import React from 'react';
import { TextField, Checkbox, FormControl, FormControlLabel, Box, Button, InputLabel, FormHelperText } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';


const AddChecklistWindow = ({ project_id, document_id, handleClose, checklists, setChecklists, ...props}) => {
    // Move the code from Tab 3 here

    //WILL NEED TO MOVE TOP WINDOW TO SAVE STATE WHEN SWITCHING BETWEEN TABS.
    //Add Checklist
    const [checklistTitle, setChecklistTitle] = useState('');
    const [checklistType, setChecklistType] = useState('Project');

    //for later use
    const [selectedDocumentType, setSelectedDocumentType] = useState('Under Development');
    const [documentTypes, setDocumentTypes] = useState(['Under Development']);  //inherit from parent

    const API = "/checklists.api"

    const addCheckList = async () => {
        //console.log("token is: ", props.token)  //Security

        await fetch(API, {
            method: 'POST',
            mode: 'cors',
            //IMPORTANT
            //The body should contain the paragraph_id being requested.  This should be passed via props

            body: JSON.stringify({
                call_type: 'add_checklist',
                document_id: document_id,
                project_id: project_id,
                checklist_title: checklistTitle,
                checklist_type: checklistType,
            }),
            headers: new Headers({
                //'Accept': 'application/json; charset=utf-8',
                'Authorization': 'Bearer ' + props.token,
                'Content-Type': 'application/json',
                //'Access-Control-Allow-Origin': '*',
            })
        })
        //.then(response => response.text())          // convert to plain text
        //.then(text => console.log("fetch data: ",text))  // then log it out
        .then(response=> {
            return response.json() //convert to json.  return passes it
        })  // Prod
        //.then(data => console.log("fetch data: ",data))  //For Debugging
        .then((ResponseData) => {
            console.log("Response:", ResponseData)
            

            //check to see if a new access_token
            //console.log("new token: ", data)
            if(ResponseData.access_token !== undefined){
                console.log("setting new token")
                props.setToken(ResponseData.access_token) //if refresh token present. Set it.
            }

            if(ResponseData.checklist_id !== undefined && ResponseData.checkklist_id !== null){
                
                //May need to add logic so that that checklists is sorted

                //Add the new checklist to the list of checklists
                setChecklists([...checklists, { ID: ResponseData.checklist_id, Title: checklistTitle, Type: checklistType}]);
                
                // Clear the title
                setChecklistTitle('');
                            
                // Set isLoading to false and handle other logic if needed.
            }

            //will return null if no labels are present
            // if(ResponseData.paragraphs_labels_list !== undefined){
            //     console.log("paragraph_labels_list:", ResponseData.paragraphs_labels_list);

            //     //const parsedLabelsList = JSON.parse(ResponseData.paragraphs_labels_list);
            //     setTags(ResponseData.paragraphs_labels_list)
            // }

            }
        );  //set data to results
    }

    return (
        // Return the JSX from Tab 3 here
        <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <FormControl
                            sx={{
                                marginTop: 2,
                                marginBottom: 2,
                                width: '80%', // Set width to 80%
                            }}
                        >
                            <TextField
                            label="New Checklist Title"
                            value={checklistTitle}
                            onChange={(event) => setChecklistTitle(event.target.value)}
                            sx={{
                                textAlign: 'left', // Align text to the left
                                minWidth: '50ch', // Set minimum width to 50 characters
                                mb: 2, // Add bottom margin
                            }}
                            />
                        </FormControl>

                        <FormControl
                            sx={{
                                marginTop: 2,
                                marginBottom: 2,
                                width: '80%', // Set width to 80%
                            }}
                        >
                        {/* 
                            Default: Project.  
                                Because the checklist can be used as a means of taking notes and confirming everything across a Project.
                                Then all paragraphs associated with the check can be displayed in a project wide report.
                            
                            Types:
                            Project or Matter: will make the checkbox appear for all items in the select project or matter
                            Document Type: will make the checklist appear for documents of this document type.
                        */}
                        <Autocomplete
                            options={[
                                "Project", 
                                //"Matter", 
                                "Document Type"
                            ]}  //eventually I will need to get the list of document types from the server
                            value={checklistType}
                            onChange={(event, newValue) => setChecklistType(newValue)}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label="Select Type"
                                sx={{
                                    textAlign: 'left', // Align text to the left
                                    minWidth: '50ch', // Set minimum width to 50 characters
                                }}
                                />
                            )}
                        />
                        {checklistType === 'Document Type' && (
                            <Autocomplete
                                options={documentTypes} // Replace this with your list of document types
                                value={selectedDocumentType}
                                onChange={(event, newValue) => setSelectedDocumentType(newValue)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Document Type"
                                    sx={{
                                    textAlign: 'left', // Align text to the left
                                    minWidth: '50ch', // Set minimum width to 50 characters
                                    }}
                                />
                                )}
                            />
                            )}
                        </FormControl>

                        {/* Note: If checklist type is "Document Type", make a select box appear under this to select the document type, with the default being the current document. */}


                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: 2,
                                alignSelf: 'flex-end', // Align the Box to the right of its parent container
                            }}
                        >
                            <Button variant="contained" onClick={handleClose}>Cancel</Button>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                disabled={!checklistTitle || !checklistType} // Disable if no value
                                onClick={addCheckList}
                            >
                                Add Checklist
                            </Button>
                        </Box>

                    </Box>
                </>
    );
};

export default AddChecklistWindow;
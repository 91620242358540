import React, { useState } from 'react';
import { Button, Box, Typography, Modal, TextField, Chip, IconButton, Autocomplete } from '@mui/material';
import { Label as LabelIcon } from '@mui/icons-material';

const LabelButton = () => {
  const [open, setOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  const handleTagClick = (tag) => {
    const updatedTags = tags.filter((t) => t !== tag);
    setTags(updatedTags);
  };

  const handleUpdate = () => {
    handleClose();
  };

  const handleTagSelect = (event, value) => {
    if (value && value.trim() !== '') {
      const trimmedValue = value.trim();
      const matchedOption = options.find((option) => option.toLowerCase() === trimmedValue.toLowerCase());
      if (matchedOption && !tags.includes(matchedOption)) {
        setTags([...tags, matchedOption]);
        setInputValue('');
      }
    }
  };

  const handleTagKeyDown = (event) => {
    if (event.key === 'Enter') {
      const tag = event.target.value.trim();
      if (tag !== '' && !tags.includes(tag)) {
        setTags([...tags, tag]);
        setInputValue('');
      }
    }
  };

  const options = [
    'indemnification',
    'limitation of liability',
    'test cases',
  ].sort();

  return (
    <>
      <Button variant="contained" startIcon={<LabelIcon />} onClick={handleOpen}>
        Label
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'white',
            boxShadow: 24,
            p: 4,
            outline: 'none',
          }}
        >
          <Typography variant="h6" component="h2" align="center" gutterBottom>
            Labels
          </Typography>
          <Box display="flex" alignItems="center" flexWrap="wrap" mb={2}>
            {tags.map((tag) => (
              <Chip
                key={tag}
                label={tag}
                onDelete={() => handleTagClick(tag)}
                color="primary"
                sx={{ mr: 1, mt: 1 }}
              />
            ))}
          </Box>
          <Autocomplete
            options={options}
            value={inputValue}
            onChange={handleTagSelect}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Type here"
                onKeyDown={handleTagKeyDown}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
              />
            )}
          />
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={handleUpdate} variant="contained" sx={{ mr: 1 }}>
              Update
            </Button>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default LabelButton;
import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';

import useMediaQuery from '@mui/material/useMediaQuery';

import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Tabs, Tab } from '@mui/material';

import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';

import Drawer from '@mui/material/Drawer';
import ChecklistIcon from '@mui/icons-material/Checklist';
import CloseIcon from '@mui/icons-material/Close';

import Toolbar from '@mui/material/Toolbar';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';

import { Select, MenuItem, TextField, Checkbox, FormControl, FormControlLabel, Box, Button, InputLabel, FormHelperText } from '@mui/material';

import SummarizeIcon from '@mui/icons-material/Summarize';

import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';


//Add in components
import AddChecklistWindow from './addChecklistWindow';
import AddCreateCheckWindow from './addCreateCheckWindow';
import AddLinkWindow from './addLinkWindow';


//const API = "/getlabels.api"
const API = "/checklists.api"


//we will need to pass the checks list as items and the check_responses list as responses
// ADD IN , comments, setComments for final version.
const AddCheckButton = ({ paragraphs, paragraph_id, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false); //for the extra items in ¶ view

    //handle tags
    const [titlesWithColorArray, settitlesWithColorArray] = useState([]);

    //Handle tabs
    const [value, setValue] = useState(0);

    //from server
    const [tagOptions, setTagOptions] = useState([]);
    const [checklists, setChecklists] = useState([]);  //holds the complete list of checklists

    //handle paragraph text
    const [selectedParagraph, setSelectedParagraph] = useState(null);

    //handle labels
    const [labels, setLabels] = useState([]);  //holds the complete list of labels

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    
    useEffect(() => {
        //This should should respond to document_id and project_id
        fetchCheckLists();

        // console.log("Add check. labels: ", labels)  //REMOVE AFTER CONFIRMING FUNCTIONALITY

        // //const titlesArray = labels.map((label) => label.Title).sort();
        // const titlesArray = labels.map((label) => label.Label_Title).sort();
        // console.log("titlesArray: ", titlesArray);

        // //const titlesWithColorArray = labels.map((label) => ({ title: label.Title, color: label.Color })).sort();
        // const titlesWithColorArray = labels.map((label) => ({ title: label.Label_Title, color: label.Color })).sort();
        // console.log("titlesWithColorArray: ", titlesWithColorArray);
        
        // settitlesWithColorArray(titlesWithColorArray);
        // setTagOptions(titlesArray);

        //paragraph text
        const selectedParagraph = paragraphs.find(paragraph => paragraph.ID === paragraph_id);
        console.log("Add Check. Selected Paragraph: ", selectedParagraph);
        setSelectedParagraph(selectedParagraph);


        // const handleKeyDown = (event) => {
        //     if (event.key === 'Escape' && isTextSelectMode) {
        //         setIsTextSelectMode(false);
        //     }
        // };

        // document.addEventListener('keydown', handleKeyDown);

        // return () => {
        //     document.removeEventListener('keydown', handleKeyDown);
        // };
    }, [paragraph_id]);

    const isMobileDevice = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };

    const handleOpen = () => {
        setIsOpen(true);
    };
    
    const handleClose = () => {
        setIsOpen(false);
    };
    
    
const fetchCheckLists = async () => {
        //console.log("token is: ", props.token)  //Security

        await fetch(API, {
            method: 'POST',
            mode: 'cors',
    //IMPORTANT
    //The body should contain the paragraph_id being requested.  This should be passed via props

            body: JSON.stringify({
                call_type: 'get_all',
                document_id: props.docid,
                project_id: props.project_id
            }),
            headers: new Headers({
                //'Accept': 'application/json; charset=utf-8',
                'Authorization': 'Bearer ' + props.token,
                'Content-Type': 'application/json',
                //'Access-Control-Allow-Origin': '*',
            })
        })
        //.then(response => response.text())          // convert to plain text
        //.then(text => console.log("fetch data: ",text))  // then log it out
        .then(response=> {
            return response.json() //convert to json.  return passes it
        })  // Prod
        //.then(data => console.log("fetch data: ",data))  //For Debugging
        .then((ResponseData) => {
            console.log("Response:", ResponseData)
            

            //check to see if a new access_token
            //console.log("new token: ", data)
            if(ResponseData.access_token !== undefined){
                console.log("setting new token")
                props.setToken(ResponseData.access_token) //if refresh token present. Set it.
            }

            if(ResponseData.labels_list !== undefined && ResponseData.labels_list !== null){
        
                console.log("addCheckButton. Setting labels_list:", ResponseData.labels_list);  //for debugging
                const labelsArray = JSON.parse(ResponseData.labels_list);  //create json object

                //OLD SORTING
                // const parsedLabelsList = JSON.parse(ResponseData.labels_list);
                // const titlesArray = parsedLabelsList.map((label) => label.Title).sort();
                // console.log("titlesArray: ", titlesArray);

                //new
                const parsedLabelsList = JSON.parse(ResponseData.labels_list);
                parsedLabelsList.sort((a, b) => a.Title.localeCompare(b.Title));
                console.log("sortedLabelsList: ", parsedLabelsList);

                const titlesWithColorArray = parsedLabelsList.map((label) => ({ title: label.Title, color: label.Color, id:label.ID })).sort();
                console.log("titlesWithColorArray: ", titlesWithColorArray);
                
                setLabels(labelsArray);
                settitlesWithColorArray(titlesWithColorArray);
                setTagOptions(parsedLabelsList);
            
                // Set isLoading to false and handle other logic if needed.
            }

            //get checklists
            if(ResponseData.checklists_list !== undefined && ResponseData.checklists_list !== null){
        
                //console.log("setting labels_list:", ResponseData.labels_list);  //for debugging
                const checklistArray = JSON.parse(ResponseData.checklists_list);  //create json object

                //sorting?
                //const titlesArray = parsedLabelsList.map((label) => label.Title).sort();
                //console.log("titlesArray: ", titlesArray);

                //const titlesWithColorArray = parsedLabelsList.map((label) => ({ title: label.Title, color: label.Color })).sort();
                //console.log("titlesWithColorArray: ", titlesWithColorArray);
                
                setChecklists(checklistArray);
            
                // Set isLoading to false and handle other logic if needed.
            }

            //will return null if no labels are present
            // if(ResponseData.paragraphs_labels_list !== undefined){
            //     console.log("paragraph_labels_list:", ResponseData.paragraphs_labels_list);

            //     //const parsedLabelsList = JSON.parse(ResponseData.paragraphs_labels_list);
            //     setTags(ResponseData.paragraphs_labels_list)
            // }

            }
        );  //set data to results
    }


    return (
        <>
        <IconButton 
          aria-label="expand_label_model"
          sx={{
            backgroundColor: props.iconButtonStyle && props.iconButtonStyle.backgroundColor ? props.iconButtonStyle.backgroundColor : 'info.main',
            color: props.iconButtonStyle && props.iconButtonStyle.color ? props.iconButtonStyle.color : 'white',
            '&:hover': {
              backgroundColor: 'info.dark',
            },
            ...props.iconButtonStyle, // incorporate the passed variable
          }}
          onClick={handleOpen}
          >
            <AddTaskOutlinedIcon/>
    
        </IconButton>
    
          {/* <Button variant="contained" startIcon={<LabelIcon />} onClick={handleOpen}/>  REMOVE AFTER CONFIRMING FUNCTIONAL */}
    
          <Modal open={isOpen} onClose={handleClose}>
            <Box
                sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'white',
                boxShadow: 24,
                p: 4,
                outline: 'none',
                width: '75%', // Set width to 75%
                height: '75%', // Set height to 75%
                overflow: 'auto', // Add scrolling if content overflows
                }}
            >
                <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'sticky', // Make the IconButton sticky
                    right: 8,
                    top: 8,
                }}
                >
                    <CloseIcon />
                </IconButton>

                <Tabs value={value} onChange={handleChange} centered>
                    <Tab label="Add Check" />
                    <Tab label="Add Link" />
                    <Tab label="Create Checklist" />
                </Tabs>

                {value === 0 && (  //the Add Check tab
                    <AddCreateCheckWindow
                        token = {props.token}
                        setToken={props.setToken}
                        document_id={props.docid}
                        project_id={props.project_id}
                        paragraph_id={paragraph_id}
                        checklists={checklists}
                        setChecklists={setChecklists}
                        checks={props.checks}
                        setChecks={props.setChecks}
                        handleClose={handleClose}
                        tagOptions={tagOptions}
                        titlesWithColorArray={titlesWithColorArray}
                        selectedParagraph={selectedParagraph}
                    />           
                )}

                {value === 1 && (
                // Your Add Link code here...
                //project_id, document_id, handleClose, tagOptions, selectedParagraph, titlesWithColorArray, checklists, setChecklists, ...props
                <AddLinkWindow
                    token = {props.token} 
                    setToken={props.setToken}

                    document_id={props.docid}
                    project_id={props.project_id}

                    paragraph_id={paragraph_id}
                    checklists={checklists}
                    setChecklists={setChecklists}

                    checks={props.checks}
                    setChecks={props.setChecks}

                    handleClose={handleClose}
                    tagOptions={tagOptions}
                    titlesWithColorArray={titlesWithColorArray}
                    selectedParagraph={selectedParagraph}
                />

                )}

                {value === 2 && (
                
                    // Your Checklists code here...
                    <AddChecklistWindow
                        token = {props.token} 
                        setToken={props.setToken}
                        document_id={props.docid}
                        project_id={props.project_id}
                        checklists={checklists}
                        setChecklists={setChecklists}
                        handleClose={handleClose}
                    />

                )}
            </Box>
          </Modal>
        </>
    );
};

export default AddCheckButton;
import React, { Component } from 'react'
import RJS_PDF_Viewer from './reactPDF'

//npm i react-pdf

//This will be designed so that it receives a pdf URL which includes sometimes a specific page.
//The hope is that when the user clicks a link, it will allow them to jump to specific pages of the pdf without having to completely rerender the pdf.
/*
//object and iframe failed. Attempting to use PDFjs.js.
//https://www.pdftron.com/blog/react/how-to-build-a-react-pdf-viewer/

Check:
#notes re pdf in div
https://stackoverflow.com/questions/2740297/display-adobe-pdf-inside-a-div
https://forum.jquery.com/topic/load-a-pdf-file-using-div-load-call-7-3-2011
https://stackoverflow.com/questions/593176/div-layer-on-top-of-pdf
https://pdfobject.com/
https://www.youtube.com/watch?v=1ZNeP7cjADo
https://stackoverflow.com/questions/29807055/jump-to-page-in-pdf-js-with-javascript
https://dev.to/sofiajonsson/adding-pdf-to-react-site-f36

https://stackoverflow.com/questions/55371402/how-to-reference-pdf-js-library-in-react

https://pdfobject.com/

pdf-js is slow:
https://stackoverflow.com/questions/15693620/reducing-pdf-loading-times-using-pdf-js
https://helpx.adobe.com/acrobat/using/optimizing-pdfs-acrobat-pro.html
https://www.tsgrp.com/2017/08/22/improving-viewing-performance-of-pdf-documents/

https://www.education-ecosystem.com/axelduch/lp6bo-how-to-create-simple-pdf-reader-using-pdfjs-and-react/98Pjo-simple-pdf-reader-using-pdfjs-and-react-7/

https://stackoverflow.com/questions/44747737/pdf-js-lazy-load
https://www.pdftron.com/blog/pdf-js/pdf-js-build-vs-buy/

*Below is probably the best source since, everything else seems to not work.
JUST PDF.JS functioning demo.  Will need to convert to ReactJS:
https://jsfiddle.net/pdfjs/wagvs9Lf/

*NOTE
https://stackoverflow.com/questions/25162554/how-to-render-whole-pdf-document-using-pdf-js-library
https://stackoverflow.com/questions/16480469/how-to-display-whole-pdf-not-only-one-page-with-pdf-js


*using React-pdf
https://rieckpil.de/howto-pdf-preview-with-react/
https://github.com/rieckpil/blog-tutorials/tree/master/pdf-preview-react


#error note:
https://stackoverflow.com/questions/6796194/canvas-getcontext2d-returns-null

#check also if you can direclty use PDF annotator

https://github.com/wojtekmaj/react-pdf

#email templates
https://github.com/voidlabs/mosaico

https://gist.github.com/fcingolani/3300351

//The below note is extremely important!  Talksa bout getting PDFs out of order, and how to chain them
https://stackoverflow.com/questions/16480469/how-to-display-whole-pdf-not-only-one-page-with-pdf-js

iFrame PDF:
https://stackoverflow.com/questions/29807055/jump-to-page-in-pdf-js-with-javascript
https://www.reddit.com/r/javascript/comments/7oc8au/how_can_i_determine_when_an_iframe_has_finished/
https://medium.com/@josh.j.pearson/handling-iframe-loading-in-react-57f044a9d0fa
https://stackoverflow.com/questions/51494680/how-to-load-pdf-file-inside-of-iframe-working-in-react
https://medium.com/@ryanseddon/rendering-to-iframes-in-react-d1cb92274f86

https://www.reddit.com/r/reactjs/comments/6ieziw/help_with_pdfjs_performance/

implement loadbar:
https://stackoverflow.com/questions/25436801/loading-bar-for-pdf-js
https://usefulangle.com/post/23/pdfjs-tutorial-3-showing-loading-progress-bar
https://github.com/mozilla/pdf.js/issues/3090
https://github.com/mozilla/pdf.js/issues/6164

canvas:
https://stackoverflow.com/questions/15341010/render-pdf-to-single-canvas-using-pdf-js-and-imagedata


PROBLEMS:
Need to get Iframe Object so I can switch the page.

 
TRY LOADING IN PDF ANNOTATOR INSTEAD:
https://github.com/agentcooper/react-pdf-highlighter


Consider adding progress bar:
https://react-bootstrap.github.io/components/progress/
https://reactjsexample.com/customizable-circular-svg-progress-bar-component-for-react/

*/
class PDF_Viewer extends Component {

	constructor(props) {
		super(props);
		//this.viewerRef = React.createRef();
    	//this.backend = new props.backend();
	
		this.state = {
			//Dev Note:  These will need to be linked so when other component updates.  They update and force render.
			 pdfURL: [],
            isLoading: false
		}
	}
	/*
	componentDidMount() {
		const { src } = this.props;
		//const element = this.viewerRef.current;  //pdfjs parts.
	  }
*/
	render() {
		

		//first check if pdf_url is defined
		if (this.props.src) {
			
			/*if yes, show the pdf*/
			//alert(this.props.src)

			return(
				//<div ref={this.viewerRef} id='viewer' style={{ width: '100%', height: '100%' }}></div>
				<RJS_PDF_Viewer
					src = {this.props.src}
					pageNumber = {this.props.pageNumber}
				/>
			);
		} else {
			//If not URL specified, return nothing.
			return(
				/*
					Problem:  apparently this is only rendering to a small part of page.
					Solution: There is a parent div that must be set to 100%
				*/
				<div className="PDF_Viewer" height="100%">
				    <p>PDF DATA NOT LOADED</p>
				</div>
			);
		}
	}
}

export default PDF_Viewer
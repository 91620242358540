import { Container, Grid, Paper } from '@mui/material';
import NoteCard from './NoteCard';
import React, { Component } from 'react'
import {Typography } from '@mui/material';

const API = '/index.py';  //Prod  Check

class DocumentLister extends Component {

	constructor(props) {
		super(props)
	
		this.state = {
			//Dev Note:  These will need to be linked so when other component updates.  They update and force render.
			 results: [],
            isLoading: true
		}
	}
	
	componentDidMount(){
		console.log("token is: ", this.props.token)

		fetch(API, {
			method: 'POST',
			mode: 'cors', 
			body: JSON.stringify({
				//search_query: this.state.search_query
				docid: this.props.docid,
				matter_id: this.props.matter_id,
				project_id: this.props.project_id,
				last_result: this.state.last_result
			}),	
			headers: new Headers({
				//'Accept': 'application/json; charset=utf-8',
				'Authorization': 'Bearer ' + this.props.token,
				'Content-Type': 'application/json',
				//'Access-Control-Allow-Origin': '*',
			})
		})
		//.then(response => response.text())          // convert to plain text
		//.then(text => console.log("fetch data: ",text))  // then log it out
		.then(response=> {
			return response.json() //convert to json.  return passes it
		})  // Prod
		//.then(data => console.log("fetch data: ",data))  //For Debugging
		.then((data) => {
			

			//check to see if a new access_token
			//console.log("new token: ", data)
			if(data.access_token !== undefined){
				console.log("setting new token")
				this.props.setToken(data.access_token) //if refresh token present. Set it.
			}

			if(data.doc_list !== undefined){
				console.log("setting doc_list")
				this.setState({results: data.doc_list, isLoading:false})}
			}


		);  //set data to results
	}	

	//may need to use to call other components
	handleClick = (event, a_value, b_value) => {
		//alert(a_value);
		this.props.parentCallback({ pdfURL:a_value, pageNumber:b_value });
	}

	//Dev Note: this will need to be changed to refresh when hits value is received.

	render() {
		//const { hits } = this.state;
		//console.log(hits)
		//if (isLoading) {
		//	return <p>Loading ...</p>;
		//}

		const {results} = this.state;  //Prod.  Not sure why this is required, but it is.  Otherwise it doesn't receive results.
		//alert(this.state.isLoading)  //for Debugging
		if (this.state.isLoading == false) {
			//var arr = [];
			
			console.log("JSON Object breakdown: ", results)  // for debugging			

			//this.props.hits = JSON.parse(this.props.hits)

			return(
				<div>	 
					{/*  Notes Page						
					*/}

					<Container sx={{bgcolor: '#E5E4E2'}}>
						
					{results.length > 0 ? (
						results.map(hit => <NoteCard 
												key={hit.objectID} 
												token={this.props.token} 
												setToken={this.props.setToken} 
												hit={hit} 
												setDocID={this.props.setDocID} 
												DocID={this.props.DocID} 
												setMatterNProject={this.props.setMatterNProject}
												handleSetOptionClick={this.props.handleSetOptionClick}
												matter_id={this.props.matter_id}
												project_id={this.props.project_id}
											/>)
							) : (
						<Typography variant="body1">No Documents Found</Typography>
					)}
						
					</Container>						
				</div>
			);	
		} else {
			return(
				<div>
					<p>No Documents Found.</p>
				</div>
			);
		}
	}
}

export default DocumentLister
import React from 'react';
import { TextField, Checkbox, FormControl, FormControlLabel, Box, Button, InputLabel, FormHelperText } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';
import { Select, MenuItem, Chip, Typography } from '@mui/material';
import { useEffect } from 'react';
import { filter } from 'underscore';

const API = "/checklists.api"



const AddLinkWindow = ({ project_id, document_id, handleClose, tagOptions, selectedParagraph, titlesWithColorArray, checklists, setChecklists, checks, setChecks, ...props}) => {

    //Add link
    const [check, setCheck] = useState({Text:''});
    const [checklist, setChecklist] = useState({Title:''});
    
    const [displayChecks, setDisplayChecks] = useState([]);

    const filterChecks = (value) => {
        console.log("filterChecks:", value);
        console.log("checks:", checks);
        const filteredChecks = checks.filter(check => check.Checklist_ID === value.ID);
        console.log("filteredChecks:", filteredChecks);
        setDisplayChecks(filteredChecks);
    }

    //needs to use full items as we will need the ids to pull the checks
    const handleSelectChecklist = (value) => {
        setChecklist(value);
        
        if (value) {
            filterChecks(value);
        }
        else {
            setDisplayChecks([]);
        }
        //fetchChecks(value);  // need to add this.
    } 
    
    const addLink = async () => {
        console.log("addLink:", 
        "project_id:", project_id, 
        "document_id:", document_id, 
        "checklist:", checklist, 
        "check:", check, 
        "selectedParagraph:", selectedParagraph);
        
        await fetch(API, {
            method: 'POST',
            mode: 'cors',
            //IMPORTANT
            //The body should contain the paragraph_id being requested.  This should be passed via props

            body: JSON.stringify({
                call_type: 'add_link',
                document_id: document_id,
                project_id: project_id,
                checklist_id: checklist.ID,
                check_id: check.ID,
                paragraph_id: selectedParagraph.ID,
                paragraph_text: selectedParagraph.Text,
            }),
            headers: new Headers({
                //'Accept': 'application/json; charset=utf-8',
                'Authorization': 'Bearer ' + props.token,
                'Content-Type': 'application/json',
                //'Access-Control-Allow-Origin': '*',
            })
        })
        //.then(response => response.text())          // convert to plain text
        //.then(text => console.log("fetch data: ",text))  // then log it out
        .then(response=> {
            return response.json() //convert to json.  return passes it
        })  // Prod
        //.then(data => console.log("fetch data: ",data))  //For Debugging
        .then((ResponseData) => {
            console.log("Response:", ResponseData)
            
            //check to see if a new access_token
            //console.log("new token: ", data)
            if(ResponseData.access_token !== undefined){
                console.log("setting new token")
                props.setToken(ResponseData.access_token) //if refresh token present. Set it.
            }

            if(ResponseData.link_id !== undefined && ResponseData.link_id !== null){
                // Add the new link to the list of links
                const newLink = { 
                    ID: ResponseData.link_id, 
                    Title: checklist.Title, 
                    Type: null, 
                    Checklist_ID: checklist.ID, 
                    Check_ID: check.ID, 
                    Paragraph_ID: selectedParagraph.ID, 
                    Paragraph_Text: selectedParagraph.Text
                };
            
                // Find the specific check and add the link to its links array
                const updatedChecks = checks.map(each_check => {
                  if (each_check.ID === newLink.Check_ID) {
                    // Add the new link to the links array of the located check
                    return {
                      ...each_check,
                      links: [...each_check.links, newLink]
                    };
                  } else {
                    // Return the check as is
                    return each_check;
                  }
                });
            
                setChecks(updatedChecks);
            
                // Clear the title
                alert("Link added successfully")
                // Set isLoading to false and handle other logic if needed.
            }
        })

        .catch((error) => {
            console.error('Error:', error);
        });
    }


    return(
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                }}
            >

                <FormControl
                    sx={{
                        marginTop: 2,
                        marginBottom: 2,
                        width: '80%', // Set width to 80%
                    }}
                >

                <Autocomplete
                    options={checklists}
                    getOptionLabel={(option) => option.Title}
                    value={checklist}
                    disabled={!selectedParagraph} // Disable if no paragraph is selected
                    onChange={(event, newValue) => handleSelectChecklist(newValue)}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        label="Checklist:"
                        sx={{
                            textAlign: 'left', // Align text to the left
                            minWidth: '50ch', // Set minimum width to 50 characters
                        }}
                        />
                    )}
                />
                </FormControl>

                <FormControl
                    sx={{
                        marginTop: 2,
                        marginBottom: 2,
                        width: '80%', // Set width to 80%
                    }}
                >
                <Autocomplete
                    options={displayChecks}
                    getOptionLabel={(option) => option.Text}

                    value={check}                            
                    onChange={(event, newValue) => setCheck(newValue)}
                    disabled={!checklist || !selectedParagraph} // Disable if no paragraph is selected} // Disable if no checklist name is selected
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        label="Select Check"
                        sx={{
                            textAlign: 'left', // Align text to the left
                            minWidth: '50ch', // Set minimum width to 50 characters
                        }}
                        />
                    )}
                />
                </FormControl>

            <FormControl
                    sx={{
                        marginTop: 2,
                        marginBottom: 2,
                        width: '80%', // Set width to 80%
                    }}
                >
                <FormHelperText sx={{
                        textAlign: 'left', // Align text to the left
                    }}>
                        Paragraph to link:</FormHelperText>
                    <Box
                        sx={{
                            bgcolor: '#f5f5f5',
                            p: 2,
                            width: '100%', // Set width to 80%
                            height: 160,
                            overflow: 'auto',
                            marginTop: 0, // Remove top margin
                        }}
                    >
                        {selectedParagraph && selectedParagraph.Text ? (
                            <Typography>
                                {selectedParagraph.Text}
                            </Typography>
                        ) : (
                            <Typography>
                                No text available
                            </Typography>
                        )}
                    </Box>
            </FormControl>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 2,
                    alignSelf: 'flex-end', // Align the Box to the right of its parent container
                    }}
            >
                <Button variant="contained" onClick={handleClose}>Cancel</Button>
                <Button 
                    variant="contained" 
                    color="primary" 
                    disabled={!checklist || !check || !selectedParagraph} // Disable if no checklist or check is selected
                >
                    Add Link
                </Button>
            </Box>

        </Box>
    )
};

export default AddLinkWindow;
import React from 'react';
import { Drawer, Typography,List, ListItem, ListItemIcon, ListItemText, IconButton, Divider, styled, useTheme, useMediaQuery } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Logo from './Logo.png';
const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

function CustomDrawer({ open, handleDrawerClose, primaryNavigationItems, secondaryNavigationItems }) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                backgroundColor: '#17191A',
              },
            }}
            variant={matches ? 'temporary' : 'persistent'}
            anchor="left"
            open={open}
            onClose={handleDrawerClose}
        >
            <DrawerHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={Logo} alt="Logo" style={{ maxWidth: '100%', maxHeight: '60px', padding: '0', marginRight: '10px' }} />
                <Typography variant="h6" style={{ color: 'white'}}>ULOMATE</Typography>
              </div>
              <IconButton onClick={handleDrawerClose} style={{ position: 'absolute', right: 0, top: 0 }}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon sx={{ color: 'white' }} /> : <ChevronRightIcon sx={{ color: 'white' }} />}
              </IconButton>
            </DrawerHeader>

            <Divider sx={{borderColor: '#2E2F30', width: '100%', alignSelf: 'center', my: 1 }} />
            <Divider />
            <List>
              {primaryNavigationItems.map((item, index) => (
                  <ListItem  button key={index} sx={{ '&:hover': { backgroundColor: '#1976d2' }, height: '40px','.MuiListItemIcon-root': { minWidth: '15px' }, '.MuiListItemText-root': { marginLeft: '15px' }}} >
                  <ListItemIcon sx={{ color: 'white' }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={<span style={{ color: 'white' }}>{item.name}</span>} />
                </ListItem>
              ))}
            </List>
            <Divider sx={{ borderColor: '#2E2F30',width: '90%', alignSelf: 'center', my: 1 }} />
            <List>
              {secondaryNavigationItems.map((item, index) => (
                <ListItem  button key={index} sx={{ '&:hover': { backgroundColor: '#1976d2' }, height: '40px','.MuiListItemIcon-root': { minWidth: '15px' }, '.MuiListItemText-root': { marginLeft: '15px' }}} >
                  <ListItemIcon sx={{ color: 'white' }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={<span style={{ color: 'white' }}>{item.name}</span>} />
                </ListItem>
              ))}
            </List>
            <Divider sx={{borderColor:'#2E2F30', width: '90%', alignSelf: 'center', my: 1 }} />
        </Drawer>
    );
}

export default CustomDrawer;
import * as React from 'react';
import { ColorButton, ColorPalette} from 'mui-color';
import { ColorPicker } from 'mui-color';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';


export default function BasicPopover(props) {
const [anchorEl, setAnchorEl] = React.useState(null);

const palette = {
    yellow: 'yellow',
    lime: 'lime',
    cyan: 'cyan',
    pink: 'pink',
    orange: 'orange',
    purple: 'purple',
    gray: 'gray',
    red: 'red',
    darkblue: 'darkblue',
};

const handleSelect = (event) => {

    console.log("Selected: ", event)
    props.passHighlightColor(event)
    setAnchorEl(null);
}

const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
};

const handleClose = () => {
    setAnchorEl(null);
};


const open = Boolean(anchorEl);
const id = open ? 'simple-popover' : undefined;

return (
    <div>
    {console.log("Hightlight color: ", props.highlightColor)}
    <Button aria-describedby={id} sx={{ marginRight: 5 }} variant="contained" onClick={handleClick}>
        <ColorButton color={props.highlightColor}/>
    </Button>
    <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
        }}
    >
        <Typography sx={{ p: 1 }}> Choose a highlight color:</Typography>
        <ColorPalette palette={palette} onSelect={handleSelect}/>
    </Popover>
    </div>
);
}
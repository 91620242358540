import React from 'react';
import { Link, redirect } from 'react-router-dom';

import { useState } from "react";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import backgroundImage from "../../logo.png"; // replace with your image file

const API = '/token';  //testing if this works
// const API = 'http://192.168.1.3:9999/token';  //testing if this works

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: 'column', // Stack children vertically
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${backgroundImage})`,
    //backgroundColor: "rgba(0, 0, 0, 0.9)",
    backgroundSize: "40%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    '@media (max-width:600px)': {
      backgroundSize: "95%",
    },
    '@media (min-width:601px) and (max-width:1024px)': {
      backgroundSize: "95%",
    },
  },
  formContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: "5px",
    padding: "20px",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
  },
  textField: {
    margin: "10px 0",
  },
  button: {
    margin: "20px 0",
    backgroundColor: "#87CEEB", // light blue color
    color: "#fff",
    "&:hover": {
      backgroundColor: "#6495ED", // darker shade of light blue color
    },
  },
  footer: {
    position: 'fixed', // This makes the footer always visible at the bottom
    left: 0,
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    padding: '12px 0', // Adjust padding as necessary
    // You might want to add background or boxShadow to make it distinguishable from the rest of the content
    backgroundColor: '#f0f0f0', // Example background color
    boxShadow: '0 -2px 4px rgba(0,0,0,0.1)', // Example shadow to make it stand out
  },
}));

function Login(props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [apiResponse, setApiResponse] = useState("");

  const [msgHandler, setmsgHandler] = useState({msg:""})

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Validate email format
    //const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    //setEmailError(!isValidEmail);
    //if (!isValidEmail) {
    //  return;
    //}
    
    // Call API
    fetch(API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          response.json().then((errorResponse) => {
            const error_msg = errorResponse.msg;
            setApiResponse(error_msg);
            console.log(error_msg);
            throw new Error(response.status);
          });
        } else {
          return response.json().then((data) => {
            console.log("success: ", data.access_token);
            props.setToken(data.access_token); //set navigation
            props.getNav();//set navigation
            return redirect("/");
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });    
    }

  return (
    <>
    <Box className={classes.root}>
      <CssBaseline />
      <Container component="main">
        <Box className={classes.formContainer}>
          <form onSubmit={handleSubmit}>
            <TextField
              className={classes.textField}
              fullWidth
              label="Email"
              value={email}
              error={emailError}
              helperText={emailError && "Please enter a valid email address"}
              onChange={(event) => setEmail(event.target.value)}
            />
            <TextField
              className={classes.textField}
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <Button
              className={classes.button}
              fullWidth
              variant="contained"
              type="submit"
            >
              Login
            </Button>
          </form>
          {apiResponse && (
            <Typography align="center">
              API response: {JSON.stringify(apiResponse)}
            </Typography>
          )}
        </Box>
      </Container>
    </Box>
    <Box className={classes.footer}>
        <Typography variant="body2" color="textSecondary" align="center">
        Copyright (C) Ulomate LLC 2024. 
        <br />
        <Link to="/about" style={{marginRight:8}}>About</Link>
        <Link to="/terms">Terms</Link>
      </Typography>
    </Box>
    </>
  );
}

export default Login;
import React, { Component } from 'react';

class SecureFileLink extends Component {
    handleClick = async (event) => {
        //NEED TO REMOVE BLOB
        //MIDDLE MOUSE CLICK CAUSES AUTHENTICATION ERROR

        //ADD SEPERATE PART FOR HANDLING MOBILE
        //NEED TO FIX ON MOBILE.  DOES REDIRECT TO URL BUT DOES NOT OPEN PDF
        //NEED TO FIX ON MOBILE.  DOES NOT SIZE TO FIT PDF PAGE.
        event.preventDefault();

        const { docid, token, target, page } = this.props;

        const response = await fetch('/getfile.py', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ docid })
        });

        if (!response.ok) {
            // Handle error
            console.error('An error occurred while fetching the file');
            return;
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Append the page number to the URL
        const finalUrl = `${url}#page=${page}`;

        // Open the file in a new tab if target is _blank, otherwise in the current tab
        window.open(finalUrl, target);
    }

    render() {
        // Clone the children and add the handleClick function to the onClick prop
        const childrenWithProps = React.Children.map(this.props.children, child => {
            return React.cloneElement(child, { onClick: this.handleClick, style: { cursor: 'pointer' } });
        });

        return childrenWithProps;
    }
}

export default SecureFileLink;
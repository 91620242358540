import React, { Component } from "react";

//where to send update request
const API = '/addtopic.py';

class New_Topic_Box extends Component {
    constructor(props) {
      //console.log(props);
      super(props);
      this.state = {
        new_topic_value: '',
      };
    }

    handleValueChange = (event) => {
		this.setState({
			new_topic_value: event.target.value
		})	
	}
  

    //handle a submit and the topic
    //might need to set up a refresh state comand for Topic List.
    handleSubmit = (e) => {
      e.preventDefault();
      
      console.log(this.state.new_topic_value)  //For Debugging
      //alert(this.state.items);
      //Add show model here
  
      fetch(API, {
          method: 'POST', 
          body: JSON.stringify({
            //put the data in a string and send it.
            //search_query: this.state.search_query
            new_topic_value: this.state.new_topic_value
          }),
          headers: new Headers({
            //configure headers to send JSON object.
            'Accept': 'application/json; charset=utf-8',
            'Content-Type': 'application/json',
            //'Access-Control-Allow-Origin': '*',
          })
        })
        //.then(response => response.text())          // convert to plain text
        //.then(text => console.log("fetch data: ",text))  // then log it out
        .then(response=> response.json())  // get the json response
        //.then(data => console.log("fetch data: ",data))  //For Debugging
        
        //check if the response returned completed or true
        .then((data) => this.setState({new_topic_value: ''}), this.props.refresh_Parent());  //set data to results
    }
  
    render() {
  
      return (
        <div >
            <h2>Create Topic:</h2>
            <form onSubmit={this.handleSubmit}>
                <div className = "new_topic_box">
                    <label> New Topic Name: </label>
                    <input type='text' value={this.state.new_topic_value} onChange={this.handleValueChange}/>
                    <button onClick={this.handleSubmit}>Add Topic</button>
                </div>
            </form>
        </div>
      );
    }
  }
  
  export default New_Topic_Box;
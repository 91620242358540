import React, { useState, useEffect } from 'react';
import { Container, Box, TextField, Button, Tabs, Tab, Paper, Typography, InputAdornment, List, ListItem, Chip, Grid } from '@mui/material';
import { SketchPicker } from 'react-color';
import SearchIcon from '@mui/icons-material/Search';

import TargetingTab from './TargetingTab';
import RegexConfig from './RegexConfig';
import SeparatorBox from './SeparatorBox';

const API_getAllLabels = "/getAllLabels.api";
const API_getSuggestions4Label = "/getSuggestions4Label.api";

const API_setLabel = "/setLabel.api";
const API_setSuggestion = "/setSuggestion.api";

const LabelsAndSuggestions = (props) => {

  const [labels, setLabels] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState({ID: 'New'});
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [labelSearch, setLabelSearch] = useState('');

  //Targets
  const [targets, setTargets] = React.useState([]);

  //selectedTarget
  const [selectedType, setSelectedType] = useState('any');
  const [selectedWords, setSelectedWords] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedCaseSensitivity, setSelectedCaseSensitivity] = useState(false);

  //creation
  const [labelCreate, setLabelCreate] = useState(true);
  const [suggestionCreate, setSuggestionCreate] = useState(false);
  
  //label search
  const [suggestionSearch, setSuggestionSearch] = useState('');
  const [filteredLabels, setFilteredLabels] = useState([]);

  //tabs
  const [tabIndex, setTabIndex] = useState(0);
  const [tabSuggestionsEnabled, setSuggestionsEnabled] = useState(0);
  const [tabTargetingEnabled, setTargetingEnabled] = useState(0);

  //fetch Labels
  const fetchLabels = async () => {
    //console.log("token is: ", props.token)  //Security

    await fetch(API_getAllLabels, {
      method: 'POST',
      mode: 'cors',
      //IMPORTANT
      //The body should contain the paragraph_id being requested.  This should be passed via props

      // body: JSON.stringify({
      //   //search_query: this.state.search_query
      //   //docid: props.docid,
      //   //matter_id: props.matter_id,
      //   //project_id: props.project_id
      //           //docid: 13
      //           docid: props.doc_id
      // }),	
      headers: new Headers({
      	//'Accept': 'application/json; charset=utf-8',
      	'Authorization': 'Bearer ' + props.token,
      	'Content-Type': 'application/json',
      	//'Access-Control-Allow-Origin': '*',
      })
    })
    //.then(response => response.text())          // convert to plain text
    //.then(text => console.log("fetch data: ",text))  // then log it out
    .then(response=> {
      return response.json() //convert to json.  return passes it
    })  // Prod
    //.then(data => console.log("fetch data: ",data))  //For Debugging
    .then((ResponseData) => {
            console.log("Response:", ResponseData)
      

      //check to see if a new access_token
      //console.log("new token: ", data)
      if(ResponseData.access_token !== undefined){
        console.log("setting new token")
        props.setToken(ResponseData.access_token) //if refresh token present. Set it.
      }

      if(ResponseData.labels_list !== undefined){
                //console.log("setting labels_list:", ResponseData.labels_list);  //for debugging
                setLabels(ResponseData.labels_list)
        
                //const parsedLabelsList = JSON.parse(ResponseData.labels_list);
                //const titlesArray = parsedLabelsList.map((label) => label.Title).sort();
                //console.log("titlesArray: ", titlesArray);

                //const titlesWithColorArray = parsedLabelsList.map((label) => ({ title: label.Title, color: label.Color })).sort();
                //console.log("titlesWithColorArray: ", titlesWithColorArray);
                //settitlesWithColorArray(titlesWithColorArray);
                //setOptions(titlesArray);
            
                // Set isLoading to false and handle other logic if needed.
            }

    }).catch(error => console.error('Error:', error));  //end of handling response and then error
  }


  //fetch Suggestions
  const fetchSuggestions = async (label_ID) => {
    //console.log("token is: ", props.token)  //Security

    await fetch(API_getSuggestions4Label, {
      method: 'POST',
      mode: 'cors',
      //IMPORTANT
      //The body should contain the paragraph_id being requested.  This should be passed via props

      body: JSON.stringify({
        //search_query: this.state.search_query
        //docid: props.docid,
        //matter_id: props.matter_id,
        //project_id: props.project_id
        //docid: 13
        label_id: label_ID
      }),	
      headers: new Headers({
      	//'Accept': 'application/json; charset=utf-8',
      	'Authorization': 'Bearer ' + props.token,
      	'Content-Type': 'application/json',
      	//'Access-Control-Allow-Origin': '*',
      })
    })
    //.then(response => response.text())          // convert to plain text
    //.then(text => console.log("fetch data: ",text))  // then log it out
    .then(response=> {
      return response.json() //convert to json.  return passes it
    })  // Prod
    //.then(data => console.log("fetch data: ",data))  //For Debugging
    .then((ResponseData) => {
      console.log("Response:", ResponseData)
      

      //check to see if a new access_token
      //console.log("new token: ", data)
      if(ResponseData.access_token !== undefined){
        console.log("setting new token")
        props.setToken(ResponseData.access_token) //if refresh token present. Set it.
      }

      if(ResponseData.suggestions_list !== undefined){
        console.log("suggestions_list:", ResponseData.suggestions_list);
        setSuggestions(ResponseData.suggestions_list)

        //const parsedLabelsList = JSON.parse(ResponseData.paragraphs_labels_list);
        //setTags(ResponseData.paragraphs_labels_list)
      }

      if(ResponseData.targets_list !== undefined){
        console.log("targets_list:", ResponseData.targets_list);
        setTargets(ResponseData.targets_list)
      }

    }).catch(error => console.error('Error:', error));  //end of handling response and then error
  }


  //SET FUNCTIONS

  const setLabelCall = async () => {
    //console.log("token is: ", props.token)  //Security

    await fetch(API_setLabel, {
      method: 'POST',
      mode: 'cors',
      //IMPORTANT
      //The body should contain the paragraph_id being requested.  This should be passed via props

      body: JSON.stringify({
        //search_query: this.state.search_query
        //docid: props.docid,
        //matter_id: props.matter_id,
        //project_id: props.project_id
        update_mode: labelCreate ? 'new' : 'update',
        label_id: selectedLabel.ID,
        label_title: selectedLabel.Title,
        label_doctype: selectedLabel.Doc_Type,
        label_color: selectedLabel.Color,
        
      }),	
      headers: new Headers({
      	  //'Accept': 'application/json; charset=utf-8',
        	'Authorization': 'Bearer ' + props.token,
         	'Content-Type': 'application/json',
      	  //'Access-Control-Allow-Origin': '*',
      })
    })
    //.then(response => response.text())          // convert to plain text
    //.then(text => console.log("fetch data: ",text))  // then log it out
    .then(response=> {
      setSelectedLabel({ID: 'New'})  //remove data from label
      fetchLabels() //reset label list
      return response.json() //convert to json.  return passes it
    })  // Prod
    .catch(error => console.error('Error:', error));  //end of handling response and then error
  }

  const setSuggestionCall = async () => {
    //console.log("token is: ", props.token)  //Security

    await fetch(API_setSuggestion, {
      method: 'POST',
      mode: 'cors',
      //IMPORTANT
      //The body should contain the paragraph_id being requested.  This should be passed via props

      body: JSON.stringify({
        //search_query: this.state.search_query
        //docid: props.docid,
        //matter_id: props.matter_id,
        //project_id: props.project_id
                //docid: 13
        update_mode: suggestionCreate ? 'new' : 'update',
        suggestion_id: selectedSuggestion.ID,
        suggestion_name: selectedSuggestion.Name,
        suggestion_type: selectedSuggestion.Type,
        suggestion_text: selectedSuggestion.Text,
        label_id: selectedLabel.ID,
      }),	
      headers: new Headers({
      	//'Accept': 'application/json; charset=utf-8',
      	'Authorization': 'Bearer ' + props.token,
      	'Content-Type': 'application/json',
      	//'Access-Control-Allow-Origin': '*',
      })
    })
    //.then(response => response.text())          // convert to plain text
    //.then(text => console.log("fetch data: ",text))  // then log it out
    .then(response=> {
      //if you get a response
      console.log("selectedLabel.ID:", selectedLabel.ID)
      setSelectedSuggestion({ID: "New", Type:"description"})
      fetchSuggestions(selectedLabel.ID);
    })  // Prod
    //.then(data => console.log("fetch data: ",data))  //For Debugging
    .catch(error => console.error('Error:', error));  //end of handling response and then error
  }


  // Fetch labels when component mounts
  useEffect(() => {
    
    fetchLabels();

  }, []);

  // Fetch suggestions when a label is selected
  const handleLabelSelection = (label) => {
    setSelectedLabel(label);
    setSelectedSuggestion(null);
    setLabelCreate(false);
    setSuggestionCreate(false);
    fetchSuggestions(label.ID);

    //Handle Setting Targeted Expression to New
    setSelectedWords([])
    setSelectedId(null);
    setSelectedType('any')
    setSelectedCaseSensitivity(false)

    //Enable Suggestions and Targeting Tabs
    setSuggestionsEnabled(1)
    setTargetingEnabled(1)
  }

  const handleSuggestionSelection = (suggestion) => {
    setSuggestionCreate(false);
    setSelectedSuggestion(suggestion);
  }

  const handleCreateNewLabel = () => {
    setSelectedSuggestion(null);
    setLabelCreate(true);
    setSelectedLabel({ID: "New"});

    //Handle Setting Targeted Expression to New
    setSelectedWords([])
    setSelectedId(null);
    setSelectedType('any')
    setSelectedCaseSensitivity(false)

  }

  const handleCreateNewSuggestion = () => {
    setSuggestionCreate(true);
    setSelectedSuggestion({ID:"New", Type:"description"});
  }

  const handleSetLabel = async () => {
    // make API call to setLabel
    setLabelCall()
  };

  const handleSetSuggestion = async () => {
    // make API call to setSuggestion
    setSuggestionCall()
  };

  //handle tab change
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  function Highlight({text = '', highlight = ''}) {
    if (!highlight.trim()) {
      return <span>{text}</span>;
    }
    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);
    return (
      <span>
        {parts.filter(part => part).map((part, i) => (
          regex.test(part) ? <span key={i} style={{backgroundColor: 'yellow'}}>{part}</span> : part
        ))}
      </span>
    );
  }


  return (
    <Container>
      {/*Left Label column*/}
      <Box sx={{ display: 'flex', backgroundColor: 'grey.100'}}>
        <Box sx={{ flexBasis: '30%', overflow: 'auto', p: 1, backgroundColor: 'grey.100', border: '1px solid #000' }}>
        <TextField
          variant="outlined"
          placeholder="Search labels..."
          value={labelSearch}
          onChange={(e) => setLabelSearch(e.target.value)}
          autoComplete="off"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <List sx={{ maxHeight: '300px', overflow: 'auto', border: '1px solid #000'}}>
            {labels !== null && labels !== undefined && labels.filter(label => label.Title.toLowerCase().includes(labelSearch.toLowerCase())).map((label, i) => (
              <Paper
                key={i}
                sx={{
                  mb: 1,
                  p: 1,
                  cursor: 'pointer',
                  backgroundColor: selectedLabel?.ID === label.ID ? 'lightblue' : label.Color
                }}
                onClick={() => handleLabelSelection(label)}
              >
                <Typography color="white">{label.Title}</Typography>
              </Paper>
            ))}
          </List>
          <Button sx={{ mt: 2 }} variant="contained" onClick={handleCreateNewLabel}>Create New Label</Button>
        </Box>

        {/*Top Label Chip*/}
        <Box sx={{ flexBasis: '70%', overflow: 'auto', p: 1, backgroundColor: 'grey.100' }}>
          <Box sx={{ p: 1, backgroundColor: 'grey.200', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Chip label={selectedLabel?.Title || 'Select a label'} color="primary" style={{ backgroundColor: selectedLabel?.Color}} />
          </Box>

        {/*Tab Selection*/}
      
          <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
            <Tab label="Label" />
            <Tab label="Suggestions" disabled={labelCreate > 0 || tabSuggestionsEnabled == 0 }/>
            <Tab label="Targeting" disabled={labelCreate > 0 || tabTargetingEnabled == 0 }/>
          </Tabs>

          {tabIndex === 0 && (
            <>
            {/* Label text fields and color picker */}
          <Box sx={{ my: 2, display: 'flex' }}>
            <Box sx={{ flexBasis: '70%', display: 'flex', flexDirection: 'column' }}>
            <TextField label="ID" variant="outlined" sx={{ my: 1 }} value={selectedLabel?.ID || ''} readOnly disabled/>
              <TextField label="Title" variant="outlined" sx={{ my: 1 }} value={selectedLabel?.Title || ''} onChange={(e) => setSelectedLabel({ ...selectedLabel, Title: e.target.value })} />
              <TextField label="Doc_Type" variant="outlined" sx={{ my: 1 }} value={selectedLabel?.Doc_Type || ''} onChange={(e) => setSelectedLabel({ ...selectedLabel, Doc_Type: e.target.value })} />
            </Box>
            <Box sx={{ flexBasis: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <SketchPicker color={selectedLabel?.Color || '#000000'} onChangeComplete={(color) => setSelectedLabel({ ...selectedLabel, Color: color.hex })} />
            </Box>
          </Box>
          <Button sx={{ mt: 2 }} variant="contained" onClick={handleSetLabel}>{labelCreate ? "Create Label" : "Update Label"}</Button>
            </>
          )}


          {/* Suggestion Sections */}
          {tabIndex === 1 && !labelCreate && tabSuggestionsEnabled && (
            <>
              <Grid>
                <TextField
                  variant="outlined"
                  placeholder="Search suggestions..."
                  value={suggestionSearch}
                  onChange={(e) => setSuggestionSearch(e.target.value)}
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button sx={{ mt: 2 }} variant="contained" onClick={handleCreateNewSuggestion}>Create New Suggestion</Button>
              </Grid>
              {selectedSuggestion && (
                <Box sx={{ mt: 2, backgroundColor: 'white'}}>
                  <Button sx={{ mt: 2 }} variant="contained" onClick={handleSetSuggestion}>{suggestionCreate ? "Create Suggestion" : "Update Suggestion"}</Button>
                  <Grid>
                    <TextField label="ID" variant="outlined" sx={{ my: 1 }} value={selectedSuggestion?.ID || '1'} readOnly disabled/>
                    <TextField label="Type" variant="outlined" sx={{ my: 1 }} value={selectedSuggestion?.Type || ''} onChange={(e) => setSelectedSuggestion({ ...selectedSuggestion, Type: e.target.value })} />
                    <TextField label="Name" variant="outlined" sx={{ my: 1 }} value={selectedSuggestion?.Name || ''} onChange={(e) => setSelectedSuggestion({ ...selectedSuggestion, Name: e.target.value })} />
                  </Grid>
                  <TextField 
                    label="Text" 
                    variant="outlined" 
                    sx={{ my: 1 }} 
                    value={selectedSuggestion?.Text || ''} onChange={(e) => setSelectedSuggestion({ ...selectedSuggestion, Text: e.target.value })} 
                    fullWidth
                    multiline
                  />
                  {/* <SketchPicker color={selectedSuggestion?.Color || '#000000'} onChangeComplete={(color) => setSelectedSuggestion({ ...selectedSuggestion, Color: color.hex })} /> */}
                </Box>
              )}
              <Paper sx={{ p: 1, mt: 2, backgroundColor: 'grey.50' }}>
              {suggestions.filter(suggestion => 
                  suggestion.Name.toLowerCase().includes(suggestionSearch.toLowerCase()) || 
                  suggestion.Text.toLowerCase().includes(suggestionSearch.toLowerCase())
                  ).map((suggestion, i) => {
                  return (() => {
                    if (suggestion.Type === "description") {
                      return (
                        <Paper 
                          onClick={() => handleSuggestionSelection(suggestion)}
                          key={i}
                          sx={{
                            mb: 1,
                            p: 1,
                            borderLeft: `4px solid ${suggestion.Label_Color}`,
                            backgroundColor: selectedSuggestion?.ID === suggestion.ID ? 'lightblue' : 'grey.200'
                          }}
                        >
                          <Typography variant="body1" component="div" sx={{ whiteSpace: 'pre-wrap' }}>
                            {/* note: appears as a function above */}
                            <Highlight text={suggestion.Text} highlight={suggestionSearch}/>  
                          </Typography>
                        </Paper>
                      );
                    } else if (suggestion.Type === "highlight") {
                      return (
                        <Paper
                          key={i}
                          sx={{
                            mb: 1,
                            p: 1,
                            bgcolor: suggestion.Color,
                            borderLeft: `4px solid ${suggestion.Color}`,
                          }}
                        >
                          <Typography variant="body1" component="div" sx={{ whiteSpace: 'pre-wrap' }}>
                            {suggestion.Text}
                          </Typography>
                        </Paper>
                      );
                    }
                  })();
                })}
              </Paper>
            </>
          )}
          {/* Suggestion Sections */}
          {tabIndex === 2 && !labelCreate && tabTargetingEnabled && (
            <>
              <TargetingTab
                token={props.token}
                setToken={props.setToken}

                label_ID={selectedLabel.ID}
                targets={targets}
                setTargets={setTargets}
                selectedType = {selectedType}
                setSelectedType = { setSelectedType}
                selectedWords = { selectedWords}
                setSelectedWords = { setSelectedWords}
                selectedId = { selectedId}
                setSelectedId = { setSelectedId}
                selectedCaseSensitivity = { selectedCaseSensitivity}
                setSelectedCaseSensitivity = { setSelectedCaseSensitivity}
              />
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default LabelsAndSuggestions;

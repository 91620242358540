import React from 'react';
import { AppBar as MuiAppBar, Toolbar, IconButton, Box, FormControl, Select, MenuItem, Avatar, styled } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Avatar1 from '../MainContent/Avatar/images/1.jpg';

const drawerWidth = 240; // Assuming this is your drawer width

const StyledAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

function CustomAppBar({ open, handleDrawerOpen }) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const [project, setProject] = React.useState('');

    const handleProjectChange = (event) => {
        setProject(event.target.value);
    };

    return (
        <StyledAppBar position="fixed" open={open} style={{ backgroundColor: 'white', color: 'black' }}>
            <Toolbar>
          {/* Left side icons */}
          {!open &&(<IconButton size="large" edge="start" aria-label="open drawer" sx={{ mr: 2 }} onClick={handleDrawerOpen} style={{ color: 'black' }} >
            <MenuIcon />
          </IconButton>)}
          {/* Search Icon and Input */}
          <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #F3F3F3',borderRadius:'50px', padding: '2px 10px', flexGrow:1, maxWidth: '100%'}}>
            <IconButton style={{ color: '#1976d2'}}>
              <SearchIcon />
            </IconButton>
            <input 
                    type="text" 
                    placeholder="Find Anything on Your Project" 
                    style={{ 
                      border: 'none', 
                      outline: 'none', 
                      padding: '5px',
                      width: matches ? '40px' : 'auto',
                      flexGrow:1,
                    }}
                  />
          </div>
          {/* Spacer */}
          <Box sx={{ flexGrow: 1 }} />
          {/* FormControl for Select */}
          <FormControl sx={{ margin: 1, flexGrow:1, maxWidth:'230px',}}>
            <Select
              value={project}
              onChange={handleProjectChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                borderRadius: '100px', border: '1px solid #F3F3F3', width: '100%',
                '.MuiSelect-select': {
                  padding: '10px',
                }
              }}>
              <MenuItem value=""><em>Landing Design</em></MenuItem>
              <MenuItem value={10}>project 1</MenuItem>
              <MenuItem value={20}>project 2</MenuItem>
              <MenuItem value={30}>project 3</MenuItem>
            </Select>
          </FormControl>

          {/* Right side icons */}
          <IconButton color="inherit">
            <TextsmsOutlinedIcon />
          </IconButton>
          <IconButton color="inherit">
            <NotificationsIcon />
          </IconButton>
          <Avatar alt="Your Name" src={Avatar1} /> {/* Replace with your Avatar image path */}
        </Toolbar>
        </StyledAppBar>
    );
}

export default CustomAppBar;
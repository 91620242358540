import React, { Component, useState } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
//import Item from './10_item'
import Column from './10_column'

//Used to send Update of columns to flask backend.

//Prod
const API = '/getatopic.py';
const API_Submit = '/updatetopicdata.py';

class Drag_n_Drop_Columns extends Component {

	constructor(props) {
		super(props)
	
		this.state = {
			topic_id:this.props.topic_id,
			Columns_List:{
				To_be_sorted: {
					id:'To_be_sorted',
					item_list: []
				},
				included: {
					id:'included',
					item_list: []
				},
				excluded: {
					id:'excluded',
					item_list: []
				}
			},
			//Dev Note:  These will need to be linked so when other component updates.  They update and force render.

            isLoading: props.isLoading
		}
	}

	updateColumns(){
		fetch(API, {
			method: 'POST', 
			body: JSON.stringify({
				//search_query: this.state.search_query
				topic_id: this.props.topic_id
			}),
			headers: new Headers({
				'Accept': 'application/json; charset=utf-8',
				'Content-Type': 'application/json',
				//'Access-Control-Allow-Origin': '*',
			})
		})
		//.then(response => response.text())          // convert to plain text
		//.then(text => console.log("fetch data: ",text))  // then log it out
		.then(response=> response.json())  // Prod
		//.then(data => console.log("fetch data: ",data))  //For Debugging
		.then((data) => this.setState({Columns_List: data, isLoading:false}), console.log(this.props.topic_id));  //set data to results
	}

	componentDidMount(){
		this.updateColumns();
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevProps.topic_id !== this.props.topic_id) {
			this.updateColumns();
		}
	}

	/*
		Handle Update Button.
		Provide JSON to flask handler at server
	*/

	   //handle a submit and the topic
    //might need to set up a refresh state comand for Topic List.
    handleSubmit = (e) => {
		e.preventDefault();
		
		console.log(this.state.Column_List)  //For Debugging
		//alert(this.state.items);
		//Add show model here
	
		fetch(API_Submit, {
			method: 'POST', 
			body: JSON.stringify({
			  //put the data in a string and send it.
			  //search_query: this.state.search_query
			  topic_id: this.props.topic_id,
			  ie_update: this.state.Columns_List
			}),
			headers: new Headers({
			  //configure headers to send JSON object.
			  'Accept': 'application/json; charset=utf-8',
			  'Content-Type': 'application/json',
			  //'Access-Control-Allow-Origin': '*',
			})
		  })
		  //.then(response => response.text())          // convert to plain text
		  //.then(text => console.log("fetch data: ",text))  // then log it out
		  .then(response=> response.json())  // get the json response
		  //.then(data => console.log("fetch data: ",data))  //For Debugging
		  
		  //check if the response returned completed or true
		  .then((data) => this.props.updateDocID(JSON.stringify({data})));  //set data to results
	  }
	
	/* Reorder an array, moving the item at $startIndex to $endIndex. */
	/*  reorder for single item list*/ 
	/*
	reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list)
		const [removed] = result.splice(startIndex, 1)
		result.splice(endIndex, 0, removed)
		return result
	}
	*/

	/* Reorder multiple arrays
		Take one list for remove
		Take another for add.
	*/
	reorder = (source_list, destination_list, startIndex, endIndex) => {
		let new_source_list = Array.from(source_list)
		let new_destination_list = Array.from(destination_list)

		if (source_list === destination_list){
			new_destination_list = new_source_list
		}

		const [removed] = new_source_list.splice(startIndex, 1)

		//result.splice(endIndex, 0, removed)

		new_destination_list.splice(endIndex, 0, removed)

		return {new_source_list, new_destination_list};
	}

	
	onDragEnd = (result) => {
		if(!result.destination) {return}
		//console.log(result)
		
		//console.log("source array: ", this.state.Columns_List, result.source.droppableId) /* for debugging */

		/* This can be bad because it accesses state similar to a pointer, need to consider making a copy */
		const source_col = this.state.Columns_List[result.source.droppableId];
		const destination_col = this.state.Columns_List[result.destination.droppableId];

		//const source_array = this.state.Columns_List[result.source.droppableId].item_list;
		//const destination_array = this.state.Columns_List[result.destination.droppableId].item_list;

		//Object.assign();

        const {new_source_list, new_destination_list}  = this.reorder (
			source_col.item_list,
			destination_col.item_list,
            result.source.index,
            result.destination.index,
		)
			
		//console.log("source array: ", source_array)
		//console.log("destination array: ", destination_array)

		//merge changes into new arrays that are copies of the state
		const new_source_col = Object.assign({}, source_col, {item_list: new_source_list});
		const new_destination_col = Object.assign({}, destination_col, {item_list: new_destination_list});
	
		/* 
			This will combine the additional items into a new obj modifing the original object, by each of the items provided, in the order they are provided.
			It will link at the highest level to do the combine, so the [name]: [variables] is important for it to do the link.

			
			https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
			const obj = Object.assign({}, o1, o2, o3);

		 */
		
		
		 const obj = Object.assign({}, this.state.Columns_List, {[result.source.droppableId]:new_source_col}, {[result.destination.droppableId]:new_destination_col});
		//console.log("Modified Items: ", modified_items)  /* for debugging */
		//console.log("obj: ", obj) /* for debugging */
		
        this.setState({Columns_List:obj})
    }

	
	render() {	
		return (
			<div>
				<DragDropContext onDragEnd={this.onDragEnd}>
					<div
					style={{
						display: 'grid',
						gridTemplateColumns: '1fr 1fr 1fr',
						margin: '24 px auto',
						width: '80%',
						gap: '8px'
					}}
					>
						{Object.values(this.state.Columns_List).map((column) =>(
							<Column col={column} key={column.id}/>
						))}
					
					</div>
				</DragDropContext>
				<div>
					<button className="secondary" onClick={this.handleSubmit}>Update</button>
				</div>
			</div>
		  )
	}
}

export default Drag_n_Drop_Columns
import React from 'react';
import { TextField, Checkbox, FormControl, FormControlLabel, Box, Button, InputLabel, FormHelperText } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';
import { Select, MenuItem, Chip, Typography } from '@mui/material';
import { useEffect } from 'react';
import { now } from 'underscore';

const API = "/checklists.api"

const AddCreateCheckWindow = ({ project_id, document_id, handleClose, tagOptions, selectedParagraph, titlesWithColorArray, checklists, setChecklists, checks, setChecks, ...props}) => {

    //WILL NEED TO MOVE TO TOP WINDOW TO SAVE STATE.
    //inputs
    const [checkText, setCheckText] = useState('');
    const [type, setType] = useState('Document');
    const [checklist, setChecklist] = useState({Title:''});
    const [referenceParagraph, setReferenceParagraph] = useState(true);  //this should create a link if selected.
    const [inputValue, setInputValue] = useState('');


    const [selectedValue, setSelectedValue] = useState(null);

    //needs to use full items as we will need the ids to pull the checks
    const handleSelectChecklist = (value) => {
        setChecklist(value);
        //fetchChecks(value);  // need to add this.
    }

    //handle tags
    const [tags, setTags] = useState([]);

    //TAG HANDLING
    // const handleTagClick = (tag) => {
    //     const updatedTags = tags.filter((t) => t !== tag);
    //     setTags(updatedTags);
    // };

    // const handleTagSelect = (event, value) => {
    //     if (tags === null) {  //check if tags is currently empty.  If so, assign a new value.
    //       const trimmedValue = value.trim();
    //       const matchedOption = tagOptions.find((option) => option.toLowerCase() === trimmedValue.toLowerCase());  //Match color etc
    //       setTags(matchedOption ? [matchedOption] : []);
    //     } else if (value && value.trim() !== '') { 
    //       const trimmedValue = value.trim();
    //       const matchedOption = tagOptions.find((option) => option.toLowerCase() === trimmedValue.toLowerCase()); //Match color etc
    //       if (matchedOption && !tags.includes(matchedOption)) {
    //         setTags([...tags, matchedOption]);
    //         setInputValue('');
    //       }
    //     }
    //   };

    
    //when enter is pressed assign the tag.
    // const handleTagKeyDown = (event) => {
    //     if (event.key === 'Enter') {
    //     const tag = event.target.value.trim();
    //     if (tag !== '' && !tags.includes(tag)) {
    //         setTags([...tags, tag]);
    //         setInputValue('');
    //     }
    //     }
    // };

    const handleTagSelect = (event, value) => {
        //if (value && value.Title) //original
        if (value) {
            const matchedOption = tagOptions.find((option) => option.ID === value.ID);
            if (matchedOption && !tags.some(tag => tag.ID === matchedOption.ID)) {
            setTags(tags === null ? [matchedOption] : [...tags, matchedOption]);
            setInputValue('');
            }
        }
        };
        
    const handleTagKeyDown = (event) => {
        if (event.key === 'Enter') {
            const tagTitle = event.target.value.trim();
            const matchedOption = tagOptions.find((option) => option.Title.toLowerCase() === tagTitle.toLowerCase());

            //make sure doesn't cause problems when the title meets multiple specifications.  If it does it shouldn't populate until 1 is selected.
            if (matchedOption && !tags.some(tag => tag.ID === matchedOption.ID)) {
            setTags([...tags, matchedOption]);
            setInputValue('');
            }
        }
        };
        
    const handleTagDelete = (tag) => {
        const updatedTags = tags.filter((t) => t.ID !== tag.ID);
        setTags(updatedTags);
    };

    const handleInputChange = (event, value) => {
        setInputValue(value);
    };

    const testTags = () => {
        console.log("tags: ", tags)
    }

    const addCheck = async () => {
        //console.log("token is: ", props.token)  //Security

        await fetch(API, {
            method: 'POST',
            mode: 'cors',
            //IMPORTANT
            //The body should contain the paragraph_id being requested.  This should be passed via props

            body: JSON.stringify({
                call_type: 'add_check',
                document_id: document_id,
                project_id: project_id,

                paragraph_id: selectedParagraph.ID,
                paragraph_text: selectedParagraph.Text,
                
                checklist_id: checklist.ID,
                check_text: checkText,
                labels: tags,
                check_type: type,
                referenceParagraph: referenceParagraph, //whether to link to the paragraph
            }),
            headers: new Headers({
                //'Accept': 'application/json; charset=utf-8',
                'Authorization': 'Bearer ' + props.token,
                'Content-Type': 'application/json',
                //'Access-Control-Allow-Origin': '*',
            })
        })
        //.then(response => response.text())          // convert to plain text
        //.then(text => console.log("fetch data: ",text))  // then log it out
        .then(response=> {
            return response.json() //convert to json.  return passes it
        })  // Prod
        //.then(data => console.log("fetch data: ",data))  //For Debugging
        .then((ResponseData) => {
            console.log("Response:", ResponseData)
            

            //check to see if a new access_token
            //console.log("new token: ", data)
            if(ResponseData.access_token !== undefined){
                console.log("setting new token")
                props.setToken(ResponseData.access_token) //if refresh token present. Set it.
            }


            // ADD CODE TO HANDLE RESPONSE WITH CHECK_ID TO ADD IT TO CHECKS.
            // AND CLEAR THE CHECK_TEXT.

            if(ResponseData.check_id !== undefined && ResponseData.checkk_id !== null){
                let Links = [];
                if (referenceParagraph) {
                    Links.push({
                      ID: null,
                      User_ID: null,
                      Doc_ID: document_id,
                      Paragraph_ID: selectedParagraph.ID,
                      Type: null,
                      Paragraph_Text: selectedParagraph.Text,
                      Comment_Text: null,
                      Created_Stamp: new Date().toISOString(),
                    });
                }
            
                const tagIds = tags.map(tag => tag.ID);

                //Add check to checks
                setChecks([...checks, { ID: ResponseData.check_id, 
                                        Title: null,
                                        Text: checkText, 
                                        Type: type, 
                                        Labels: tagIds,
                                        Color: '#ADD8E6',  //need to add color to creation
                                        Links: Links
                                    }]);
                }

        });  //set data to results
    }


    return (
        // Return the JSX from Tab here
            <>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                }}
            >
                <FormControl
                    sx={{
                        marginTop: 2,
                        marginBottom: 2,
                        width: '80%', // Set width to 80%
                    }}
                >
                <FormHelperText>Checklist:</FormHelperText>
                <Autocomplete
                    options={checklists}
                    getOptionLabel={(option) => option.Title} //needed to make sure you don't get a minimization error.  Focus on .Title element for list.
                    value={checklist}
                    onChange={(event, newValue) => handleSelectChecklist(newValue)}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        label="Checklist:"
                        sx={{
                            textAlign: 'left', // Align text to the left
                            minWidth: '50ch', // Set minimum width to 50 characters
                        }}
                        />
                    )}
                />
                </FormControl>



                <FormControl
                    sx={{
                        marginTop: 2,
                        marginBottom: 2,
                        width: '80%', // Set width to 80%
                    }}
                >
                    <FormHelperText>Type:</FormHelperText>
                        <Select
                            value={type}
                            onChange={(event) => setType(event.target.value)}
                        >
                            <MenuItem value="Document">Document</MenuItem>
                            <MenuItem value="Paragraph">Paragraph</MenuItem>
                        </Select>
                </FormControl>



                <Box
                    sx={{
                        width: '80%', // Ensure the main container is 80% of its parent's width
                        margin: 'auto', // Center the box if that's desired
                    }}
                    >
                    <FormControl
                        fullWidth
                        sx={{
                        maxWidth: '100%',
                        border: '1px solid #ccc', // Add border
                        }}
                    >
                        <FormHelperText>Labels:</FormHelperText>
                        <Autocomplete
                            fullWidth // Ensure Autocomplete takes the full width
                            options={tagOptions}
                            getOptionLabel={(option) => option.Title || ""} // Use the Title property as the label
                            value={selectedValue}
                            onChange={(event, newValue) => {
                                setSelectedValue(newValue);
                                handleTagSelect(event, newValue);
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                              }}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label="Type here"
                                onKeyDown={handleTagKeyDown}
                                fullWidth // Make TextField take the full width
                                variant="outlined"
                                sx={{ mb: 2 }}
                                />
                            )}
                        />

                        <Box
                            alignItems="center"
                            flexWrap="wrap"
                            mb={2}
                            sx={{
                                backgroundColor: '#f5f5f5', // Set background color to light grey
                                p: 2,
                                marginX: 'auto', // Shorthand for marginLeft and marginRight; aka center the box
                                width: '90%', // Ensure this Box takes the full width of its parent
                                height: 80,
                                overflow: 'auto',
                            }}
                        >
                            {tags !== null &&
                            tags !== undefined &&
                            tags.map((tag) => {
                                return (
                                <Chip
                                    key={tag.ID}
                                    label={tag.Title}
                                    onDelete={() => handleTagDelete(tag)}
                                    color="primary"
                                    sx={{ mr: 1, mt: 1, backgroundColor: tag.Color }}
                                />
                                );
                            })}
                        </Box>
                    </FormControl>
                    </Box>


                <FormControl
                    sx={{
                        marginTop: 2,
                        marginBottom: 2,
                        width: '80%', // Set width to 80%
                    }}
                >
                    <FormHelperText>Check text:</FormHelperText>
                    <TextField
                        multiline
                        rows={5}
                        variant="outlined"
                        value={checkText}
                        onChange={(event) => setCheckText(event.target.value)}
                    />
                </FormControl>


                <FormControl
                    sx={{
                        marginTop: 2,
                        marginBottom: 2,
                        width: '80%', // Set width to 80%
                    }}
                >
                <FormControlLabel
                    control={<Checkbox checked={referenceParagraph} onChange={(event) => setReferenceParagraph(event.target.checked)} />}
                    label="Include Reference Paragraph?:"
                    sx={{
                        marginBottom: 0, // Remove bottom margin
                    }}
                />
                    <Box
                        sx={{
                            bgcolor: '#f5f5f5',
                            p: 2,
                            width: '100%', // Set width to 80%
                            height: 160,
                            overflow: 'auto',
                            marginTop: 0, // Remove top margin
                        }}
                    >
                        {selectedParagraph && selectedParagraph.Text ? (
                            <Typography>
                                {selectedParagraph.Text}
                            </Typography>
                        ) : (
                            <Typography>
                                No text available
                            </Typography>
                        )}
                    </Box>
                </FormControl>

                <Typography variant="h6" component="h2" align="center" gutterBottom>
                    Similar Checks (in development)
                </Typography>

                <Box
                    sx={{
                    border: '1px solid #ccc',
                    bgcolor: '#f5f5f5',
                    p: 2,
                    width: '80%',
                    height: 100,
                    overflow: 'auto',
                    marginBottom: 10,
                    }}
                >
                    {/* Similar checks go here */}
                </Box>

            </Box>



            <Box
                sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                }}
            >
                <Button variant="contained" onClick={handleClose}>Cancel</Button>

                <Button 
                    variant="contained" 
                    color="primary" 
                    disabled={!checklist||!type||!checkText} // Disable if no values are selected
                    //onClick={addCheck}
                    onClick={addCheck}
                >
                    Add Check
                </Button>
            </Box>
            </>
        );
};

export default AddCreateCheckWindow;
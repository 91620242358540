import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Checkbox from '@mui/material/Checkbox'; 
import ImageIcon from '@mui/icons-material/Image';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import GradingIcon from '@mui/icons-material/Grading';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ChecklistIcon from '@mui/icons-material/Checklist';
import BookIcon from '@mui/icons-material/Book';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ThreePIcon from '@mui/icons-material/ThreeP';
import BalanceIcon from '@mui/icons-material/Balance';
import LanguageIcon from '@mui/icons-material/Language';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
// ... and the rest of the regular icons


import { matchingNames } from './InformationGroup';
import { getPathsForNodesWithChildren } from './InformationGroup';

function getIconStyle(type, color, isOutlined) {
    const commonStyle = { color: color };

    const outlinedStyle = {
        color: 'white',
        borderRadius: '3px',
        backgroundColor: color,
        padding: '2px'
    };

    const borderStyle = {
        color: color,
        border: `2px solid ${color}`,
        borderRadius: '3px',
        padding: '0 1px 0 1px'
    };

    switch (type) {
        case 'drafts':
        case 'templates':
        case 'checklists':
        case 'labels':
        case 'research_group':
            return isOutlined ? outlinedStyle : borderStyle;
        default:
            return commonStyle;
    }
}

function getIconForType(type, color, isOutlined=false) {
    //console.log(type, color);

    const icons = {
        folder: isOutlined ? FolderIcon : FolderOpenIcon,
        image: ImageIcon,
        slideshow: SlideshowIcon,
        zip: FolderZipIcon,
        video: VideoFileIcon,
        audio: AudioFileIcon,
        document: InsertDriveFileIcon,
        draft: isOutlined ? EditNoteOutlinedIcon : EditNoteIcon,
        drafts: isOutlined ? EditNoteOutlinedIcon : EditNoteIcon,
        template: GradingOutlinedIcon,
        templates: isOutlined ? GradingOutlinedIcon : GradingIcon,
        checklist: ChecklistOutlinedIcon,
        checklists: isOutlined ? ChecklistOutlinedIcon : ChecklistIcon,
        label: BookIcon,
        labels: isOutlined ? BookIcon : BookOutlinedIcon,
        research_group: MenuBookIcon,
        comment: ThreePIcon,
        legal: BalanceIcon,
        website: LanguageIcon
    };

    const IconComponent = icons[type] || InsertDriveFileIcon;
    return <IconComponent style={getIconStyle(type, color, isOutlined)} />;
}



const useStyles = makeStyles({
  root: {
    height: '100%',  
    maxHeight: '500px', 
    flexGrow: 1,
    maxWidth: 400,
    padding: '5px 10px',
    border: 0,
    overflow: 'auto',
  },
  labelRoot: {
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
  },
});

function updateChildNodes(checkedNodes, node, checkedStatus) {
  if (node.children) {
    for (let child of node.children) {
      if (checkedStatus && !checkedNodes.includes(child.name)) {
        checkedNodes.push(child.name);
      } else if (!checkedStatus && checkedNodes.includes(child.name)) {
        const index = checkedNodes.indexOf(child.name);
        checkedNodes.splice(index, 1);
      }
      updateChildNodes(checkedNodes, child, checkedStatus);
    }
  }
  return checkedNodes;
}

export default function FileView({ data, isSorted, new_color, parentComponent, expandedPaths, checkedItems, setCheckedItems, checkedNodes, setCheckedNodes, isSelectionChanged, setSelectionChanged}) {
  
  //console.log("aaaa",data);
    
  const [expanded, setExpanded] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false);

  data = data || [];

  function getIdsFromCheckedNodes(checkedNodes, data) {
    if (!data.children || !Array.isArray(data.children)) {
        // If data.children is not an array or does not exist, return an empty array
        return [];
    }

    const ids = data.children
        .filter(item => checkedNodes.includes(item.u_id))
        .map(item => item.id);

    return ids;
}
    
  useEffect(() => {
    let updatedExpanded = expandedPaths;
    const res = filterExpandedNodes(data);
    if (res) {
        updatedExpanded = [];
        const paths = getPathsForNodesWithChildren(res);
        updatedExpanded = paths;
    }
    setExpanded(updatedExpanded);

}, [data, expandedPaths]);


// useEffect to log changes to checkedNodes when updateFlag changes
// Pass Checked IDs back to Parent for use in query
useEffect(() => {
  console.log("CheckedNodes are:", checkedNodes);
  console.log("data: ", data)

  var new_checked = getIdsFromCheckedNodes(checkedNodes, data)
  console.log("new_checked: ", new_checked)
  setCheckedItems(new_checked)
  // Additional code to execute after setCheckedNodes
}, [updateFlag]);

//console.log('result', expandedPaths);
  const classes = useStyles();
  
  const [selectedNodes, setSelectedNodes] = useState([]);
  //const [checkedNodes, setCheckedNodes] = useState([]);  //need to pass this because it need to be used in the parent component
  const [typesList, setTypesList] = useState([]);
  new_color = data && new_color ? new_color : null;

  const handleNodeSelect = (event, nodeIds) => {
    setSelectedNodes(nodeIds);
  };

function updateChildNodes(checkedNodes, node, nodePath, checkedStatus) {
  if (node.children) {
    for (let child of node.children) {
      const childPath = `${nodePath}/${child.u_id}-${child.name}`;
      if (checkedStatus && !checkedNodes.includes(childPath)) {
        checkedNodes.push(childPath);
      } else if (!checkedStatus && checkedNodes.includes(childPath)) {
        const index = checkedNodes.indexOf(childPath);
        checkedNodes.splice(index, 1);
      }
      updateChildNodes(checkedNodes, child, childPath, checkedStatus);
    }
  }
  return checkedNodes;
}

//recursivelytraverse the tree structure starting from a given node and collect the u_id of each node it encounters
//NOTE: CONSIDER GETTING ID HERE AS WELL SO THAT YOU DON"T NEED THE USE EFFECT.  SHOULD HELP WITH PROCESSING.
const handleCheck = (item, nodePath) => {

    // Check the initial node and set selection change
    // Note: not sure if this also activates when a node is checked off.
    if (item.u_id === "1" && isSelectionChanged !== false) {
      setSelectionChanged(false); //reflect that all items are selected
    } else if (item.u_id !== "1" && isSelectionChanged !== true) {
      setSelectionChanged(true);  //reflect that a selection has been made
    }

    const traverseChildren = (node, ids = []) => {
        ids.push(node.u_id); // Assuming each node has a unique id in `u_id`
        if (node.children) {
            node.children.forEach(child => traverseChildren(child, ids));
        }

        return ids;
    };

    const itemAndChildrenIds = traverseChildren(item);
    
    if (checkedNodes.includes(item.u_id)) {
        // Uncheck item and all its children
        setCheckedNodes(checkedNodes.filter(id => !itemAndChildrenIds.includes(id)));
    } else {
        // Check item and all its children
        setCheckedNodes([...checkedNodes, ...itemAndChildrenIds]);
    }

    // Trigger the update flag
    setUpdateFlag(prevFlag => !prevFlag);
};

function filterExpandedNodes(data) {
    // If the node is not expanded, return null
    if (!data.expanded) {
        return null;
    }

    // Create a shallow copy of the data
    let newData = { ...data };

    // If the node has children, recursively process them
    if (newData.children) {
        newData.children = newData.children
            .map(child => filterExpandedNodes(child))
            .filter(Boolean);
    }

    return newData;
}

function getPathsForNodesWithChildren(data, path = []) {
    let paths = [];
    
    const newPath = [...path, data.name];

    // Add the path if the node has children
    if (data.children) {
        paths.push(newPath.join('/'));
        for (let child of data.children) {
            paths = paths.concat(getPathsForNodesWithChildren(child, newPath));
        }
    }

    return paths;
}

const renderCheckboxIcon = (node, checkedNodes,checkedItems, handleCheck, nodePath) => (
  <>
    <Checkbox
      checked={checkedItems.includes(node.id)} // Replace item.id with the actual item identifier
      //checked={checkedNodes.includes(node.u_id)}
      //checked={checkedNodes.includes(nodePath)}  // Use nodePath
      onClick={(event) => {
        event.stopPropagation();
        handleCheck(node, nodePath);
      }}
    />
  </>
);

const renderTree = (nodes, parentPath = '') => {
    if (nodes.length === 0){
     return (
        <TreeItem
            key="not-result"
            nodeId="not-result"
            label="No Results Found"
            id="noResultsTreeItem"
            classes={{
                label: classes.label,
                labelRoot: classes.labelRoot,
                selected: classes.selected,
            }}
        />
    );
    }
    if (nodes.length!==0 && Array.isArray(nodes)) {
        return nodes.map((node) => renderTree(node, parentPath));
    }
    //const nodePath = parentPath ? `${parentPath}/${nodes.u_id}-${nodes.name}` : `${nodes.u_id}-${nodes.name}`;
    //const nodePath = parentPath ? `${parentPath}/${nodes.u_id}-${nodes.name}` : `${nodes.u_id}-${nodes.name}`;
    const nodePath = parentPath ? `${parentPath}/${nodes.u_id}-${nodes.name}` : `${nodes.u_id}-${nodes.name}`;
    const labelContent = (
        <div className={classes.labelRoot}>
            {renderCheckboxIcon(nodes, checkedNodes, checkedItems, handleCheck, nodePath)}
            {nodes.name}
        </div>
    );
    
    //const sortedChildren = nodes.children ? [...nodes.children].sort((a, b) => a.name.localeCompare(b.name)) : null;
    const sortedChildren = isSorted && nodes.children 
        ? [...nodes.children].sort((a, b) => a.name.localeCompare(b.name)) 
        : nodes.children;
    return (
        <TreeItem
            key={nodePath}
            nodeId={nodePath}
            label={labelContent}
            classes={{
                label: classes.label,
                labelRoot: classes.labelRoot,
                selected: classes.selected,
            }}
            onLabelClick={() => console.log(nodes.name)}
            onClick={() => console.log(nodes.name)}
            selected={selectedNodes.includes(nodePath)}   // Updated nodes.name to nodePath
            endIcon={getIconForType(nodes.type, '#808080')}
            collapseIcon={getIconForType(nodes.type, new_color || "#2e7bcf", false)}
            expandIcon={getIconForType(nodes.type, new_color || "#2e7bcf", true)}
        >
            
            {sortedChildren ? sortedChildren.map((node) => renderTree(node, nodePath)) : null}
        </TreeItem>
    );
};

  return (
    <TreeView
      className={classes.root}
      
      onNodeToggle={(event, nodeIds) => {
        setExpanded(nodeIds);
      }}
      defaultCollapseIcon={getIconForType(data.type, new_color || "#2e7bcf",false )}
      defaultExpandIcon={getIconForType(data.type, new_color || "#2e7bcf", true)}
      defaultEndIcon={typesList}
      multiSelect
      selected={selectedNodes}
    >
      {renderTree(data)}
      
    </TreeView>
  );
}
// Copyright © 2024 Ulomate LLC. All rights reserved.
// This source code is proprietary and may not be copied, distributed 
// or shared without the express written consent of Ulomate LLC.

import React, { useRef } from 'react';
import { Box, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';

import {Checkbox, FormControlLabel} from '@mui/material';

const HighlighterParagraph = ({ 
  paragraph,
  showLabels,
  labels,
  isParagraphSelected,
  selectedParagraphRef,
  handleParagraphClick,
  index,
  bgcolor,
  highlightedRanges,
  highlightColor,
  setActiveParagraphIndex }) => {
  
  const isHighlighted = (index) => {
    const latestHighlight = highlightedRanges.slice().reverse().find(range => 
      index >= range.start && index < range.end
    );
    return latestHighlight;
  };



//   const findSelection = () => {

//     const paragraphRef = useRef(null); // Add a ref for the paragraph

//     const selection = window.getSelection();
//     if (!selection.isCollapsed && paragraphRef.current) {
//         const range = selection.getRangeAt(0);
//         let startCharCount = 0;
//         let endCharCount = 0;
//         let foundStart = false;

//         const iterateNodesForStart = (node) => {
//             if (node === range.startContainer) {
//                 // Add the start offset once the start container is found
//                 startCharCount += range.startOffset;
//                 foundStart = true;
//                 // If the start and end containers are the same, add the end offset here and stop
//                 if (range.startContainer === range.endContainer) {
//                     endCharCount = startCharCount + (range.endOffset - range.startOffset);
//                 }
//             } else if (!foundStart && node.nodeType === Node.TEXT_NODE) {
//                 // Accumulate text lengths until the start container is found
//                 startCharCount += node.textContent.length;
//             }
//             // Recursively handle child nodes
//             if (!foundStart) node.childNodes.forEach(iterateNodesForStart);
//         };

//         const iterateNodesForEnd = (node) => {
//             if (!foundStart) return; // Don't start counting until start has been found
//             if (node === range.endContainer) {
//                 // Once the end container is found, add the end offset and stop
//                 endCharCount += range.endOffset;
//                 return;
//             } else if (node.nodeType === Node.TEXT_NODE) {
//                 // Continue accumulating text lengths to calculate the end position
//                 endCharCount += node.textContent.length;
//             }
//             // Recursively handle child nodes
//             node.childNodes.forEach(iterateNodesForEnd);
//         };

//         // Start iteration from the paragraph element for start
//         iterateNodesForStart(paragraphRef.current);
//         // If the end container is different, start a new iteration for the end
//         if (range.startContainer !== range.endContainer) {
//             iterateNodesForEnd(paragraphRef.current);
//         }

//         console.log(`Selection starts at position: ${startCharCount}, ends at: ${endCharCount}`);
//     }
// };


  const handleMouseUp = () => {
    // Set this paragraph as the active one when text is selected
    setActiveParagraphIndex(index);
  };

  const renderTextWithHighlights = (paragraph, highlightedRanges) => {
    //ERRORS--- THIS STILL HIGHLIGHTS THE FIRST WORD IN A SECTION.
    //NEED TO ADD A CONTROL Z and a CONTROL Y to redo.
    //NEED TO ADD THE ABILITY TO ONLY SEE SPECIFIC HIGHLIGHTS.
    //NEED TO ADD THE ABILITY TO REMOVE HIGHLIGHTS BY SELECTING.

    console.log("renderTextWithHighlights:", 
    "paragraph:", paragraph, 
    "highlightedRanges:", highlightedRanges,
    "sortedRanges:", sortedRanges);

    // Sort highlights by start position to handle them in sequence
    const sortedRanges = highlightedRanges.sort((a, b) => a.start - b.start);
  
    let mergedRanges = [];
  
    sortedRanges.forEach(current => {
      // Process the current range against already mergedRanges
      let newMergedRanges = [];
      let overlapFound = false;
  
      mergedRanges.forEach(existing => {
        if (current.start > existing.end || current.end < existing.start) {
          // No overlap, keep existing range
          newMergedRanges.push(existing);
        } else {
          // Overlap found, process it
          overlapFound = true;
          if (current.start > existing.start) {
            // Add the non-overlapping part of the existing range
            newMergedRanges.push({ ...existing, end: current.start });
          }
          if (current.end < existing.end) {
            // Split existing range if current ends before existing range
            newMergedRanges.push({ ...existing, start: current.end });
          }
          // Add or merge current range, ensuring not to duplicate the addition
          if (!newMergedRanges.includes(current)) {
            newMergedRanges.push(current);
          }
        }
      });
  
      if (!overlapFound) {
        newMergedRanges.push(current);
      }
  
      mergedRanges = newMergedRanges;
    });
  
    // Now, sort mergedRanges again since new ranges could be out of order
    mergedRanges.sort((a, b) => a.start - b.start);
  
    // Generate segments for rendering based on mergedRanges
    const segments = [];
    let lastIndex = 0;
    mergedRanges.forEach(range => {
      if (lastIndex < range.start) {
        segments.push({
          text: paragraph.Text.slice(lastIndex, range.start),
          highlight: false,
        });
      }
      segments.push({
        text: paragraph.Text.slice(range.start, range.end),
        highlight: true,
        color: range.color,
      });
      lastIndex = range.end;
    });
  
    // Add the remaining text if there's any
    if (lastIndex < paragraph.Text.length) {
      segments.push({
        text: paragraph.Text.slice(lastIndex),
        highlight: false,
      });
    }
  
    // Render the segments as Typography components
    return segments.map((segment, index) => (
      <Typography
        component="span"
        key={index}
        sx={{
          backgroundColor: segment.highlight ? segment.color : 'transparent',
          display: 'inline',
        }}
      >
        {segment.text}
      </Typography>
    ));
  };  
    //end renderTextWithHighlights
  

  return (
      <Paper
          key={paragraph.ID}
          data-paragraph-id={paragraph.ID} // for text detection
          page-id={paragraph.File_Page_Number} // for text detection
          variant={showLabels ? 'elevation' : 'plain'}
          sx={{
            mb: 1,
            p: 1,
            bgcolor: isParagraphSelected ? 'action.selected' : 'background.paper',
            cursor: 'pointer',
            borderLeft: isParagraphSelected ? '4px solid #3f3f3f' : 'none',
          }}
          onClick={() => handleParagraphClick(index, paragraph.ID)}  //Select the paragraph.
          ref={isParagraphSelected ? selectedParagraphRef : null}  //this might need to be fixed
          onMouseUp={handleMouseUp} //For highlighting the paragraph  #removed for testing.  This was for click and drag highlighting.
          onContextMenu={(e) => e.preventDefault()}  // Add this line to prevent the default context menu on mobile
      >
          {showLabels !== null && showLabels !== undefined && showLabels && (
            <Box sx={{ mb: 1, display: 'flex', flexWrap: 'wrap' }}>
              {showLabels && (
              <>
                {/* Only show checkbox when multiSelectMode is true */}
                {false && (
                  <Checkbox
                    // checked={isMultiSelected}
                    checked={false}
                    // onChange={handleCheckboxChange}
                    sx={{ padding: 0, margin: '0 0px 0 0' }} // Optional: adjust padding and margin
                  />
                )}
                {labels.map((label) => (
                  label.Paragraph_ID === paragraph.ID && (
                    <Chip
                      key={label.Label_ID}
                      label={label.Inserted_by === "machine" ? `(m) ${label.Label_Title}` : label.Label_Title}  // Add (m) for machine generated labels
                      variant="outlined"
                      size="small"
                      sx={{
                        mr: 1,
                        mt: 1,
                        bgcolor: label.Color,
                        fontSize: '8pt',
                        color: 'white', // Set the text color to white
                        border: '1px solid #ccc',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        lineHeight: '1',
                        height: '1',
                        '& .MuiChip-deleteIcon': {
                          width: '12px',
                          height: '12px',
                        },
                      }}
                    />
                  )
                ))}
              </>
            )}
          </Box>
          )}

          

          { //THIS STILL HAS AN ISSUE.  WHEN YOU HIGHLIGHT BETWEEN TWO HIGHLIGHT SECTIONS, THE CODE COPIES TEXT TO A LATER PORTION
            //WE NEED TO FIX THIS

            paragraph && paragraph.Text && (highlightedRanges && highlightedRanges.length > 0 ? renderTextWithHighlights(paragraph, highlightedRanges) :
            <Typography component="span">{paragraph.Text}</Typography>)
            
            // paragraph && paragraph.Text && (
            //   highlightedRanges && highlightedRanges.length > 0 ?
            //   highlightedRanges.sort((a, b) => a.timestamp - b.timestamp).reduce((mergedRanges, range) => {
            //     const newRanges = [];
            //     let hasOverlap = false;
            //     for (const existingRange of mergedRanges) {
            //       if (existingRange.end <= range.start || existingRange.start >= range.end) {
            //         // If the current range does not overlap with the existing range, add the existing range to newRanges
            //         newRanges.push(existingRange);
            //       } else {
            //         // If the current range overlaps with the existing range, split the existing range and insert the current range
            //         hasOverlap = true;
            //         if (existingRange.start < range.start) {
            //           newRanges.push({ ...existingRange, end: range.start });
            //         }
            //         newRanges.push(range);
            //         if (range.end < existingRange.end) {
            //           newRanges.push({ ...existingRange, start: range.end });
            //         }
            //       }
            //     }
            //     if (!hasOverlap) {
            //       // If the current range does not overlap with any range in mergedRanges, add it to newRanges
            //       newRanges.push(range);
            //     }
            //     return newRanges;
            //   }, []).reduce((segments, range, rangeIndex) => {
            //     // For each range, add a segment before the highlight and a segment for the highlight
            //     const prevEnd = rangeIndex > 0 ? segments[segments.length - 1].end : 0;
            //     const beforeHighlight = paragraph.Text.slice(prevEnd, range.start);
            //     const highlightedText = paragraph.Text.slice(range.start, range.end);
            //     return [
            //       ...segments,
            //       { text: beforeHighlight, highlight: false, end: range.start },
            //       { text: highlightedText, highlight: true, color: range.color, end: range.end }
            //     ];
            //   }, []).concat({ text: paragraph.Text.slice(highlightedRanges.sort((a, b) => b.end - a.end)[0].end), highlight: false }).map((segment, segmentIndex) => (
            //     <Typography
            //       component="span"
            //       key={segmentIndex}
            //       sx={{
            //         backgroundColor: segment.highlight ? segment.color : 'transparent',
            //         display: 'inline'
            //       }}
            //     >
            //       {segment.text}
            //     </Typography>
            //   )) :
            //   <Typography component="span">{paragraph.Text}</Typography>
            // )
          }
      </Paper>
  );
};

export default HighlighterParagraph;
import React, { Component } from 'react'

/*  NOTES

Will need to add values and have them show up into the list.
Drag and drop should detect an ordering number.
When the item is placed all numbers should be updated in an array of sorts.
Then submit can just forward the already constructed data to teh server.

*/


var placeholder = document.createElement("li");
placeholder.className = "placeholder";

class DND_List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {...props};
  }


  dragStart(e) {
    this.dragged = e.currentTarget;
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', this.dragged);
  }


  dragEnd(e) {
    this.dragged.style.display = 'block';
    this.dragged.parentNode.removeChild(placeholder);
    
    // update state
    var data = this.state.colors;
    var from = Number(this.dragged.dataset.id);
    var to = Number(this.over.dataset.id);
    if(from < to) to--;
    data.splice(to, 0, data.splice(from, 1)[0]);
    this.setState({colors: data});
  }


  dragOver(e) {
    e.preventDefault();
    this.dragged.style.display = "none";
    if(e.target.className === 'placeholder') return;
    this.over = e.target;
    e.target.parentNode.insertBefore(placeholder, e.target);
  }


  handleSubmit(e){
    e.preventDefault();
    var lis = document.getElementById("dnd_list").getElementsByTagName("li");
    //Loop through values and do stuff.
    for (let item of lis) {
        console.log(item.textContent);  //presents data to console.
    }

    //alert(lis.textContent);
    //alert(values);
    
    /*
    //Get Values in Order
    //var list_values = document.getElementById("dnd_list").getElementsByTagName("li");
    (".dnd_list").find("li").each(function()
    {
       var li=(this);                
       alert(li.text()) 
    });
    */

    //Call AJAX Fetch to make call to server to combine pdf.

    //Update when done

    //alert(list_values);
  }
	render() {
    var listItems = this.state.colors.map((item, i) => {
      return (
          //create customize each row here.
        <li
          data-id={i}
          key={i}
          draggable='true'
          onDragEnd={this.dragEnd.bind(this)}
          onDragStart={this.dragStart.bind(this)}>{item}
        </li>
      )
     });
		return (
            <div className="dnd_list" id="dnd_list">
                <form onSubmit={this.handleSubmit}>
                    <ul onDragOver={this.dragOver.bind(this)}>
                        {listItems}
                    </ul>
                    <button>Save Changes</button>
                </form>
            </div>
        )
    }
}

export default DND_List
import React from 'react';
import { Box, IconButton } from '@mui/material';

// import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import PushPinIcon from '@mui/icons-material/PushPin';
// import LabelIcon from '@mui/icons-material/Label';
// import ExpandIcon from '@mui/icons-material/Expand';

import ExpandParaButton from './expandbutton';
import LabelButton from '../../LabelButton/00_labelbutton';
import CopyButton from './copybutton';
import PushpinButton from './pinbutton';

export default function ActionButtonGroup({hit, searchQuery, ...props}) {
  const iconButtonStyle = {
    // backgroundColor: '#1976d2',
    // color: 'white',
    borderRadius: '5px',
    margin: '1px 1px',
    padding: '5px 10px'
  };

  //console.log("ActionButtonGroup:", hit);

  return (

    <Box sx={{ position: 'absolute', top: '4px', right: '4px', display: 'flex', zIndex: '98'}}> {/* using a box or div so I can add multiple buttons.  */}
       <CopyButton
        aria-label="copy"
        variant="contained"
        iconButtonStyle={iconButtonStyle}
        text={hit["_source"]["Text"]}  //remember the imported variables need to be surrounded with {}.
        //text={hit?._source.Text}  //use to fix undefined errors
      />
      <ExpandParaButton
        iconButtonStyle={iconButtonStyle}
      />
      <PushpinButton
        iconButtonStyle={iconButtonStyle}
      />
      <LabelButton 
        token = {props.token} 
        setToken={props.setToken}
        paragraph_id={hit["_source"]['ID']}
        iconButtonStyle={iconButtonStyle}
      />
    </Box>
  );
}
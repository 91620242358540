import React, { useState } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SortIcon from '@mui/icons-material/Sort';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function SorterSpeedDial({ onSort, sortOrder }) {
    const [open, setOpen] = useState(false);

    const actions = [
        { icon: <SortIcon />, name: 'Default', order: 'none' },
        { icon: <ArrowUpwardIcon />, name: 'Ascend', order: 'asc' },
        { icon: <ArrowDownwardIcon />, name: 'Descend', order: 'desc' },
    ];

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleAction = (order) => {
        onSort(order);
        handleClose();
    };

    const activeIcon = actions.find(a => a.order === sortOrder)?.icon || <SortIcon />;

    return (
        <SpeedDial
            ariaLabel="Sort Speed Dial"
            icon={activeIcon}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction="left"
            sx={{
                position: 'absolute',
                top: theme => theme.spacing(0.5),
                left: theme => theme.spacing(8),
                backgroundColor: 'transparent',
                boxShadow: 'none',
                '& .MuiFab-root': {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                },
            }}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={() => handleAction(action.order)}
                    sx={{
                        '&.MuiSpeedDialAction-fab': {
                            backgroundColor: 'white',
                            '&:active': {
                                backgroundColor: 'white',
                            },
                            '&:focus': {
                                backgroundColor: 'white',
                            },
                        },
                    }}
                />
            ))}
        </SpeedDial>
    );
}

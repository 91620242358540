import React, { Component } from 'react'
import { groupBy, map } from 'underscore'
//import groupBy from '_'
import './09_topics_page.css'
import Drag_n_Drop_Columns from "./10_dnd_columns_for_topic"
import Topics_List from "./09_topics_list"
import New_Topic_Box from "./09_new_topic_box"

const API = '/index.py';  //Prod  Check

class Topics_Page extends Component {

	constructor(props) {
		super(props)
	
		this.state = {
			Topic_to_Display: '', //Debugging. Set to 1.
			//Dev Note:  These will need to be linked so when other component updates.  They update and force render.
		}
	}
	
	//may need to use to call other components
	handleClick = (event, a_value, b_value) => {
		//alert(a_value);
		this.props.parentCallback({ pdfURL:a_value, pageNumber:b_value });
	}

	forceRefresh = () => {
        // Force a render with a simulated state change
		this.setState({ state: this.state });
		//alert("refreshed!")  //For Debugging.
	}
	
	
	set_Topic_to_Display = (topic_ID) => {
        // Force a render with a simulated state change
		this.setState({ Topic_to_Display: topic_ID});
		//alert("refreshed!")  //For Debugging.
	}
	

	//Dev Note: this will need to be changed to refresh when hits value is received.

	render() {
		//Make sure we have a topic to display
		if(this.state.Topic_to_Display){
			console.log("DEFINED: ", this.state.Topic_to_Display)

			return(
				<div>
					<New_Topic_Box refresh_Parent={this.forceRefresh}/>
					<Topics_List set_Topic_to_Display={this.set_Topic_to_Display}/>
					<Drag_n_Drop_Columns topic_id={this.state.Topic_to_Display}/>
				</div>
			)
		} else {

			return(
				<div>
					<New_Topic_Box refresh_Parent={this.forceRefresh}/>
					<Topics_List set_Topic_to_Display={this.set_Topic_to_Display}/>
				</div>
			);
		}

	}
}

export default Topics_Page
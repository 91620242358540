import React, { Component } from 'react'
import MULTI_RJS_PDF_Viewer from './05_MultiPDF'

//npm i react-pdf

//This will be designed so that it receives a pdf URL which includes sometimes a specific page.
//The hope is that when the user clicks a link, it will allow them to jump to specific pages of the pdf without having to completely rerender the pdf.
/*

Based on 02_pdfviewer

*/
class MultiPDF_Viewer extends Component {

	constructor(props) {
		super(props);
		//this.viewerRef = React.createRef();
    	//this.backend = new props.backend();
	
		this.state = {
			//Dev Note:  These will need to be linked so when other component updates.  They update and force render.
			 pdfURL: [],
            isLoading: false
		}
	}
	
	/*
	componentDidMount() {
		const { src } = this.props;
		//const element = this.viewerRef.current;  //pdfjs parts.
	  }
*/

	render() {
		

		//first check if pdf_url is defined
		if (this.props.src) {
			
			/*if yes, show the pdf*/
			//alert(this.props.src)

			return(
				//<div ref={this.viewerRef} id='viewer' style={{ width: '100%', height: '100%' }}></div>
				<MULTI_RJS_PDF_Viewer
					src = {this.props.src}
					pageNumber = {this.props.pageNumber}
					startPage = {this.props.startPage}
					endPage = {this.props.endPage}
				/>
				
			);
		} else {
			//If not URL specified, return nothing.
			return(
				/*
					Problem:  apparently this is only rendering to a small part of page.
					Solution: There is a parent div that must be set to 100%
				*/
				<div className="PDF_Viewer" height="100%">
				    <p>PDF DATA NOT LOADED</p>
				</div>
			);
		}
	}
}

export default MultiPDF_Viewer
// Copyright © 2024 Ulomate LLC. All rights reserved.
// This source code is proprietary and may not be used, copied, distributed 
// or shared without the express written consent of Ulomate LLC.

import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';

//import highlighter
import Highlighter_Sidebar from '../Checklists/Sidebar';
//import InfoPanel from './InfoPanel';
//import ParagraphPanel from './ParagraphPanel';
import ParagraphPanel from './ParagraphPanel';  //testing new paragraph panel

import LabelButton from '../LabelButton/00_labelbutton';

//for annotations
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';

//for add check
import AddCheckButton from '../Checklists/addCheckButton/addCheckButton';

import LinksModal from '../Checklists/LinksModal';

const switchColor = '#1976d2'; // Replace with the specific color used by the switch

const API = "/initialanalyzer.api"

function AnalyzerPage(props) {
  const [selectedParagraphIndex, setSelectedParagraphIndex] = React.useState(0);
  const [selectedParagraphID, setSelectedParagraphID] = React.useState(0);
  const [showLabels, setShowLabels] = React.useState(false);
  const [showRightSection, setShowRightSection] = React.useState(false);

  const [comments, setComments] = React.useState([]);

  const [checks, setChecks] = React.useState([]);  //for checks

  const [responses, setResponses] = React.useState([]);  //for check responses

  //linksModal
  const [linksModalIsOpen, setLinksModalIsOpen] = useState(false);
  const [selectedCheck, setSelectedCheck] = useState(null);


  const iconButtonStyle = {
    backgroundColor: '#424242',  //grey background
    color: '#0288d1',  //icon color, lighter blue
    borderRadius: '5px',
    margin: '1px 1px',
    padding: '5px 10px'
  };

  //START FOR SCROLLING ADDITIONS
  const selectedParagraphRef = useRef(null);

  //For Checklists
  // Initialize highlightColor state with a default color (you can change it as needed)
  const [highlightState, setHighlightState] = useState({
    shouldHighlight: false,
    highlightColor: undefined,
    highlightingObject: {},
    Paragraph_ID: 0 //this might not be needed.
  });
  
  // const [shouldHighlight, setShouldHighlight] = useState(false);
  // const [highlightColor, setHighlightColor] = useState(); 
  // const [highlightingObject, setHighlightingObject] = useState({}); // Add a new state for the highlighting object

  const contentRef = useRef(null);

  // Update handleHighlightTrigger to accept a color parameter
  const handleHighlightTrigger = (item, paragraph_id) => {
    console.log('Highlight color received:', item.Color);
    console.log('Highlight item received:', item);
    console.log('Paragraph ID:', paragraph_id);

    setHighlightState(prevState => ({
      ...prevState,
      shouldHighlight: true,
      highlightColor: item.Color,
      highlightingObject: item,  //NEED TO ADD THE OBJECT HERE THAT IDENTIFIES THE LABEL, ETC.
      Paragraph_ID: paragraph_id
    }));

    //old code
    // setShouldHighlight(true);
    // setHighlightColor(color); // Update highlight color when triggered

  };
  //END SCROLLING ADDITIONS
  
//   const paragraphsInitialState = [
//     { ID: 1, Text: "Note1" },
//     { ID: 2, Text: "Note2" },
//     { ID: 3, Text: "Note3" },
//     { ID: 4, Text: "Note4" },
//     { ID: 5, Text: "Note5 with a long text that can span multiple lines and still display properlyNote5 with a long text that can span multiple lines and still display properlyNote5 with a long text that can span multiple lines and still display properlyNote5 with a long text that can span multiple lines and still display properly\n\nNote5 with a long text that can span multiple lines and still display properlyNote5 with a long text that can span multiple lines and still display properly" },
//     { ID: 6, Text: "Note6" },
//   ];
  const paragraphsInitialState = [];

//   const ParagraphSuggestionsInitialState = {
//     1: [{ contentType: "description", content: "Content1" }, { contentType: "description", content: "Content2" }, { contentType: "description", content: "Content3" }],
//     2: [{ contentType: "description", content: "Content4" }],
//     3: [{ contentType: "description", content: "Content5" }, { contentType: "description", content: "Content6" }, { contentType: "description", content: "Content7" }],
//     4: [{ contentType: "description", content: "Content8" }, { contentType: "description", content: "Content9" }],
//     5: [{ contentType: "description", content: "Content10" }],
//     6: [{ contentType: "description", content: "Content11" }, { contentType: "description", content: "Content12" }],
//   };
  const ParagraphSuggestionsInitialState = []
  
  const [paragraphs, setParagraphs] = useState(paragraphsInitialState);
  const [paragraphSuggestions, setParagraphSuggestions] = useState(ParagraphSuggestionsInitialState);
  const [labels, setLabels] = useState([]);  //this is for the entire list of labels.
  const [documentLabels, setDocumentLabels] = useState([]);

  //stores the highlighted ranges.  This is used to store the highlighted ranges for the paragraphs.
  const [highlightedRanges, setHighlightedRanges] = useState([]);
  //const [highlightColor, setHighlightColor] = useState('#FFFF00');
  const [activeParagraphIndex, setActiveParagraphIndex] = useState(null);


    //COMPONENT DID MOUNT - RUN WHEN COMPONENT FIRST MOUNTS
  useEffect(() => {
    fetchData();

  }, []);

  const fetchData = async () => {
		//console.log("token is: ", props.token)  //Security

		await fetch(API, {
			method: 'POST',
			mode: 'cors',
      //IMPORTANT
      //The body should contain the paragraph_id being requested.  This should be passed via props

			body: JSON.stringify({
				//search_query: this.state.search_query
				//docid: props.docid,
				//matter_id: props.matter_id,
				project_id: props.project_id,
                //docid: 13
        docid: props.docid
			}),	
			headers: new Headers({
				//'Accept': 'application/json; charset=utf-8',
				'Authorization': 'Bearer ' + props.token,
				'Content-Type': 'application/json',
				//'Access-Control-Allow-Origin': '*',
			})
		})
		//.then(response => response.text())          // convert to plain text
		//.then(text => console.log("fetch data: ",text))  // then log it out
		.then(response=> {
			return response.json() //convert to json.  return passes it
		})  // Prod
		//.then(data => console.log("fetch data: ",data))  //For Debugging
		.then((ResponseData) => {
            console.log("Response:", ResponseData)
			
			//check to see if a new access_token
			//console.log("new token: ", data)
			if(ResponseData.access_token !== undefined){
				console.log("setting new token")
				props.setToken(ResponseData.access_token) //if refresh token present. Set it.
			}

			if(ResponseData.labels_list !== undefined){
        console.log("setting labels_list:", ResponseData.labels_list);  //for debugging
        setDocumentLabels(ResponseData.labels_list)  
      }

      if(ResponseData.all_labels_list !== undefined){
        console.log("setting all_labels_list:", ResponseData.all_labels_list);  //for debugging
        setLabels(ResponseData.all_labels_list)

      }

      if(ResponseData.paragraphs_list !== undefined){
          console.log("setting paragraphs:", ResponseData.paragraphs_list);
          setParagraphs(ResponseData.paragraphs_list)
          
      }

      if(ResponseData.suggestions_list !== undefined){
          console.log("suggestions_list:", ResponseData.suggestions_list);
          setParagraphSuggestions(ResponseData.suggestions_list)
  
          //const parsedLabelsList = JSON.parse(ResponseData.paragraphs_labels_list);
          //setTags(ResponseData.paragraphs_labels_list)
      }

      if(ResponseData.checks_list !== undefined){
        console.log("setting checks_list:", ResponseData.checks_list);
        setChecks(ResponseData.checks_list)  
      }

      if(ResponseData.check_response_list !== undefined){
        console.log("setting check_response_list:", ResponseData.check_response_list);
        setResponses(ResponseData.check_response_list)  
      }

		});  //end of handling response
	}

  const handleParagraphClick = (index, id) => {
    setSelectedParagraphIndex(index);
    setSelectedParagraphID(id);
  };

  const handleNextParagraph = () => {
    setSelectedParagraphIndex((prevIndex) => (prevIndex + 1) % paragraphs.length);
    setSelectedParagraphID(paragraphs[(selectedParagraphIndex + 1) % paragraphs.length].ID);
    if (selectedParagraphRef.current) {
        selectedParagraphRef.current.scrollIntoView({ behavior: 'smooth' });
      }
  };

  const handlePrevParagraph = () => {
    setSelectedParagraphIndex((prevIndex) => (prevIndex - 1 + paragraphs.length) % paragraphs.length);
    setSelectedParagraphID((prevID) => paragraphs[(selectedParagraphIndex - 1 + paragraphs.length) % paragraphs.length].ID);
  
    // Scroll the selected paragraph into view after updating the state
    setTimeout(() => {
      if (selectedParagraphRef.current) {
        selectedParagraphRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }, 0);
  };

  const handleToggleLabels = () => {
    setShowLabels((prevShowLabels) => !prevShowLabels);
  };

  const handleToggleRightSection = () => {
    setShowRightSection((prevShowRightSection) => !prevShowRightSection);
  };


  const updateLabelList = (newLabels, paragraph_id) => {
    // TBC
    // Need to fix so that labels are deleted and then added again.  This will get rid of the corrent error
    // where the labels are not being erased although they will be erased in the database.
    
    // Need to fix the issue where the labels are all showing up as black.  This is likely happening at the
    // tag level.  Need to check the tag level and see if the color is being passed correctly.

    // Need to fix the issue where labels are being replicated each time a new paragraph is clicked.
    // SEEMS TO BE FIXED, BUT UNSURE OF HOW.  NEED TO WATCH OUT FOR FUTURE ISSUES.

    setDocumentLabels(prevLabels => {
      // Filter out any existing labels for the given paragraph_id
      const labelsWithoutCurrentParagraph = prevLabels.filter(label => label.Paragraph_ID !== paragraph_id);
      console.log("labelsWithoutCurrentParagraph:", labelsWithoutCurrentParagraph)
      
      // Add the new labels for the paragraph_id
      const updatedLabels = [
        ...labelsWithoutCurrentParagraph,
        ...newLabels.map(newLabel => ({
          ...newLabel,
          Paragraph_ID: paragraph_id // Ensure this property is set correctly
        }))
      ];
      
      console.log("Updated labels list:", updatedLabels);
      return updatedLabels;
    });
  };
  

  return (
    <Container maxWidth="lg">
    {
        //TOOLBAR
    }
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Toolbar sx={{ position: 'sticky', top: 0, zIndex: 10, bgcolor: 'grey.800' }}>
          <IconButton color="primary" onClick={handlePrevParagraph}>
            <ArrowBackIosIcon />
          </IconButton>
          <IconButton color="primary" onClick={handleNextParagraph}>
            <ArrowForwardIosIcon />
          </IconButton>
          
          
          {/* For checklists */}
          <Box sx={{ flexGrow: 1 }} />
            <Highlighter_Sidebar 
              token = {props.token}
              setToken={props.setToken}
              document_id={props.docid}
              project_id={props.project_id}

              handleHighlightTrigger={handleHighlightTrigger} 
              labels={documentLabels} 
              selectedParagraphID={selectedParagraphID}
              contentRef={contentRef} 

              //pass checks
              checks = {checks}
              setChecks = {setChecks}

              //Pass states for comments.
              comments = {comments}
              setComments = {setComments}

              //Responses
              responses = {responses}  //responses
              setResponses = {setResponses}  //setResponses

              //linksModal
              linksModalIsOpen={linksModalIsOpen}  //linksModalIsOpen, setLinksModalIsOpen, selectedCheck, setSelectedCheck
              setLinksModalIsOpen={setLinksModalIsOpen}
              setSelectedCheck = {setSelectedCheck}
              selectedCheck={selectedCheck}

              paragraphs={paragraphs}

            />  {/* For Checklists */}

          <Box>
          
          {/* For adding a check */}
          <AddCheckButton 
            token = {props.token} 
            setToken={props.setToken}
            docid={props.docid}
            project_id={props.project_id}
            paragraph_id={selectedParagraphID}
            iconButtonStyle={iconButtonStyle}
            updateLabelList={updateLabelList} // Pass the function as a prop
            checks = {checks}
            setChecks = {setChecks}
            paragraphs={paragraphs}
            labels={labels} //pass labels for tag feature
          />

          {/* links model */}
          <LinksModal 
            showModal={linksModalIsOpen} 
            iconButtonStyle={iconButtonStyle}
            selectedCheck={selectedCheck} 
            setShowModal={setLinksModalIsOpen} 
          />

          <LabelButton 
            token = {props.token} 
            setToken={props.setToken}
            paragraph_id={selectedParagraphID}
            iconButtonStyle={iconButtonStyle}
            updateLabelList={updateLabelList} // Pass the function as a prop
          />

          {/* For annotations */}
          <IconButton color="primary">
            <AddCommentOutlinedIcon/>
          </IconButton>


          
          
            <FormControlLabel
                control={<Switch checked={showLabels} onChange={handleToggleLabels}/>}
                        label={
                                <span
                                    style={{
                                        fontSize: 10, // Adjust the font size as desired
                                        color: switchColor, // Use any color value you want
                                    }}
                                >
                              Show Labels
                            </span>
                          }
                          labelPlacement="top"
            />
            
          </Box>
          <Box>
            <FormControlLabel
                    control={<Switch checked={showRightSection} onChange={handleToggleRightSection} />}
                            label={
                                <span
                                    style={{
                                        fontSize: 10, // Adjust the font size as desired
                                        color: switchColor, // Use any color value you want
                                    }}
                                >
                                    Show Suggestions
                                </span>
                            }
                            labelPlacement="top"
            />
          </Box>
        </Toolbar>
        
        
        <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'auto', borderLeft: '4px solid grey.200' }}>
            {
                //LEFT SECTION
            }

          <ParagraphPanel
            paragraphs={paragraphs}
            selectedParagraphIndex={selectedParagraphIndex}
            handleParagraphClick={handleParagraphClick}
            selectedParagraphRef={selectedParagraphRef}
            showLabels={showLabels}
            labels={documentLabels}
            showRightSection={showRightSection}
            setHighlightState={setHighlightState}  //Setting highlight info for UseEffect to process.
            shouldHighlight={highlightState.shouldHighlight}
            // setShouldHighlight={setShouldHighlight}
            highlightState={highlightState}
            highlightColor={highlightState.highlightColor}
            highlightingObject={highlightState.highlightingObject}
            onHighlightTrigger={handleHighlightTrigger}
            activeParagraphIndex={activeParagraphIndex}
            setActiveParagraphIndex={setActiveParagraphIndex}
            highlightedRanges={highlightedRanges}
            setHighlightedRanges={setHighlightedRanges}
            contentRef={contentRef} 
          />

          {
            //HANDLE THE RIGHT SECTION
          }
          {showRightSection && (
           <Box sx={{ flexBasis: '40%', overflow: 'auto', p: 1, bgcolor: 'grey.200' }}>
           {paragraphSuggestions !== null && paragraphSuggestions !== undefined && paragraphSuggestions && paragraphSuggestions.map((contentItem, i) => {
             if (contentItem.Paragraph_ID === selectedParagraphID) {
               if (contentItem.Type === "description") {
                 return (
                   <Paper
                     key={i}
                     sx={{
                       mb: 1,
                       p: 1,
                       borderLeft: `4px solid ${contentItem.Label_Color}`,
                     }}
                   >
                     <Typography variant="body1" component="div" sx={{ whiteSpace: 'pre-wrap' }}>
                       {contentItem.Text}
                     </Typography>
                   </Paper>
                 );
               } else if (contentItem.Type === "highlight") {
                 return (
                   <Paper
                     key={i}
                     sx={{
                       mb: 1,
                       p: 1,
                       bgcolor: contentItem.Color,
                       borderLeft: `4px solid ${contentItem.Color}`,
                     }}
                   >
                     <Typography variant="body1" component="div" sx={{ whiteSpace: 'pre-wrap' }}>
                       {contentItem.Text}
                     </Typography>
                   </Paper>
                 );
               }
             }
             return null;
           })}
         </Box>
         
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default AnalyzerPage;

// SettingsMenu.js
import React, { useState, useMemo, useEffect} from 'react';
import { IconButton, Menu, MenuItem, Checkbox, ListItemText } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

const ChecklistMenu = ({ checks, checkedCheckListItems, setCheckedCheckListItems}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [uniqueChecks, setUniqueChecks] = useState([]);
    //const [checkedCheckListItems, setCheckedCheckListItems] = useState({});  //put in parent to allow for it effect parent checks

    useEffect(() => {
        // Creating a map to track unique Checklist_IDs
        const checksMap = new Map();

        // Filtering checks to keep only the first occurrence of each Checklist_ID
        checks.forEach(check => {
            if (!checksMap.has(check.Checklist_ID)) {
                checksMap.set(check.Checklist_ID, check);
            }
        });

        const uniqueChecksArray = Array.from(checksMap.values());

        setUniqueChecks(uniqueChecksArray);
        setCheckedCheckListItems(uniqueChecksArray.reduce((acc, item) => ({ ...acc, [item.Checklist_ID]: true }), {}));
    }, [checks]);

    const handleCheckMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCheckMenuClose = () => {
        setAnchorEl(null);
    };

    const handleCheckMenuItemToggle = (id, event) => {
        event.stopPropagation(); // Prevent the menu from closing when an item is clicked
        setCheckedCheckListItems(prev => ({ ...prev, [id]: !prev[id] }));
    };

    return (
        <>
            <IconButton
                color="inherit"
                aria-label="settings"
                onClick={handleCheckMenuClick}
            >
                <SettingsIcon />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCheckMenuClose}
            >
                {uniqueChecks.map((check) => (
                    <MenuItem key={check.Checklist_ID} onClick={(event) => handleCheckMenuItemToggle(check.Checklist_ID, event)}>
                        <Checkbox checked={checkedCheckListItems[check.Checklist_ID] || false} />
                        <ListItemText primary={check.Checklist_Title} />
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default ChecklistMenu;

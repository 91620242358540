import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Modal,
  TextField,
  Box,
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  IconButton, 
  CardMedia
} from '@mui/material';

import { Close } from '@mui/icons-material';
import DocumentLister from './03_DocumentLister';

//for Search
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

const API = "/getmatters.api"
const ProjectAPI = "/createproject.api"
const MatterAPI = "/creatematter.api"

// const API = "http://192.168.1.3:9999/getmatters.api"
// const ProjectAPI = "http://192.168.1.3:9999/createproject.api"
// const MatterAPI = "http://192.168.1.3:9999/creatematter.api"

/*
const data = [
  {
    name: 'Matter 1',
    projects: [
      {
        name: 'Project 1',
        status: 'In Progress',
      },
      {
        name: 'Project 2',
        status: 'Completed',
      },
    ],
  },
  {
    name: 'Matter 2',
    projects: [
      {
        name: 'Project 3',
        status: 'On Hold',
      },
      {
        name: 'Project 4',
        status: 'In Progress',
      },
    ],
  },
];
*/

const useStyles = makeStyles({
  root: {
    //display: 'flex',  //this causes the cards to fold together.
    flexDirection: 'column',
    backgroundColor: '#f5f5f5',
    padding: '1rem',
    height: '100vh',
  },
  matterCard: {
    marginBottom: '.5rem',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    borderRadius: '4px',
    marginTop: '.5rem', // Add 1rem of margin to the top of the card
  },
  matterCardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#03a9f4',
    color: 'white',
    padding: '1rem',
    borderRadius: '4px 4px 0 0',
  },
  projectCard: {
    marginTop: '0.5rem',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    borderRadius: '4px',
  },
  projectCardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addProjectButton: {
    backgroundColor: '#1976d2',
    color: 'white',
    '&:hover': {
      backgroundColor: '#0d47a1',
    },
  },
  addMatterButton: {
    backgroundColor: '#03a9f4',
    color: 'white',
    '&:hover': {
      backgroundColor: '#0277bd',
    },
    alignSelf: 'flex-end',
    marginBottom: '5rem',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#f5f5f5', // Set background color to match page
    padding: '1rem',
    borderRadius: '4px',
    outline: 'none',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  addButton: {
    backgroundColor: '#03a9f4', // Set Add Matter/Project button color
    color: 'white',
    '&:hover': {
      backgroundColor: '#0277bd',
    },
  },
  cancelButton: {
    backgroundColor: '#bdbdbd', // Set Cancel button color
    color: 'white',
    '&:hover': {
      backgroundColor: '#9e9e9e',
    },
  },
  modalTitle: {
    color: '#03a9f4',
    margin: 0,
  },
});

const Dashboard_Matters = (props) => {
  const classes = useStyles();

  //MODEL CODE..
  const [openAddProjectModal, setOpenAddProjectModal] = useState(false);
  const [openAddMatterModal, setOpenAddMatterModal] = useState(false);
  //MODEL CODE - For Project
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [projectMatterID, setProjectMatterID] = useState('');
  const [projectStatus, setProjectStatus] = useState(''); //not used but might use later.
  //MODEL CODE - For Matter
  const [matterName, setMatterName] = useState('');
  const [matterDescription, setMatterDescription] = useState('');

  const [data, setData] = useState('');

  const [searchTerm, setSearchTerm] = useState(''); //for search

  // search and filter matters and projects
  const filteredData = data && Array.isArray(data) && searchTerm
    ? data.reduce((acc, matter) => {
        if (matter.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          // If the matter name includes the search term, include the entire matter
          acc.push(matter);
        } else {
          // If the matter name does not include the search term, filter the projects
          const matchingProjects = matter.projects.filter(project =>
            project.name.toLowerCase().includes(searchTerm.toLowerCase())
          );

          if (matchingProjects.length > 0) {
            // If there are matching projects, include the matter with only the matching projects
            acc.push({ ...matter, projects: matchingProjects });
          }
        }

        return acc;
      }, [])
    : data || [];


  //add API call here to create Project
  const handleSubmitAddProject = () => {
    console.log('Submitting new project:', { projectName });
    addProject();
    handleCloseAddProjectModal();
    setProjectName('');
    setProjectDescription('');
    setProjectMatterID('');
  };

  //add API call here to create Matter
  const handleSubmitAddMatter = () => {
    console.log('Submitting new matter:', { matterName });
    addMatter(); //variables for description are pulled from globals.
    handleCloseAddMatterModal();
    setMatterName('');
    setMatterDescription('');
  };

  const handleCloseAddMatterModal = () => {
    setOpenAddMatterModal(false);
    setMatterName('');
  };

  const handleOpenAddProjectModal = (matterId) => {
    setOpenAddProjectModal(true);
    setProjectMatterID(matterId);
  };

  const handleCloseAddProjectModal = () => {
    setOpenAddProjectModal(false);
  };

  const handleOpenAddMatterModal = () => {
    setOpenAddMatterModal(true);
  };

  const [DocViewOpen, setDocViewOpen] = useState(false);
  const [DocViewProject, setDocViewProject] = useState(false);
  const [DocViewMatter, setDocViewMatter] = useState(false);

  const handleDocViewOpen = (matter_id, project_id) => {
    setDocViewMatter(matter_id);
    setDocViewProject(project_id);
    setDocViewOpen(true);
  };

  const handleDocViewClose = () => {
    setDocViewOpen(false);
  };
  

  //COMPONENT DID MOUNT - RUN WHEN COMPONENT FIRST MOUNTS
  useEffect(() => {

    fetchData();
    props.getNav();
  }, []);

  const fetchData = async () => {
		console.log("token is: ", props.token)

		await fetch(API, {
			method: 'POST',
			mode: 'cors',
			/*body: JSON.stringify({
				//search_query: this.state.search_query
				//docid: props.docid,
				//matter_id: props.matter_id,
				//project_id: props.project_id
			}),*/	
			headers: new Headers({
				//'Accept': 'application/json; charset=utf-8',
				'Authorization': 'Bearer ' + props.token,
				'Content-Type': 'application/json',
				//'Access-Control-Allow-Origin': '*',
			})
		})
		//.then(response => response.text())          // convert to plain text
		//.then(text => console.log("fetch data: ",text))  // then log it out
		.then(response=> {
			return response.json() //convert to json.  return passes it
		})  // Prod
		//.then(data => console.log("fetch data: ",data))  //For Debugging
		.then((ResponseData) => {
      console.log("Response:", ResponseData)
			

			//check to see if a new access_token
			//console.log("new token: ", data)
			if(ResponseData.access_token !== undefined){
				console.log("setting new token")
				props.setToken(ResponseData.access_token) //if refresh token present. Set it.
			}

			if(ResponseData.matters_list !== undefined){
				console.log("setting matters_list:", ResponseData.matters_list)
        setData(ResponseData.matters_list)
        //set isLoading to false.
			  }
			}
		);  //set data to results
	}
  
  //send data to server to add Matter.
  const addMatter = async () => {
    try {
      const response = await fetch(MatterAPI, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          name: matterName,
          description: matterDescription
        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${props.token}`
        }
      });
      
      if (response.ok) {
        setMatterName('');
        setMatterDescription('');
        await fetchData(); // force fetchData function to rerun
        await props.getNav();
      } else {
        console.log('Error adding matter');
      }
      
    } catch (error) {
      console.log(error);
    }
  };


  //send data to server to add Project to Matter.
  const addProject = async () => {
    try {
      const response = await fetch(ProjectAPI, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          name: projectName,
          description: projectDescription,
          matterid: projectMatterID
        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${props.token}`
        }
      });
      
      if (response.ok) {
        setProjectName('');
        setProjectDescription('');
        setProjectMatterID('');
        await fetchData(); // force fetchData function to rerun
        await props.getNav();
      } else {
        console.log('Error adding project');
      }
      
    } catch (error) {
      console.log(error);
    }
  };


  const getStatusColor = (status) => {
    switch (status) {
      case 'In Progress':
        return '#2196f3';
      case 'Completed':
        return '#4caf50';
      case 'On Hold':
        return '#ff9800';
      default:
        return '#bdbdbd';
    }
  };

  if (data) {
      return (
        <div className={classes.root}>
          {/* <Typography variant="h5">Matter_ID:{props.matter_id}</Typography> <Typography variant="h5">Project_ID:{props.project_id}</Typography> */}

          {/* Search bar */}
          <TextField
              variant="outlined"
              placeholder="Search..."
              value={searchTerm}
              type="search"
              onChange={e => setSearchTerm(e.target.value)}
              sx={{ marginBottom: 2 }}  // Add some margin at the bottom
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
              ),
            }}
          />

          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.addMatterButton}
              onClick={handleOpenAddMatterModal}
            >
              Add Matter
            </Button>
          </div>

          {filteredData.map((matter, matterIndex) => (
          // {data.map((matter, matterIndex) => (
            <Card key={matterIndex} className={classes.matterCard} sx={{display: 'flex', flexDirection:'column'}}>
              <div className={classes.matterCardHeader}>
                <Typography variant="h5">{matter.name}</Typography>
                <Button 
                  variant="contained" 
                  className={classes.addProjectButton}
                  onClick={() => handleOpenAddProjectModal(matter.id)}
                >
                  Add Project
                </Button>
              </div>
              
              <CardContent sx={{ overflow: 'auto', '&::-webkit-scrollbar': { width: '8px' }, '&::-webkit-scrollbar-track': { backgroundColor: 'lightblue' }, '&::-webkit-scrollbar-thumb': { backgroundColor: 'grey' } }}>
              {matter.projects.map((project, projectIndex) => (
                <>
                  {project.id !== undefined && project.id !== 'None' &&
                    <Card
                      key={projectIndex}
                      className={classes.projectCard}
                      style={{ borderLeft: `5px solid ${getStatusColor(project.status)}` }}
                    >
                      <CardContent className={classes.projectCardContent}>
                        <Typography variant="h6">{project.name}</Typography>
                        <Typography variant="h6">{project.status}</Typography>
                      </CardContent>
                      <CardActions>
                        <Button 
                          size="small" color="primary"
                          onClick={() => {props.setMatterNProject(matter.id, project.id);props.handleSetOptionClick(matter.id, project.id)}}
                          >
                          Select
                        </Button>
                        <Button size="small" color="primary"
                          onClick={() => {handleDocViewOpen(matter.id, project.id)}}
                        >
                          View Documents
                        </Button>
                        {/* <Button size="small" color="secondary">
                          Edit
                        </Button> */}
                      </CardActions>
                    </Card>
                  }
                </>
              ))}
              </CardContent>
              
            </Card>
          ))}

          {/* Add Project Modal */}
          <Modal
            open={openAddProjectModal}
            onClose={handleCloseAddProjectModal}
            className={classes.modal}
          >
            <div className={classes.paper}>
              <Typography variant="h5" className={classes.modalTitle}>Add Project</Typography>
              <form className={classes.form}>
                <TextField
                  required
                  label="Project Name"
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                />
                <TextField
                  label="Description"
                  value={projectDescription}
                  onChange={(e) => setProjectDescription(e.target.value)}
                />
                <div>
                  <Button className={classes.addButton} onClick={handleSubmitAddProject}>Submit</Button>
                  <Button className={classes.cancelButton} onClick={handleCloseAddProjectModal}>Cancel</Button>
                </div>
              </form>
            </div>
          </Modal>        

          {/* Add Matter Modal */}
          <Modal
            open={openAddMatterModal}
            onClose={handleCloseAddMatterModal}
            className={classes.modal}
          >
            <div className={classes.paper}>
              <Typography variant="h5" className={classes.modalTitle}>Add Matter</Typography>
              <form className={classes.form}>
                <TextField
                  required
                  label="Matter Name"
                  value={matterName}
                  onChange={(e) => setMatterName(e.target.value)}
                />
                <TextField
                  label="Description"
                  value={matterDescription}
                  onChange={(e) => setMatterDescription(e.target.value)}
                />
                <div>
                  <Button onClick={handleSubmitAddMatter}>Submit</Button>
                  <Button onClick={handleCloseAddMatterModal}>Cancel</Button>
                </div>
              </form>
            </div>
          </Modal>

          {/* Add Document View Modal */}
          <div>
            <Dialog open={DocViewOpen} onClose={handleDocViewClose} fullWidth maxWidth="md">
              <DialogTitle>
              <Typography variant="h5" sx={{ color: '#2196f3', mb: 2 }}>Project Documents</Typography>
                <IconButton aria-label="close" onClick={handleDocViewClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                <DocumentLister 
                  token={props.token}
                  setToken={props.setToken}
                  matter_id={DocViewMatter}
                  project_id={DocViewProject}
                  DocID={props.DocID}
                  setDocID={props.setDocID} 
                  setMatterNProject={props.setMatterNProject}
                  handleSetOptionClick={props.handleSetOptionClick}
                />
              </DialogContent>
              <DialogActions>
                <Button variant="contained" onClick={handleDocViewClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>

      );
  } else {return(
    <div><p>No Matters Found.</p></div>
  );}
};

export default Dashboard_Matters;
import { SnoozeOutlined } from '@mui/icons-material';
import React, { useState } from 'react';
import { redirect } from 'react-router-dom';

const API = '/register';
//const API = 'https://www.ulomate.com/register';  //allow registration when not on the site.

function Register(props) {

    const [loginForm, setloginForm] = useState({
      email: "",
      password: ""
    })

    const [msgHandler, setmsgHandler] = useState({msg:""})

    function logMeIn(event) {
      //put fetch call here

/*
      fetch(API, {
        method: 'POST', 
        body: JSON.stringify({
          email: loginForm.email,
          password: loginForm.password
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
        })
      })
      .then((response) => response.json())
      .then((data) => {
        console.log("success: ", data.access_token)
        props.setToken(data.access_token)
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response)
          console.log(error.response.status)
          console.log(error.response.headers)
        }
      })
*/
      fetch(API, {
        method: 'POST', 
        body: JSON.stringify({
          email: loginForm.email,
          password: loginForm.password
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
        })
      })
      .then((response) => {
        if(!response.ok){

          response.text().then(function (text) {
            var error_msg = JSON.parse(text)
            setmsgHandler({msg:error_msg.msg})
            console.log(error_msg.msg)
          });
          //console.log(response)
          //console.log(msgHandler.msg)
          throw new Error(response.status);

        } else {
          console.log("Got to json")
          return response.json()
        }
      })
      .then((data) => {
        console.log("success: Account Registered!")
        //redirect!!!!!
        return redirect("/login")
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response)
          console.log(error.response.status)
          console.log(error.response.headers)
        }
      })


      setloginForm(({
        email: "",
        password: ""}))

      event.preventDefault()
    }

    function handleChange(event) { 
      const {value, name} = event.target
      setloginForm(prevNote => ({
          ...prevNote, [name]: value})
      )}

    return (
      <div>
        <h1>Registration</h1>
        <p> Pleas input your requested username and password:</p>
          <form className="login">
            <input onChange={handleChange} 
                  type="email"
                  text={loginForm.email} 
                  name="email" 
                  placeholder="Email" 
                  value={loginForm.email} />
            <input onChange={handleChange} 
                  type="password"
                  text={loginForm.password} 
                  name="password" 
                  placeholder="Password" 
                  value={loginForm.password} />

          <button onClick={logMeIn}>Submit</button>
        </form>
        {!msgHandler.msg && msgHandler.msg !== "" ? "": <p>{msgHandler.msg}</p>}
      </div>
    );
}

export default Register;
import React from 'react'

// import CardMedia from '@mui/material/CardMedia';
// import CardContent from '@mui/material/CardContent';
// import { Container, Link, Typography } from '@mui/material';
import { Card, Box, useMediaQuery, useTheme } from '@mui/material';
//import { Grid } from '@mui/material';  //moved to Grid2 for finer grouping
import Grid from '@mui/material/Unstable_Grid2';

import DocumentImage from './DocumentImage';
import DocumentInfo from './DocumentInfo';

import ActionButtonGroup from '../Buttons/ActionButtonGroup';

import HighlightText from './HighlightText';
import TypeButtonGroup from '../Buttons/TypeButtonGroup';

export default function SRNoteCard({ hit, searchQuery, ...props }){
    // Define the array of colors  Note this should repeat after it is done.
    const colors = ['lightpink', 'lightgreen', 'lightblue', 'lightskyblue', 'lavender', 'mint', 'peach', 'lilac', 'canary', 'babyblue', 'coral', 'skyblue', 'buttercream'];

    // Break the searchQuery into words, ignoring 'AND' and 'OR'
    // const searchWords = searchQuery.toLowerCase().split(' ').filter(word => word !== 'and' && word !== 'or');  OLD

    // const searchWords = typeof searchQuery === 'string'
    // ? searchQuery.toLowerCase().split(' ').filter(word => word !== 'and' && word !== 'or')
    // : [];

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // Function to highlight words in the text  OLD
    // function highlightText(text, searchWords) {
    //     const words = text.split(' ');
    //     return words.map((word, index) => {
    //         const lowerCaseWord = word.toLowerCase();
    //         const wordIndex = searchWords.indexOf(lowerCaseWord);
    //         if (wordIndex !== -1) {
    //             const color = colors[wordIndex % colors.length];
    //             return <mark style={{backgroundColor: color}} key={`${word}-${index}`}>{word}</mark>;
    //         } else {
    //             return <span key={`${word}-${index}`}>{word} </span>;
    //         }
    //     });
    // }

    // Highlight words in hit["_source"]["Text"]
    // const highlightedText = highlightText(hit["_source"]["Text"], searchWords);
    
    return(
        <div>
            {/* <Card sx={{ width: 1, m: 1, position: 'relative'}} elevation={2}> */}
            {/* <Card sx={{ position: 'relative', maxWidth: '100%', margin: "auto", mt: 2, mb: 2 }} elevation={2}> */}

            {/* <Card sx={{ position: 'relative', maxWidth: '100%', margin: "auto", mt: 2, mb: 4}} elevation={2}> */}
            <Card sx={{ position: 'relative', maxWidth: '100%', margin: "auto", mt: 1, mb: 1}} elevation={2}>
                {isMobile ? (
                   // Mobile layout
                    <Box>
                         {/* Button groups */}
                         <TypeButtonGroup/>
                         <ActionButtonGroup 
                            hit={hit}
                            searchQuery={searchQuery}
                            {...props} //pass down remaining props from parent
                        />

                        {/* Display DocumentImage and DocumentInfo side by side */}
                        <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                            {/* Box for DocumentImage */}
                            <Box sx={{ 
                                flexShrink: 0, 
                                mt: 5, // add space on top, use a value from the theme spacing or specific units like '8px'
                            }}>
                                <DocumentImage 
                                    hit={hit} 
                                    {...props} //pass down remaining props from parent
                                />
                            </Box>

                            {/* Box for DocumentInfo */}
                            <Box sx={{ 
                                flexGrow: 1, 
                                ml: 0,
                                mt: 4, // add space on top, use a value from the theme spacing or specific units like '8px'
                            }}>
                                <DocumentInfo 
                                    hit={hit} 
                                    {...props} //pass down remaining props from parent
                                />
                            </Box>
                        </Box>

                        {/* Scrollable Text Display Box */}
                        <Box sx={{ bgcolor: '#F6F6F6', p: 2 }}>
                            <HighlightText text={hit["_source"]["Text"]} searchWords={searchQuery} colors={colors} />
                        </Box>
                    </Box>
                    ) : (
                    // Desktop layout
                    <Grid container spacing={0}>
                         {/* Button groups */}
                         <TypeButtonGroup/>
                         <ActionButtonGroup 
                            hit={hit}
                            searchQuery={searchQuery}
                            {...props} //pass down remaining props from parent
                        />
    
                        <Grid item xs={12} md={2}>
                            {/* Button Container */}
                            {/* <Box sx={{ display: 'flex', flexDirection: 'column', height: '200', maxHeight: '200'}}> */}
                            <Box sx={{ 
                                display: 'flex',
                                alignItems: 'center',     // Center vertically in the container
                                flexDirection: 'column', 
                                height: '100%',
                                p:2,
                                mt: 3, // add space on top, use a value from the theme spacing or specific units like '8px'
                            }}>
                                {/* Ensure DocumentImage doesn't stretch and is limited to a height of 200px */}
                                <DocumentImage 
                                    hit={hit} 
                                    {...props} //pass down remaining props from parent
                                />
                            </Box>
                        </Grid>
                        
                        <Grid item xs={12} md={10}>
                            {/* For Header and Text Display */}
                            {/* This works better with 80%, but the text should not bleed into the Image. */}
                            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', p:2}}>
                                <DocumentInfo hit={hit}/>
                                {/* For Text Display */}
                                {/* <Box sx={{ maxHeight: 'calc(100vh - 250px)', bgcolor: '#F6F6F6', p: 2, margin:1, }}> */}
                                <Box sx={{ bgcolor: '#F6F6F6', p: 2, margin: 1, }}>
                                {/* <Box sx={{ bgcolor: '#F6F6F6', p: 2 }}> */}
                                    <HighlightText text={hit["_source"]["Text"]} searchWords={searchQuery} colors={colors} />
                                </Box>
                            </Box>
                            {/* <CardContent>
                            </CardContent> */}
                        </Grid>
                    </Grid>
                    )}
            </Card>
        </div>
    );
}
import React, { useState } from 'react'; // Import useState
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { Link, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { Button } from '@mui/material';

import QueueIcon from '@mui/icons-material/Queue';
import FindInPageIcon from '@mui/icons-material/FindInPage';

import TargetPicker from './Target_Picker/TargetPicker';

export default function NoteCard({ hit, ...props }) {
    // Define state for the toggle
    const [isToggled, setIsToggled] = useState(false);

    return (
        <div>
            <Card sx={{ width: 1, m: 1 }} elevation={2}>
                <CardHeader nogutter
                    title={
                        <div>
                            {/* {console.log("Testing: ", props.DocID, " ", hit["ID"])} */}
                            <Typography> Document Name: {hit["Document_Name"]} </Typography>

                            <Button
                                variant="contained"
                                color={hit["ID"] == props.DocID ? "secondary":"info"} // Change color when toggled
                                disabled={hit["OCR"] !== 1}
                                onClick={() => {props.setDocID(hit["ID"]);props.setMatterNProject(props.matter_id, props.project_id);props.handleSetOptionClick(props.matter_id, props.project_id)}} //sets document for analysis
                                startIcon={<FindInPageIcon />}
                            >
                            </Button>

                            <Button
                                variant="contained"
                                color={isToggled ? "secondary" : "primary"} // Change color when toggled
                                disabled={hit["OCR"] !== 1}
                                onClick={() => setIsToggled(!isToggled)} // Toggle state on click
                                startIcon={<QueueIcon />}
                            >
                            </Button>

                        </div>
                    }
                    subheader={`Document ID: ${hit["ID"]}`}
                />

                <table>
                    {/* Conditional rendering */}
                        <tr>
                            <th>
                                <Link target="_blank" href={`/getfile.py?docid=${hit["ID"]}`}>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={`/geticon?docid=${hit["ID"]}`}
                                        alt=""
                                        align="left"
                                        sx={{ border: 1 }}
                                    />
                                </Link>
                            </th>
                        {!isToggled && (
                            <th align="left"><CardContent>
                                <Typography pargraph>
                                    <p>Document MD5: {hit['Document_MD5']}</p>
                                    <p>OCR: {hit['OCR']}</p>
                                    <p>Advanced OCR: {hit['Advanced_OCR']}</p>
                                    <p>Document Catagory: Not Functional</p>
                                </Typography>

                            </CardContent></th>
                        )} 
                        {isToggled && (
                        <th>
                            <Typography pargraph>
                                <p>Please choose your processing:</p>
                            </Typography>
                            <TargetPicker
                                token={props.token} setToken={props.setToken} docid={hit['ID']}
                            />
                        </th>
                        )}
                        </tr>
                </table>
            </Card>
        </div>
    )
}

import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const SeparatorBox = (props) => {
  const [inputValue, setInputValue] = React.useState('');
  const [searchValue, setSearchValue] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedItem, setSelectedItem] = React.useState(null);


  const [caseSensitive, setCaseSensitive] = React.useState(props.selectedCaseSensitivity || false);
  const [searchType, setSearchType] = React.useState(props.selectedType || 'any');

  const handleClick = () => {
    const newItems = inputValue.split(/,|\n/)
                      .map(item => item.trim())
                      .filter(item => item && !props.items.includes(item));
    if (newItems.length > 0) {
      const newItemsArray = [...new Set([...props.items, ...newItems])].sort((a, b) => caseSensitive ? a.localeCompare(b) : a.toLowerCase().localeCompare(b.toLowerCase()))
      props.setItems(newItemsArray);
      setInputValue('');
    }
  };

  const handleDelete = (item) => {
    props.setItems(props.items.filter(i => i !== item));
  };

  const handleItemClick = (event, item) => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  }

  const handleSearchTypeChange = (event) => {
    props.setSelectedType(event.target.value);
  }

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="search-type-label">Search Type</InputLabel>
        <Select
          labelId="search-type-label"
          value={props.selectedType}
          onChange={handleSearchTypeChange}
        >
          <MenuItem value={'any'}>Search for Any Words</MenuItem>
          <MenuItem value={'anyInSentence'}>Search for Any Words in Sentence</MenuItem>
          <MenuItem value={'all'}>Search for All Words</MenuItem>
          <MenuItem value={'allInSentence'}>Search for All Words in Sentence</MenuItem>
        </Select>
      </FormControl>
      <br />
      <br />
      <Button onClick={handleClick}>Add Words</Button>

      <FormControlLabel
        control={
          <Switch checked={props.selectedCaseSensitivity} onChange={() => props.setSelectedCaseSensitivity(!props.selectedCaseSensitivity)} />
        }
        label="Case-Sensitive"
        labelPlacement="start"
      />
      
      <br />
       <TextField
        value={inputValue}
        onChange={event => setInputValue(event.target.value)}
        placeholder="Enter words or phrases..."
        multiline
        fullWidth
      />
      <br />
      
      <TextField
        value={searchValue}
        onChange={handleSearchChange}
        placeholder="Words Search..."
        autoComplete="off"
      />
      <List>
        {/* {console.log("words items:", items)} */}
        {props.items
            .filter(item => item.toLowerCase().includes(searchValue.toLowerCase()))
            .map((item, index) => (
            <Paper elevation={2} key={index}>
            <ListItem onClick={(event) => handleItemClick(event, item)}>
                <ListItemText primary={item} />
                <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => handleDelete(item)}>
                    <DeleteIcon />
                </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            </Paper>
        ))}
        </List>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Option 1 for {selectedItem}</MenuItem>
        <MenuItem onClick={handleClose}>Option 2 for {selectedItem}</MenuItem>
      </Menu>
    </Box>
  );
};

export default SeparatorBox;

import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledBadge = styled(Box)(({ theme }) => ({
  color: 'gray',
  padding: '0 2px',
  display: 'inline-block',
  fontSize: '0.75rem',
  height: '24px',
  lineHeight: '24px', // Aligns text vertically center

}));

const Badge = ({ content }) => {
  return <StyledBadge>{content}</StyledBadge>;
};

export default Badge;
import React, { Component } from 'react'
import { groupBy, map } from 'underscore'
//import groupBy from '_'
import './09_topics_page.css'

const API = '/gettopics.py';  //Prod  Check

class Topics_List extends Component {

	constructor(props) {
		super(props)
	
		this.state = {
			//Dev Note:  These will need to be linked so when other component updates.  They update and force render.
			 results: [],
            isLoading: true
		}
	}
	
	componentDidMount(){

		fetch(API, {
			method: 'POST', 
			body: JSON.stringify({
				//search_query: this.state.search_query
				docid: this.props.docid
			}),
			headers: new Headers({
				'Accept': 'application/json; charset=utf-8',
				'Content-Type': 'application/json',
				//'Access-Control-Allow-Origin': '*',
			})
		})
		//.then(response => response.text())          // convert to plain text
		//.then(text => console.log("fetch data: ",text))  // then log it out
		.then(response=> response.json())  // Prod
		//.then(data => console.log("fetch data: ",data))  //For Debugging
		.then((data) => this.setState({results: data, isLoading:false}));  //set data to results
	}	

	//may need to pull other info.
	handleClick = (event, a_value) => {
        //alert(a_value);
        console.log("Setting Value: ", a_value)
		this.props.set_Topic_to_Display({topic_ID:a_value});
    }

	//Dev Note: this will need to be changed to refresh when hits value is received.

	render() {
		
		const {results} = this.state;  //Prod.  Not sure why this is required, but it is.  Otherwise it doesn't receive results.
		//alert(this.state.isLoading)  //for Debugging
		if (this.state.isLoading == false) {
			//var arr = [];
			console.log("Topics - JSON Object breakdown: ", results)  // for debugging

			/*  Notes Page
				https://www.w3schools.com/cssref/css3_pr_flex-wrap.asp
				https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mastering_Wrapping_of_Flex_Items
				
				https://stackoverflow.com/questions/34923468/reactjs-appending-data-to-a-z-alphabetic-list
			
				Topics are broken into columns that extend the entire page.  Its kind of nice but not organized.
				Will need to redefine.
            */
            
           var grouped_react_components = '';

           var results_list = this.state.results,
                returned_list = '',
                index = '';

            console.log(results_list)

            if(results_list !== undefined){ 
                
                /* //For Testing
                var results_list_for_testing = [];  //for testing

                //remove element for testing
                for (const each_item of results_list){
                    results_list_for_testing.push(each_item['Document_MD5'])
                }
    
                results_list = results_list_for_testing;  //for testing

                */
                
                //Alphabatize the topics list
                results.sort(function(a, b){
                    if(a.Topic_Name < b.Topic_Name) { return -1; }
                    if(a.Topic_Name > b.Topic_Name) { return 1; }
                    return 0;
                })
    
                console.log("Topics - Object Breakdown: ", results_list)  // for debugging
    
                
                var grouped_results = groupBy(results_list, function(result_item) {
                    console.log("result item: ", result_item ["Topic_Name"])
                    return result_item["Topic_Name"].charAt(0);
                });
                
                console.log(grouped_results)
                //return(JSON.stringify(grouped_results))  // for debugging
    
                var grouped_react_components = map(grouped_results, 
                    (grouped_item_list, key) => { 
                        return(<ul>{key}{
                        grouped_item_list.map(each_item => {
                            return(<li onClick={(e) => this.handleClick(e,each_item["ID"])}>{each_item["Topic_Name"]}</li>);
                        })
                    } </ul>);
                })
            } //  End If
			

			return(
				<div>
					<h2>Topics List:</h2>
					<div className="topics_div">
						{grouped_react_components}
					</div>
				</div>
				);

		} else {
			return(
				<div>
					<p>Loading...</p>
				</div>
			);
		}
	}
}

export default Topics_List
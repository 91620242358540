import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';

export const ItemCheckBox = ({title, checked: initialChecked, onCheckChange}) => {
  const [checked, setChecked] = useState(initialChecked);
  
  useEffect(() => {
    setChecked(initialChecked);
  }, [initialChecked]);
  
  const handleCheckChange = (e) => {
    e.stopPropagation();
    let nextChecked;
    if (checked === 'Empty') nextChecked = 'Processed';
    else nextChecked = 'Empty';

    setChecked(nextChecked);
    onCheckChange(title, 'item', nextChecked);
  }
  const checkboxColor = 
    checked === 'Empty' ? 'default' 
  : checked === 'Processed' ? '#00FF00' 
  : checked === 'DisabledProcessing' ? '#80FF80' 
  : '#808080';

  return (
    <Checkbox 
      disabled={checked === 'Disabled' || checked === "DisabledProcessing"}
      checked={checked === 'Processed' || checked === 'Disabled' || checked === "DisabledProcessing"} 
      onChange={handleCheckChange} 
      onClick={(e) => e.stopPropagation()}
      sx={{ 
        ml: 2, 
        color: checkboxColor, 
        '&.Mui-checked': { color: checkboxColor }
      }}
    />
  );
}

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Stage, Layer, Rect, Transformer } from "react-konva";
import styled from "styled-components";
import Rectangle from "./Rectangle";
import _ from "lodash";
import uniqid from "uniqid";
import Konva from "konva";
import RModal from "react-modal";
import ReactJson from "react-json-view";
import { ThirtyFpsRounded } from "@mui/icons-material";
import { select } from "underscore";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 500px;
`;

const Button = styled.button``;

const CanvasWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  background: #cdc5c53b;
`;

const ReactCanvas = ({div_id, div_target, annotator_state, setAnnotatorState, highlightColor, initialRectangles, page_number, rectanglesNestList, passPageAnnotations}) => {

  const [rectangles, setRectangles] = useState(initialRectangles); //where we place the sublist from.

  //const [height, setHeight] = useState(500);
  //const [width, setWidth] = useState(500);
  
  const [selectedId, selectShape] = useState(null);

  const [isDisabled, setIsDisabled] = useState(false);
  const [isOpenImportModal, setIsOpenImportModal] = useState(false);
  const [importValue, setImportvalue] = useState("");
  const [isOpenExportModal, setIsOpenExportModal] = useState(false);
  const [containerWidthHeight, setContainerWidthHeight] = useState({
    width: 200,
    height: 200,
  });
  const containerRef = useRef();

  const [entered, setIntered] = React.useState(false); //used to detect if in stage.

  /*
  const trRef = React.useRef();
  const layerRef = React.useRef();
  const Konva = window.Konva;
  */

  const [nodesArray, setNodes] = useState([]); //for multiselection  //experimental

  //IN PROGRESS

  //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
  //SELECTION BOX CODE
  //ADDING SELECTION BOX OPTION.
  //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX

  const trRef = React.useRef();
  const layerRef = React.useRef();

  //Used for drawing the selection box, which we will use to determine the coordinates of the highlight
  const selectionRectRef = React.useRef();
  const selection = React.useRef({
    visible: false,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0
  });

  const updateSelectionRect = () => {
    //draws selection rectangle
    const node = selectionRectRef.current;
    node.setAttrs({
      visible: selection.current.visible,
      x: Math.min(selection.current.x1, selection.current.x2),
      y: Math.min(selection.current.y1, selection.current.y2),
      width: Math.abs(selection.current.x1 - selection.current.x2),
      height: Math.abs(selection.current.y1 - selection.current.y2),
      fill: highlightColor
    });
    node.getLayer().batchDraw();
  };


  const oldPos = React.useRef(null);
  const onMouseDown = (e) => {
    const isElement = e.target.findAncestor(".elements-container");
    const isTransformer = e.target.findAncestor("Transformer");
    if (isElement || isTransformer) {
      return;
    }

    console.log("annotator_state: ", annotator_state)
    if (!annotator_state) {
      return;
    }

    //This appears to be updating the rect deminsions.  Gets a reference to the object, and then alters the aspects of that rect.
    //This might be selecting and moving a rectangle.
    const pos = e.target.getStage().getPointerPosition();
    selection.current.visible = true;
    selection.current.x1 = pos.x;
    selection.current.y1 = pos.y;
    selection.current.x2 = pos.x;
    selection.current.y2 = pos.y;
    updateSelectionRect();
  };

  const onMouseMove = (e) => {
    //SOMETHING WEIRD HAPPENS WHEN CLICKING OUTSIDE THE CANVAS AND THEN DRAGGING IN.  MAY NEED TO LIMIT TO ONLY WHEN INITIAL CLICK WAS INSIDE CANVAS.
    if (!selection.current.visible) {
      return;
    }
    const pos = e.target.getStage().getPointerPosition();
    selection.current.x2 = pos.x;
    selection.current.y2 = pos.y;
    updateSelectionRect();
  };

  const onMouseUp = () => {
    oldPos.current = null;

    if (!selection.current.visible) {
      return;
    }
    const selBox = selectionRectRef.current.getClientRect();

    const elements = [];
    layerRef.current.find(".rectangle").forEach((elementNode) => {
      const elBox = elementNode.getClientRect();
      if (Konva.Util.haveIntersection(selBox, elBox)) {
        elements.push(elementNode);
      }
    });
    trRef.current.nodes(elements);

    //console.log("Selection Rectangle Info: ", selection.current)  //for debugging
    //newRectCrt = selection.current //this doesn't work.  it wipes on reset.  use selection rectangle.
    //console.log("newRectCrt Info: ", newRectCrt)  //for debugging

    //make the new rectangle using selection.current
    createRect() 

    //disable selection rectangle
    selection.current.visible = false;
    // disable click event
    Konva.listenClickTap = false;
    updateSelectionRect();
  };

  
  //This appears to relate to multiple selections.
  const onClickTap = (e) => {
    // if we are selecting with rect, do nothing
    if (selectionRectRef.visible) {
      return;
    }
    let stage = e.target.getStage();
    let layer = layerRef.current;
    let tr = trRef.current;

    // if click on empty area - remove all selections
    if (e.target === stage) {
      selectShape(null);
      setNodes([]);
      tr.nodes([]);
      layer.draw();
      return;
    }

    // do nothing if clicked NOT on our rectangles
    if (!e.target.hasName(".rect")) {
      return;
    }

    // do we pressed shift or ctrl?
    const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
    const isSelected = tr.nodes().indexOf(e.target) >= 0;

    if (!metaPressed && !isSelected) {
      // if no key pressed and the node is not selected
      // select just one
      tr.nodes([e.target]);
    } else if (metaPressed && isSelected) {
      // if we pressed keys and node was selected
      // we need to remove it from selection:
      const nodes = tr.nodes().slice(); // use slice to have new copy of array
      // remove node from array
      nodes.splice(nodes.indexOf(e.target), 1);
      tr.nodes(nodes);
    } else if (metaPressed && !isSelected) {
      // add the node into selection
      const nodes = tr.nodes().concat([e.target]);
      tr.nodes(nodes);
    }
    layer.draw();
  };

  //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
  //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX  END OF SELECTION BOX CODE  XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
  //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX

  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
      trRef.current.nodes([]);
      setNodes([]);
      // layerRef.current.remove(selectionRectangle);
    }
  };

  /*
  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };
  */

  //NEED TO SWITCH SO THIS APPEARS ON MOUSE CURSOR.
  //https://stackoverflow.com/questions/68920457/display-shapes-where-user-clicks-in-react-konva
  const createRect = _.throttle((e) => {
    var tmp_id = uniqid()
    console.log("New Rect Properties: ", selection.current)

    //Take the lesser of x1,x2 and y1,y2 to determine top left, and then switch the values if necessry to get the bottom right.
    var x1, y1, x2, y2
    /*console.log(
      "x1: ", selection.current.x1, " ",
      "y1: ", selection.current.y1, " ",
      "x2: ", selection.current.x2, " ",
      "y2: ", selection.current.y2,
    ) */

    if (selection.current.y2 < selection.current.y1){
      y1 = selection.current.y2
      y2 = selection.current.y1
    } else {
      y1 = selection.current.y1
      y2 = selection.current.y2
    }

    if (selection.current.x2 < selection.current.x1){
      x1 = selection.current.x2
      x2 = selection.current.x1
    } else {
      x1 = selection.current.x1
      x2 = selection.current.x2
    }

    //reduce the now greater value from lesser value to get high and width.
    var tmp_height = y2 - y1
    var tmp_width = x2 - x1
    
    //console.log("tmp_height: ", tmp_height) //for debugging
    //console.log("tmp_width: ", tmp_width) //for debugging
    //console.log("rectangles: ", rectangles) //for debugging

    //if rectangle is too small cancel creation
    if ((tmp_height < 5 && tmp_height > -5)  && (tmp_width < 5 && tmp_width <5)) {
      return
    }



    var tmp_rectangles = [
      ...rectangles,
    {
      x: x1,  //fixed to work at mouse cursor.
      y: y1,  //fixed to work at mouse cursor.
      //x: e.evt.clientX,
      //y: e.evt.clientY,
      width: tmp_width,
      height: tmp_height,
      opacity: 0.3,
      fill: highlightColor,
      id: tmp_id,
      //id: uniqid(),
    },]

    setRectangles([
      ...rectangles,
      {
        x: x1,  //fixed to work at mouse cursor.
        y: y1,  //fixed to work at mouse cursor.
        //x: e.evt.clientX,
        //y: e.evt.clientY,
        width: tmp_width,
        height: tmp_height,
        opacity: 0.3,
        fill: highlightColor,
        id: tmp_id,
        //id: uniqid(),
      },
    ])

    passPageAnnotations(page_number, tmp_rectangles)
    
    //SET RECTANGLE TO SELECTED
    //THIS HAS A DELAY WITH MANY OBJECTS  
    //selectShape(tmp_id)

  }, 110);

  //Add update rect list here.

  const onToggleOnOff = () => {
    setIsDisabled(!isDisabled);
  };

  const onDelete = useCallback(
    _.debounce((selectedId, rectangles) => {

      if(selectedId){

          console.log("Page No. ", page_number ,"selectedId: ", selectedId)

          var tmp_rect = rectangles.filter((rect) => rect.id !== selectedId)
          setRectangles(rectangles.filter((rect) => rect.id !== selectedId));
          console.log(page_number,": ", tmp_rect)
          //update rectangle list
          passPageAnnotations(page_number, tmp_rect)
          //selectShape(null);
      }

    }, 100),
    []
  );

  const handlePress = (e) => {
    if (e.code === "Delete") {
        
      //this controls the canvas.  Otherwise all canvases fire on the button press.
      if(entered){
        console.log("Delete entered: ", page_number, "entered: ", entered)
        onDelete(selectedId, rectangles);
      }
  }
}


  //LISTEN FOR BUTTON PRESSES.
  //DELETE - Deletes a rectangle.

  useEffect(() => {
    document.addEventListener("keydown", handlePress)

    //Setup resize loop
    console.log(div_id)

    const ReactCanvasinterval = setInterval(() => {
      console.log('Interval triggered - ReactCanvas');
      //console.log(div_id)


      //getting DivID on passed ID will be transfered for konva canvas and therefore, you will not get height of actual page div.  Need to seperately query page div.

      if (document.querySelector(div_target)) {
        //clear Interval to stop loop
        clearInterval(ReactCanvasinterval)

        
        
        //console.log("DIV Exists!"); //for Debug
        var pdfcanvas = document.querySelector(div_target)  //need to target parent div, not canvas that got moddified.
        //var pdfcanvas = document.getElementById(div_id);  //target div by ID.  Fails for Konva since div is replaced with stage properties.
        
        //copy style
        const styles = window.getComputedStyle(pdfcanvas);
        let cssText = styles.cssText;

        //testing properties
        //console.log("height: ", pdfcanvas.height) //for Debugging
        //console.log("width: ", pdfcanvas.width)  //for Debugging
        //console.log("cssText height: ", styles.getPropertyValue("height"))  //for Debugging
        //console.log("cssText width: ", styles.getPropertyValue("width"))  //for Debugging
        
        //setContainerWidthHeight({height:pdfcanvas.height, width:pdfcanvas.width})
        setContainerWidthHeight({height:pdfcanvas.height, width:pdfcanvas.width}) //Debugging.  Works for set state.
        //containerWidthHeight.height = pdfcanvas.height
        //containerWidthHeight.width = pdfcanvas.width
        //console.log("demisioned updated")
      }
      else{
        console.log(div_id + "does not exist!")
      }
    }, 1000);

    return () => {
      clearInterval(ReactCanvasinterval); //clears when you exit component.  eq to ComponentDoesUnmount.
      document.removeEventListener("keydown", handlePress);
    }


  }, [selectedId, rectangles]);

  const onImportvalueChange = (e) => {
    setImportvalue(e.target.value);
  }
  //https://konvajs.org/docs/sandbox/Responsive_Canvas.html

  //ADDING RESIZE OR SIZE WHILE INSERTING.
  //https://konvajs.org/docs/select_and_transform/Basic_demo.html
  //https://codesandbox.io/s/react-konva-multiple-selection-tgggi

  return (
        <Stage
          width={containerWidthHeight.width}
          height={containerWidthHeight.height}

          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onTouchStart={checkDeselect}
          onClick={onClickTap}
          onMouseEnter={() => {
            setIntered(true);
            console.log("Entered :", page_number); //for debugging
          }}
          onMouseLeave={() => {
            setIntered(false);
            console.log("Left :", page_number); //for debugging
          }}

          //onMouseDown={checkDeselect}
          //onTouchStart={checkDeselect}
          //onDblClick={createRect}
          //onDblTap={createRect}

          container={div_id}
        >
          <Layer ref={layerRef}>
            {rectangles.map((rect, i) => {
              return (
                <Rectangle
                  key={i}
                  getKey={i}
                  shapeProps={rect}
                  isSelected={rect.id === selectedId}
                  /*onSelect={() => {
                    selectShape(rect.id);
                  }}*/
                  onSelect={(e) => {
                    if (e.current !== undefined) {
                      let temp = nodesArray;
                      if (!nodesArray.includes(e.current)) temp.push(e.current);
                      setNodes(temp);
                      trRef.current.nodes(nodesArray);
                      trRef.current.nodes(nodesArray);
                      trRef.current.getLayer().batchDraw();
                    }
                    selectShape(rect.id);
                  }}
                  onChange={(newAttrs) => {
                    const rects = rectangles.slice();
                    rects[i] = newAttrs;
                    setRectangles(rects);
                  }}
                />
              );
            })}
            
            {
              //NEEDED FOR SELECTION RECTANGLE
            }
            <Transformer
              // ref={trRef.current[getKey]}
              ref={trRef}
              boundBoxFunc={(oldBox, newBox) => {
                // limit resize
                if (newBox.width < 5 || newBox.height < 5) {
                  return oldBox;
                }
                return newBox;
              }}
            />
            <Rect fill={highlightColor} ref={selectionRectRef} opacity={0.6} />
          </Layer>
        </Stage>
    
  );
};

export default ReactCanvas;

import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import CloseIcon from '@mui/icons-material/Close';

export default function SearchButton({
  searchTerm: propSearchTerm,
  searchActive,
  handleSearchClick,
  handleSearchTermChange,
  handleClearSearch,
}) {
  const [searchTerm, setSearchTerm] = useState(propSearchTerm);

  useEffect(() => {
    // Only clear the search term when searchActive changes from true to false
    if (!searchActive) {
      if (handleClearSearch) {
        handleClearSearch();
      }
    }
  }, [searchActive, handleClearSearch]);

  const handleInputChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    handleSearchTermChange(newSearchTerm);
  };

  const clearSearchInput = () => {
    setSearchTerm('');
    if (handleClearSearch) {
      handleClearSearch();
    }
  };

  const handleEventStopPropagation = (event) => {
    event.stopPropagation();
  };

  return (
    <>
      <IconButton aria-label="search" onClick={handleSearchClick}>
        <SearchIcon style={{ color: 'white' }} />
      </IconButton>
      {searchActive && (
  <div style={{ 
    display: 'flex', 
    alignItems: 'center',
    width: searchTerm ? '63%' : '70%',  
    backgroundColor: 'white', 
    borderRadius: '15px', 
    border: '1px solid #ccc', 
    boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box'
  }}>
    <InputBase 
      value={searchTerm}  
      placeholder="Search"
      onClick={handleEventStopPropagation}
      onFocus={handleEventStopPropagation}
      onChange={handleInputChange}
      sx={{ flex: 1 }}  
      style={{flexGrow: 1}}  // Ensure it takes available space
    />
    {searchTerm && (
      <IconButton 
        size="small" 
        aria-label="clear search" 
        onClick={clearSearchInput} 
        sx={{ color: 'gray' }}
        style={{flexShrink: 0}}  // Ensure it doesn’t shrink
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>
    )}
  </div>
)}

    </>
  );
}

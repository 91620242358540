import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SearchButton from './SearchButton';
import SorterButton from './SorterButton';
import FileView from './00_FileView';
import { informationgroup_data, documents_data } from './data.js';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => {
  const { expanded, ...other } = props;
  return (
    <MuiAccordionSummary {...other} />
  );
})(({ theme, expanded }) => ({
  backgroundColor: expanded ? 'purple' : "#9925be",
  pointerEvents: 'none',
  '& > *': {
    pointerEvents: 'auto',
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

function InformationGroup({ title, expanded, handleChange,color }) {
const toggleAccordion = (event) => {
  event.stopPropagation();
  handleChange(event, !expanded);
};

  const [expandedPaths, setExpandedPaths] = useState([]);
  const [searchActive, setSearchActive] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSorted, setIsSorted] = useState(false);
   const [sortOrder, setSortOrder] = useState('none');
   const [checkedItems, setCheckedItems] = useState([]);

const toggleSort = () => {
    setSortOrder(prevOrder => {
        switch (prevOrder) {
            case 'none':
                return 'asc';
            case 'asc':
                return 'desc';
            case 'desc':
                return 'none'; 
            default:
                return 'none';
        }
    });
};
  const handleSearchTermChange = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
};

 const handleSort = (order) => {
    setSortOrder(order);
};   
  const [previousSearchTerm, setPreviousSearchTerm] = useState('');

  const handleSearchClick = () => {
    if (searchActive && searchTerm) {
      // Save the current search term as the previous search term
      setPreviousSearchTerm(searchTerm);
      handleClearSearch();  // Clear the search term and reset state
    } else if (previousSearchTerm) {
      // If there's a previous search term, apply it and start searching
      setSearchTerm(previousSearchTerm);
    }
    setSearchActive(!searchActive);  // Toggle the search active state
};

const filterdata = searchTerm != "" ? filterData(informationgroup_data, searchTerm) : informationgroup_data;
useEffect(() => {
    const paths = getPathsForNodesWithChildren(filterdata);  
    setExpandedPaths(paths);
}, []);

const handleSorterClick = (event) => {
  event.stopPropagation();
  event.preventDefault();
};
const handleClearSearch = () => {
        setSearchTerm('');
        handleSearchTermChange('');  // Clear the search term
    };
 const clearSearchTerm = () => {
    setSearchTerm('');  // clear the search term
  };
  console.log("searchitem: ", searchTerm)
const sortData = (data, order) => {
    if (!data.children) return data;
    const sortedChildren = [...data.children].sort((a, b) => {
        if (order === 'asc') {
            return a.name.localeCompare(b.name);
        } else if (order === 'desc') {
            return b.name.localeCompare(a.name);
        } else {
            return 0;
        }
    });
    return {
        ...data,
        children: sortedChildren.map(child => sortData(child, order))
    };
};
  return (
    <Accordion expanded={expanded}>
      <AccordionSummary 
        aria-controls="panel1d-content" 
        id="panel1d-header" 
        expanded={expanded}
      >
       <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <SearchButton 
      searchActive={searchActive} 
      handleSearchClick={handleSearchClick} 
      handleSearchTermChange={handleSearchTermChange}
      handleClearSearch={handleClearSearch}  
      clearSearchTerm={clearSearchTerm}
    />
    {!searchActive && <Typography style={{color: 'white', marginLeft: '8px'}}>{title}</Typography>}
  </div>

  <div style={{ marginLeft: 'auto' }}>
    <SorterButton onSort={handleSort} sortOrder={sortOrder} />
  </div>

  <ArrowForwardIosSharpIcon 
    onClick={toggleAccordion}
    style={{
      color: 'white',
      transform: expanded ? 'rotate(90deg)' : 'rotate(-90deg)',
      fontSize: '14px',
      marginLeft: '16px'  // Add some space between the sorter button and the arrow icon
    }} 
  />
</Box>


      </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>

          {/* This has been modified in the current code and will need to be updated to reflect the current state of the code. */}
          <FileView 
              data={sortData(filterdata, sortOrder).children} 
              expandedPaths={expandedPaths} 
              searchIsActive={searchActive} 
              new_color={filterdata.color}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
          />

        </AccordionDetails>
    </Accordion>
  );
}

export default function CustomizedAccordions() {
  const groups = [
    { id: informationgroup_data.id, title: informationgroup_data.name, color: informationgroup_data.color }
  ];

  const initialExpandedState = groups.reduce((acc, group) => {
    acc[group.id] = false;
    return acc;
  }, {});

  const [expanded, setExpanded] = React.useState(initialExpandedState);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded((prev) => ({
      ...prev,
      [panel]: newExpanded,
    }));
  };

  return (
    <div>
      {groups.map((group) => (
        <InformationGroup
          key={group.id}
          title={group.title}
          expanded={expanded[group.id]}
          color={group.color}
          handleChange={handleChange(group.id)}
        />
      ))}
    </div>
  );
}
const resetExpanded = (data) => {
  const newData = { ...data };

  if (newData.expanded) {
    newData.expanded = false;
  }
  
  if (newData.children) {
    newData.children = newData.children.map(child => resetExpanded(child));
  }
  
  return newData;
}

const filterData = (data, searchTerm) => {
  if (!searchTerm) return data;

  const lowerCaseSearchTerm = searchTerm.toLowerCase();
  const searchResults = [];

  // Recursive function to search nodes and their children
  const searchNodes = (node) => {
    if (node.name.toLowerCase().includes(lowerCaseSearchTerm)) {
      // If the node's name matches the search term, add it as a top-level item
      searchResults.push({ ...node });
    }
    if (node.children) {
      for (const child of node.children) {
        searchNodes(child); // Recursively search children
      }
    }
  };

  // Start the search for each child of the root node
  data.children.forEach(searchNodes);

  return { ...data, children: searchResults };
}


function getPathsForNodesWithChildren(data, path = []) {
    let paths = [];
    
    const newPath = [...path, data.name];
    if (data.children) {
        paths.push(newPath.join('/'));
        for (let child of data.children) {
            paths = paths.concat(getPathsForNodesWithChildren(child, newPath));
        }
    }

    return paths;
}
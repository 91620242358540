import React, { useState } from 'react';
import { IconButton, styled } from '@mui/material';
import { PushPin } from '@mui/icons-material';

const PushPinButton = ({iconButtonStyle}) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  return (
    <IconButton 
      aria-label="expand_paragraph_search"
      sx={{
        ...iconButtonStyle, //incorporate the passed variable
        backgroundColor: isClicked ? 'secondary.main' : 'info.main',
        color: 'white',
        '&:hover': {
          backgroundColor: isClicked ? 'secondary.dark' : 'info.dark',
        },
      }}
      onClick={handleClick}
      >
        <PushPin/>

    </IconButton>
  );
};

export default PushPinButton;
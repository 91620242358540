import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography, Modal, IconButton, Table, TableBody, TableCell, TableRow } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const CheckReportWindow = ({ paragraphs, responses, checks, isOpen, setIsOpen, ...props }) => {
  const [tabValue, setTabValue] = useState(0);

  const handleClose = () => setIsOpen(false);
  const handleChange = (event, newValue) => setTabValue(newValue);

  const groupResponsesByChecklistId = (responses) => {
    const grouped = {};
    checks.forEach(check => {
      const responsesForCheck = responses.filter(response => response.Check_ID === check.ID);
      if (responsesForCheck.length > 0) {
        if (!grouped[check.Checklist_ID]) {
          grouped[check.Checklist_ID] = { title: check.Checklist_Title, checks: [] };
        }
        grouped[check.Checklist_ID].checks.push({ checkText: check.Text, responses: responsesForCheck });
      }
    });
    return grouped;
  };

  const getParagraphTextById = (paragraphId) => {
    const paragraph = paragraphs.find(p => p.ID === paragraphId);
    return paragraph ? paragraph.Text : '';
  };

  const groupedResponses = groupResponsesByChecklistId(responses);

  const responseColor = {
    check: '#e6ffe6', // even lighter green
    cross: '#ffe6e6', // even lighter red
    question: '#ffffe6', // even lighter yellow
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '90%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        outline: 'none',
        overflow: 'auto',
      }}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Tabs value={tabValue} onChange={handleChange} centered>
          {Object.entries(groupedResponses).map(([checklistId, { title }], index) => (
            <Tab key={checklistId} label={title} />
          ))}
        </Tabs>
        {Object.entries(groupedResponses).map(([checklistId, { title, checks }], index) => (
          <div key={checklistId} hidden={tabValue !== index}>
            {checks.map((check, checkIndex) => (
              <Box key={checkIndex} sx={{ mt: 2 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{`${checkIndex + 1}. ${check.checkText}`}</Typography>
                {check.responses.map(({ ID, Paragraph_ID, Response }) => (
                  <Box key={ID} sx={{ mt: 1, ml: 4, border: '1px solid grey', p: 1 }}>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ width: '50px' }}>
                            {Response === "cross" && <CloseIcon sx={{ color: 'darkred' }} />}
                            {Response === "check" && <CheckIcon sx={{ color: 'darkgreen' }} />}
                            {Response === "question" && <HelpOutlineIcon sx={{ color: 'orange' }} />}
                          </TableCell>
                          <TableCell sx={{ background: responseColor[Response] }}>
                            {getParagraphTextById(Paragraph_ID)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                ))}
              </Box>
            ))}
          </div>
        ))}
      </Box>
    </Modal>
  );
};

export default CheckReportWindow;

import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';

export const GroupCheckBox = ({ title, checked: initialChecked, onCheckChange }) => {
  const [checked, setChecked] = useState(initialChecked);

  useEffect(() => {
    console.log("initialChecked changed: ", initialChecked);
    console.log("Prop 'initialChecked' value in GroupCheckBox:", initialChecked);
    setChecked(initialChecked);
  }, [initialChecked]);

  const handleCheckChange = (e) => {
    e.stopPropagation();
    let nextChecked;
    if (checked === 'Empty') nextChecked = 'Processed';
    else nextChecked = 'Empty';

    setChecked(nextChecked);
    onCheckChange(title, 'group', nextChecked);
  }

  const checkboxColor = 
    checked === 'Empty' ? 'default' 
  : checked === 'Processed' ? '#00FF00' 
  : checked === 'DisabledProcessing' ? '#80FF80' 
  : undefined; // or any default color

  return (
    <Checkbox 
      indeterminate={checked === 'Process'} 
      checked={checked === 'Processed' || checked === 'DisabledProcessing' || checked === "Disabled"} 
      onClick={(e) => e.stopPropagation()} 
      onChange={handleCheckChange} 
      sx={{ 
        ml: 2, 
        color: checkboxColor, 
        '&.Mui-checked': { color: checkboxColor }
      }}
    />
  );
}

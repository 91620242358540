import React, { Component } from 'react'
import Item from './10_item'
import {Droppable} from 'react-beautiful-dnd'

class Column extends Component {

	constructor(props) {
		super(props)
	
		this.state = {
			//Dev Note:  These will need to be linked so when other component updates.  They update and force render.
			 text: props.text,
            index: props.index
		}
	}

    /* Notes:
    
        https://dev.to/imjoshellis/codealong-multi-column-drag-and-drop-in-react-3781

    */

	render = () => {
		
		return (
        
            <Droppable droppableId={this.props.col.id}>
                { provided => (
                    <div style={{
                        display: 'flex',
                        flexDirection:'column'

                    }}
                    >
                        <h2>{this.props.col.id}</h2>
                        <div
                            style={{
                                display:'flex',
                                flexDirection: 'column',
                                minHeight:'120px',
                                backgroundColor: '#eee'
                            }}
                        
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {this.props.col.item_list.map((text, index) =>(
                                <Item key={text} text={text} index={index}/>
                            ))}
                            {provided.placeholder}			
                        </div>
                    </div>    
                )}
            </Droppable>
		)
	}
}

export default Column
import React, { Component } from 'react'

//This will allow files to be dragged and dropped to be uploaded.
/*

Notes:

Need to add a section that gets file data to be sent to server. Such as: last date modified, date created, etc.
Probably need to detect operating system.

*/
class File_Uploader extends Component {

	constructor(props) {
		super(props);
		//this.viewerRef = React.createRef();
    	//this.backend = new props.backend();
	
		this.state = {
			//Dev Note:  These will need to be linked so when other component updates.  They update and force render.
			 pdfURL: [],
            isLoading: false
		}
	}
	/*
	componentDidMount() {
		const { src } = this.props;
		//const element = this.viewerRef.current;  //pdfjs parts.
	  }
*/

componentDidUpdate = (prevProps, prevState) => {


	console.log("updated - check for infinite loop")
	var progress = document.getElementById("progress");
	var progress_wrapper = document.getElementById("progress_wrapper");
	var progress_status = document.getElementById("progress_status");

	var upload_btn = document.getElementById("upload_btn");
	var loading_btn = document.getElementById("loading_btn");
	var cancel_btn = document.getElementById("cancel_btn");

	var alert_wrapper = document.getElementById("alert_wrapper");

	var file_input_label = document.getElementById("file_input_label");
	var input = document.getElementById("file_input");

	//USE IF STATEMENTS TO STOP INFINITE LOOP SO THAT THIS DOESN"T CRASH THE APPLICATION.
	if(prevState.progress !== this.state.progress){
		this.setState(progress)
	}
	
	if(prevState.progress_wrapper !== this.state.progress_wrapper){
		this.setState(progress_wrapper)
	}
	
	if(prevState.progress_status !== this.state.progress_status){
		this.setState(progress_status)
	}

	if(prevState.upload_btn !== this.state.upload_btn){
		this.setState(upload_btn)
	}
	
	if(prevState.loading_btn !== this.state.loading_btn){
		this.setState(loading_btn)
	}

	if(prevState.cancel_btn !== this.state.cancel_btn){
		this.setState(cancel_btn)
	}

	if(prevState.alert_wrapper !== this.state.alert_wrapper){
		this.setState(alert_wrapper)
	}

}


show_alert(message, valert){
	var alert_wrapper = document.getElementById("alert_wrapper");

	alert_wrapper.innerHTML = `
	<div class="alert alert-${valert} alert-dismissible fade show" role="alert">
		<span> ${message} </span>
		<button type="button" class="close" data-dismiss="alert" aria-label="Close">
			<span aria-hidden="true">&times;</span>
			</button>
		</div>
	`
}

input_filename(){
	var file_input_label = document.getElementById("file_input_label");
	var input = document.getElementById("file_input");

	file_input_label.innerText = input.files[0].name;
}


reset() {

	var progress = document.getElementById("progress");
	var progress_wrapper = document.getElementById("progress_wrapper");
	var progress_status = document.getElementById("progress_status");

	var upload_btn = document.getElementById("upload_btn");
	var loading_btn = document.getElementById("loading_btn");
	var cancel_btn = document.getElementById("cancel_btn");

	var alert_wrapper = document.getElementById("alert_wrapper");

	var file_input_label = document.getElementById("file_input_label");
	var input = document.getElementById("file_input");

	input.value = null;
	input.disabled = false;

	cancel_btn.classList.add("d-none");
	loading_btn.classList.add("d-none");
	upload_btn.classList.remove("d-none");

	progress_wrapper.classList.add("d-none");
	progress.setAttribute("style", "width: 0%");

	file_input_label.innerText = "Select file";


}


upload = () => {

	var url = "/upload";

	var progress = document.getElementById("progress");
	var progress_wrapper = document.getElementById("progress_wrapper");
	var progress_status = document.getElementById("progress_status");

	var upload_btn = document.getElementById("upload_btn");
	var loading_btn = document.getElementById("loading_btn");
	var cancel_btn = document.getElementById("cancel_btn");

	var alert_wrapper = document.getElementById("alert_wrapper");

	var file_input_label = document.getElementById("file_input_label");
	var input = document.getElementById("file_input");

	if(!input.value) {

		this.show_alert("No File selected", "warning");

		return;

	}

	var data = new FormData();

	var request = new XMLHttpRequest();

	request.responseType = "json";

	alert_wrapper.innerHTML = "";

	input.disable = true;

	upload_btn.classList.add("d-none");

	loading_btn.classList.remove("d-none");

	cancel_btn.classList.remove("d-none");

	progress_wrapper.classList.remove("d-none");

	var file = input.files[0];

	var filename = file.name;

	var filesize = file.size;

	document.cooke = `filesize=${filesize}`;

	data.append("file", file);
	data.append("filesize", filesize);
	data.append("matter_id", this.props.matter_id);  //sent to flask via request.form. Throws CORS may be thrown if syntax
	data.append("project_id", this.props.project_id);  //sent to flask via request.form.  Throws CORS may be thrown if syntax

	request.upload.addEventListener("progress", (e) => {

		var loaded = e.loaded;
		var total = e.total;

		var percentage_complete = (loaded / total) * 100;

		progress.setAttribute("style", `width: ${Math.floor(percentage_complete)}%`);

		progress_status.innerText = `width: ${Math.floor(percentage_complete)}% uploaded`;

		

	})


	request.addEventListener("load", (e) =>{
		if(request.status == 200){

			this.show_alert(`${request.response.message}`, "success");
		
		}
		else {
			
			this.show_alert(`Error uploading file`, "danger");
		}

		this.reset();
	})

	request.addEventListener("error", (e) =>{

		this.reset();

		this.show_alert("Error uploading file", "danger");
	})

	request.addEventListener("abort", (e) =>{

		this.reset();

		this.show_alert("Upload cancelled", "primary");
	})

	request.open("post", url);
	console.log("upload data:", this.props.matter_id, this.props.project_id)
	request.setRequestHeader('Authorization', 'Bearer ' + this.props.token)
	request.send(data);

	cancel_btn.addEventListener("click", function(){

		request.abort();

	})

}  // end upload

	render() {
		return(

				<div style={{border: '2px solid lightblue'}}>
					
					<meta charset="utf-8"/>
					<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
			
					<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css" integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk" crossOrigin="anonymous"/>
				
					<h5>Please upload the file (make sure to choose a Project):</h5>
					
					
					<div className="container">
						<div className="row">
							<div className="col">

								<div className="mb-3 mt-3">
									
									<h2 className="mb-3" style={{fontweight: "300"}}></h2>

									<div className="form-group mb-3">

										<div className="custom-file">

											
												<input type="file" multiple="" id="file_input" name="files[]" className="custom-file-input" onInput={this.input_filename}/>
												<label for="file_input" id="file_input_label" className="custom-file-label">Select File</label>
												<br/>
			
												<button onClick={this.upload} id="upload_btn" className="btn btn-primary">Upload</button>
												<button className="btn btn-primary d-none" id="loading_btn" type="button" disabled>
													<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
													Uploading...
												</button>

												<button className="btn btn-secondary d-none" id="cancel_btn" type="button">Cancel </button>

											<div id="progress_wrapper" className="d-none">
												<label id="progress_status">50% uploaded</label>
												<div className="progress mb-3">
													<div id="progress" className="progress-bar" role="progressbar" aria-valuenow="25" area-valuemin="0"  area-valuemax="100"></div>
												</div>
											</div>

											<div id="alert_wrapper"></div>

										</div>

									</div>

								</div>

							</div>
						</div>
					</div>
				
						<script src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossOrigin="anonymous"></script>
						<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js" integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo" crossOrigin="anonymous"></script>
						<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.min.js" integrity="sha384-OgVRvuATP1z7JjHLkuOU7Xw704+h835Lr+6QL9UvYjZE3Ipu6Tp75j7Bh/kR0JKI" crossOrigin="anonymous"></script>
				
				</div>
			);
	}
}

{
	//<form action="upload" method="post" enctype="multipart/form-data"></form>
}

export default File_Uploader
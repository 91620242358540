import React from 'react';
import { Chip, Box } from '@mui/material';

const SearchTermChips = ({ searchQuery }) => {
  const colors = ['lightpink', 'lightgreen', 'lightblue', 'lightskyblue', 'lavender', 'mint', 'peach', 'lilac', 'canary', 'babyblue', 'coral', 'skyblue', 'buttercream'];
  
  // Function to extract and clean search words
  const prepareSearchWords = searchWordsString => {
    // Match quoted phrases with single or double quotes or individual words
    const matches = searchWordsString.match(/"([^"]+)"|'([^']+)'|\b(\w+)\b/g) || [];

    return matches.map(word => {
      if ((word.startsWith('"') && word.endsWith('"')) || (word.startsWith("'") && word.endsWith("'"))) {
        // Remove quotes for exact phrases and do not remove special characters
        return word.substring(1, word.length - 1);
      } else {
        // Remove special characters for individual words and convert to lowercase
        return word.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
      }
    }).filter(word => word !== 'and' && word !== 'or');
  };

  // const searchTerms = parseSearchTerms(searchQuery);
  const searchTerms = prepareSearchWords(searchQuery);

  return (
    <Box sx={{ padding: 2, display: 'flex', flexWrap: 'wrap' }}>
      {searchTerms.map((term, index) => (
        <Chip 
          label={term} 
          sx={{ 
            backgroundColor: colors[index % colors.length], 
            marginRight: 1,
            marginBottom: 1
          }} 
          key={index} 
        />
      ))}
    </Box>
  );
};

export default SearchTermChips;

import { yellow } from "@mui/material/colors";
import React, { Component, useEffect } from "react";
import SortableItems from "./05_drag_and_drop_listv2";
import MultiPDF_Viewer from './05_multipdfviewer';
import ScreenfillModalObject from './06_screenfill_modal'


class DocumentAnnotatorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [["7", "1", "30"]],
      show_full_modal: false,
      doc_id: "test",
      processing_complete: false,
      annotator_on: false,
      rectanglesNestList: false,
      highlightColor: "yellow",
      isLoading: true //this isn't used yet.
    };
  }

  //THE BELOW: componentDidMount, componentWillUnmount, onEscape functions are needed to enable keypress detection.
  componentDidMount(){
    //Mounts listener when component is mounted.
    document.addEventListener("keydown", this.onKeyPressed, false)
    
  }

  componentWillUnmount(){
    //Removes when component is dismounted.
    document.removeEventListener("keydown", this.onKeyPressed, false)
  }

  onKeyPressed = (e) => {
    //ADD KEY DETECTION HERE
    if (e.key == "Escape"){
      console.log("Pressed: ", e.key)  //for debugging
      this.setState({annotator_on: false});
    }
  }

  updateItems = (items) => {
    this.setState({items})
  }

  updateDocID = (tmp_doc_id) => {
    //alert(tmp_doc_id);
    var obj = JSON.parse(tmp_doc_id);
    //alert(obj.data);    
    this.setState({doc_id: obj.data});
    console.log("Obj data: ", obj.data);
    //debugging how to check state change
    // this.setState({doc_id:obj.data }, () => {
    //   console.log('doc_id:', this.state.doc_id);
    // }); 
    //alert("Page Merge DocID: ", this.state.new_doc_id)
  }

  setAnnotatorState = (tmp_state) => {
    //alert(tmp_state);
    this.setState({annotator_on: tmp_state});
    console.log("annotator state changed to: ", tmp_state); //for debugging
    console.log("AnnotatorState:", this.state.annotator_on)
  }

  updateProcessing = (tmp_data) => {
    //alert(tmp_data);
    this.setState({processing_complete: tmp_data});
    
    //debugging how to check state change
    // this.setState({doc_id:obj.data }, () => {
    //   console.log('doc_id:', this.state.doc_id);
    // }); 
    //alert("Page Merge DocID: ", this.state.new_doc_id)
  }

  showFullModal = () => {
    this.setState({show_full_modal:true});
  }

  hideFullModal = () => {
    this.setState({show_full_modal:false});
  }

  passPageAnnotations = (tmp_page_num, tmp_rectangles_array) => {
    //this is not defined
    if(!this.state.rectanglesNestList){
      //console.log("pasPageAnnotations. rectanglesNestList NOT detected.  Making new nested dict")
      var tmp_rectanglesNestList = {}
    } else {
      //console.log("pasPageAnnotations. rectanglesNestList detected")
      var tmp_rectanglesNestList = this.state.rectanglesNestList  
    }

    console.log("passPageAnnotations: ", tmp_page_num, tmp_rectangles_array, tmp_rectanglesNestList)

    tmp_rectanglesNestList[tmp_page_num] = tmp_rectangles_array
    this.setState({rectanglesNestList: tmp_rectanglesNestList})
  }

  passHighlightColor = (tmpHighlightColor) => {
    //set highlight color
    this.setState({highlightColor: tmpHighlightColor})
  }

  render() {
    //console.log(this.state.items);
    return(
      <div>
        {
          //This is the ?? modal.
        }
        <ScreenfillModalObject 
          show={this.state.show_full_modal} 
          doc_id={this.state.doc_id}
          processing_complete={this.state.processing_complete} 
          updateDocID={this.updateDocID} 
          updateProcessing={this.updateProcessing} 
          hideFullModal={this.hideFullModal}
        />


        <div className="PDF_View_Sidebar">
          {
            //This is the sidebar
          }
          <SortableItems 
              pdf_list={this.state.items} 
              updateItems={this.updateItems} 
              updateDocID={this.updateDocID}
              updateProcessing={this.updateProcessing} 
              showFullModal={this.showFullModal}
              annotator_state={this.state.annotator_on}
              setAnnotatorState={this.setAnnotatorState}
              rectanglesNestList={this.state.rectanglesNestList}
              passPageAnnotations={this.passPageAnnotations}
              highlightColor={this.state.highlightColor}
              passHighlightColor={this.passHighlightColor}
          />

        </div>

        <div className="PDF_View_Page">
        {this.state.items.map((value, index) => (
            //Note: if you add () to a function it calls it.
            //Index is not passed so you have to define it twice.
            <MultiPDF_Viewer 
              src = {`/getfile.py?docid=${value[0]}`}
              startPage = {value[1]}
              endPage = {value[2]}
              annotator_state={this.state.annotator_on}
              setAnnotatorState={this.setAnnotatorState}
              rectanglesNestList={this.state.rectanglesNestList}
              passPageAnnotations={this.passPageAnnotations}
              highlightColor={this.state.highlightColor}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default DocumentAnnotatorPage;
import React from 'react';
import { Box, IconButton } from '@mui/material';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

export default function TypeButtonGroup({hit, searchQuery, ...props}) {
  const iconButtonStyle = {
    // backgroundColor: '#1976d2',
    // color: 'white',
    borderRadius: '5px',
    margin: '1px 1px',
    padding: '5px 10px'
  };

  //console.log("ActionButtonGroup:", hit);

  return (

    <Box sx={{ position: 'absolute', top: '4px', left: '4px', display: 'flex', zIndex: '98'}}> {/* using a box or div so I can add multiple buttons.  */}
        <IconButton 
          aria-label="document_type_icon"
          disableRipple // This disables the click animation
          sx={{
            ...iconButtonStyle, // incorporate the passed variable
            backgroundColor: 'white',
            color: 'grey',
            '&:hover': {
              backgroundColor: 'white', // same as default state
              color: 'grey', // same as default state
            },
            border: '1px solid rgba(0, 0, 0, 0.2)', // ensure the outline is the same on hover
            cursor: 'default' // Disable the pointer cursor
          }}
        >
          <QuestionMarkIcon/>
        </IconButton>
      
      {/* <LabelButton 
        token = {props.token} 
        setToken={props.setToken}
        paragraph_id={hit["_source"]['ID']}
        iconButtonStyle={iconButtonStyle}
      /> */}
    </Box>
  );
}
import React from 'react';
import { CardMedia } from '@mui/material';
import { Link, Typography } from '@mui/material';
import SecureFileLink from '../../File Authentication/SecureFileLink';

function DocumentImage({ hit, ...props }) {
  return (
    // <CardMedia
    //   component="img"
    //   sx={{ width: 450, height: 200, border: '1px solid #ccc', margin: 1 }}
    //   image={image}
    //   alt="Document Thumbnail"
    // />
    // <Link target="_blank" href={`/getfile.py?docid=${hit["_source"]["Doc_ID"]}#page=${hit["_source"]["File_Page_Number"]}`}>
    <SecureFileLink 
      docid={hit["_source"]["Doc_ID"]} 
      token={props.token} 
      target="_blank" 
      page={hit["_source"]["File_Page_Number"]}
    >
      <CardMedia
        component="img"
        //height="200"
        image={`/geticon?docid=${hit["_source"]["Doc_ID"]}`}
        alt="Document Thumbnail"
        align="center"
        sx={{ 
          maxWidth: '100%', 
          maxHeight: 200, 
          width: 'auto', 
          height: 'auto', 
          objectFit: 'contain', 
          border: '1px solid #ccc',
          margin: 1 }}
      />
    </SecureFileLink>
    // </Link>
  );
}
export default DocumentImage;
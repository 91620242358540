import React, { Component } from 'react'
import {Draggable} from 'react-beautiful-dnd'

class Item extends Component {

	constructor(props) {
		super(props)
	
		this.state = {
			//Dev Note:  These will need to be linked so when other component updates.  They update and force render.
			text: props.text,
            index: props.index
		}
	}

	render(){
		
		return (
			<Draggable draggableId={this.props.text} index={this.props.index}>
				{provided => (
					<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					>
						<span>{this.props.text}</span>			
					</div>
				)}

			</Draggable>
		  )
	}
}

export default Item
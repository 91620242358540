import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListIcon from '@mui/icons-material/List';
import AddButton from './FolderView/AddButton';
import StarIcon from '@mui/icons-material/Star'; // Import the Star icon
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import ModalPage from './FolderView/Modal'; 
import Modal from '@mui/material/Modal';
import SomeComponent from './FolderView/Modal'

import { TextField, Button, Select, MenuItem, FormControl, InputLabel, InputAdornment, Box} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { useMediaQuery } from '@mui/material';

const API = '/search.api';  //Prod  Check

function appBarLabel(onMenuClick, handleOpen, showMenuIcon = true, handleSubmit, SearchQuery, db_type, handleMenuChange, handleSearch_QueryChange, isLargeScreen) {  // Add handleOpen parameter

  return (
    <Toolbar sx={{ minHeight: '40px', padding: '0px', alignItems: 'center' }}> {/* Reduced minHeight and padding */}
      {showMenuIcon && (
        <>
          <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2, padding: '6px' }} onClick={onMenuClick}> {/* Reduced padding */}
            <ListIcon />
          </IconButton>
          <AddButton onClick={handleOpen} sx={{ padding: '6px' }} /> {/* Reduced padding */}
        </>
      )}
  
      <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}> {/* Reduced margin */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            id="search-query"
            label="Search"
            type="search"
            variant="outlined"
            value={SearchQuery}
            onChange={handleSearch_QueryChange}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                handleSubmit(ev);
              }
            }}
            sx={{ backgroundColor: 'white', flexGrow: 1, marginY: '2px' }} // Reduced marginY
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: { padding: '0 px', 
              minWidth: isLargeScreen ? 400 : 'auto',  // Conditionally set minWidth
            } // Reduced internal padding
            }}
          />
          <Select
            labelId="db-type-select-label"
            id="db-type-select"
            value={db_type}
            onChange={handleMenuChange}
            sx={{
              ml: 1,
              minWidth: isLargeScreen ? 120 : 'auto',  // Conditionally set minWidth
              backgroundColor: 'white',
              marginY: '2px'
            }}
          >
            <MenuItem value="paragraphs">Paragraphs</MenuItem>
            <MenuItem value="pages">Pages</MenuItem>
          </Select>
        </Box>
      </form>
  
      <Typography variant="h6" noWrap component="div" />
    </Toolbar>
  );
}

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1976d2',
    },
  },
});

export default function SearchBar({ onMenuClick, SearchQuery, setSearchQuery, DBType, setDBType, CheckedItems,isSelectionChanged, ...props }) {
  const [modalOpen, setModalOpen] = React.useState(false);

  const isLargeScreen = useMediaQuery('(min-width:1280px)');

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSearch_QueryChange = (event) => {
		setSearchQuery(event.target.value)	
	}

  const handleMenuChange = (event) => {
		setDBType(event.target.value)
	}

  // const handleSubmit = (event) => {alert("Submitted!")}  //For Dev

  //FOR PROD
  const handleSubmit = (event) => {
		event.preventDefault()
		props.parentCallbackSetLoading(true);
		console.log("search check:", props.project_id, props.matter_id)
    console.log("SearchQuery, DBType:", SearchQuery, DBType)
		console.log("checking token:", props.token)

		props.callbackSetQueryNdb_type(SearchQuery, DBType)

		fetch(API, {
			method: 'POST',
			mode: 'cors',
			body: JSON.stringify({
				search_query: SearchQuery,
				db_type: DBType,
				project_id: props.project_id,
				matter_id: props.matter_id,
        checked_items: CheckedItems,
				isSelectionChanged:isSelectionChanged,
			}),	
			headers: new Headers({
				//'Accept': 'application/json; charset=utf-8',
				'Authorization': 'Bearer ' + props.token,
				'Content-Type': 'application/json',
				//'Access-Control-Allow-Origin': '*',
			})
		})
		//.then(response => response.text())          // convert to plain text
		//.then(text => console.log("fetch data: ",text))  // then log it out
		.then(response=> {
			return response.json() //convert to json.  return passes it
		})  // Prod
		//.then(data => console.log("fetch data: ",data))  //For Debugging
		.then((ResponseData) => {
      		console.log("Response:", ResponseData)
			

			//check to see if a new access_token
			//console.log("new token: ", data)
			if(ResponseData.access_token !== undefined){
				console.log("setting new token")
				props.setToken(ResponseData.access_token) //if refresh token present. Set it.
			}

			if(ResponseData.results !== undefined){
				console.log("setting results:", ResponseData.results)
				props.parentCallback({ hits: ResponseData.results, isLoading: false });  //send to Parent Search_Page  Handle JSON conversion as parent level?  PROD
				//set isLoading to false.
			  }
			}
		);  //set data to results
	}

  return (
    <Stack spacing={0} sx={{ 
      zIndex: -2000}} >
      {/* ... rest of your code */}
        <AppBar position="static" color="primary" enableColorOnDark sx={{ padding: 0, margin: 0 }}>
          {
            <Typography variant="h6" value="a" noWrap/>
          }
        </AppBar>
        <AppBar position="static" sx={{ backgroundColor: '#4d4d4d' }}>
          {appBarLabel(onMenuClick, handleOpen, true, handleSubmit, SearchQuery, DBType, handleMenuChange, handleSearch_QueryChange, isLargeScreen)}  {/* Pass handleOpen to appBarLabel */}
        </AppBar>

      {/* ... rest of your code */}

      {/* Modal to render ModalPage */}
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <SomeComponent handleClose={handleClose} />
      </Modal>

    </Stack>
  );
}


import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

function MyBox({ onRemove }) { // <-- accept a callback prop named 'onRemove'
  return (
    <Box sx={{ border: '1px solid #ccc', borderRadius: '4px', width: 300, overflow: 'hidden', height: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: '#1976d2', p: 0.5}}>
        <Typography variant="h6">
          Header
        </Typography>
        <IconButton color="inherit" sx={{ color: 'white' }} onClick={onRemove}> {/* <-- attach the callback to the click event */}
          <AddIcon />
        </IconButton>
      </Box>
      <Box sx={{ p: 1, bgcolor: 'white' }}>
        <TextField 
          fullWidth 
          margin="normal" 
          variant="outlined" 
          placeholder="Enter text..." 
          sx={{
            '& fieldset': { 
              borderColor: '#9925be', 
              borderStyle: 'dashed',
              borderWidth: 1 
            },
            '&:hover fieldset': { 
              borderColor: '#9925be !important',  // Ensure border color remains pink on hover
              borderStyle: 'dashed !important'  // Ensure border style remains dashed on hover
            },
            '&.Mui-focused fieldset': { 
              borderColor: 'blue !important',  // Change border color to blue on focus
              borderStyle: 'solid !important'  // Change border style to solid on focus
            },
            '& input': {  // Override height of the input
              height: '30px',
              padding: '4px',  // Adjust padding to vertically center text
            },
          }}
        />
        <Box sx={{ display: 'flex', mt: 1 }}>
          <Button 
            variant="outlined" 
            sx={{ 
              borderColor: '#9925be', 
              borderStyle: 'dashed',
              marginRight: '10px', 
              padding: '0px 8px',   
              color: '#9925be',  // Set text color to #9925be
              '&:hover': { backgroundColor: '#9925be', color: 'white', borderColor: '#9925be', borderStyle: 'dashed' },  // Set button color to #9925be and text color to white on hover
              '&.Mui-focused': { borderColor: '#9925be', borderStyle: 'dashed' },
            }}
          >
            Button 1
          </Button>
          <Button 
            variant="outlined" 
            sx={{ 
              borderColor: '#9925be', 
              borderStyle: 'dashed', 
              padding: '0px 8px', 
              color: '#9925be',  // Set text color to #9925be
              '&:hover': { backgroundColor: '#9925be', color: 'white', borderColor: '#9925be', borderStyle: 'dashed' },  // Set button color to #9925be and text color to white on hover
              '&.Mui-focused': { borderColor: '#9925be', borderStyle: 'dashed' },
            }}
          >
            Button 2
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default MyBox;

import React, { Component } from "react";
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from "react-sortable-hoc";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faBars } from "@fortawesome/free-solid-svg-icons";
import styles from "./05_index.module.css";
import arrayMove from "./05_arrayMove";
import ModalObject from "./06_modal"
import { Card, CardActions, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import HeightIcon from '@mui/icons-material/Height';
import DeleteIcon from '@mui/icons-material/Delete';
import { red } from "@mui/material/colors";

//AppBar
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';



const API = '/merge_documents.py';

//see: https://github.com/clauderic/react-sortable-hoc/issues/44


//Drag handler
const DragHandle = sortableHandle(() => (
  <IconButton className={styles.dragHandler} aria-label="Move" sx={{bgcolor: '#E5E4E2'}}>
      <HeightIcon />
  </IconButton>
));

//Draggable elements
//You must pass functions and define them in the {}.  See delete_func below.
const SortableItem = sortableElement(({ value, i, delete_func }) => (

      <Card className={styles.dragElement}>
        <table sx={{padding:0}}>
          <tr>
            <CardHeader
                title={`Document ID: ${value[0]}`}
                sx={{padding:0}}                
            />
          </tr>
          <tr>
            <CardContent sx={{padding:0}}>
              <Typography>
                Starting Page: {value[1]} <br/>
                Ending Page: {value[2]}
              </Typography>
            </CardContent>
          </tr>
          <tr>
            <CardActions sx={{padding:0}}>
            {/*accept the e argument and then pass it to the function explicitly*/}
              <IconButton onClick={(e) => delete_func(i, e)}> 
                  <DeleteIcon aria-label="remove"/>
              </IconButton>
          </CardActions>
          </tr>
        </table>
        <DragHandle/>

        {/*
        
        action={   
                  <IconButton aria-label="Delete">
                    <DeleteIcon />
                  </IconButton>
                }

        */}
      </Card>

));

//Drag area
const SortableContainer = sortableContainer(({ children }) => {
  return <div className={styles.dragContainer}>{children}</div>;
});

class SortableItems extends Component {
  constructor(props) {
    //console.log(props);
    super(props);
    this.state = {
      items: this.props.pdf_list,
      show_modal:false
    };
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex)
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    
    console.log(this.state.items)
    //alert(this.state.items);
    //Add show model here
    this.props.showFullModal();

    fetch(API, {
        method: 'POST', 
        body: JSON.stringify({
          //search_query: this.state.search_query
          //change docid to appropriate.
          document_array: this.state.items
        }),
        headers: new Headers({
          'Accept': 'application/json; charset=utf-8',
          'Content-Type': 'application/json',
          //'Access-Control-Allow-Origin': '*',
        })
      })
      //.then(response => response.text())          // convert to plain text
      //.then(text => console.log("fetch data: ",text))  // then log it out
      .then(response=> response.json())  // Prod
      //.then(data => console.log("fetch data: ",data))  //For Debugging
      .then((data) => this.props.updateDocID(JSON.stringify({data}), this.props.updateProcessing(true)));  //set data to results
  }

  showModal = (e) => {
    e.preventDefault();
    this.setState({show_modal:true});
  }

  sendUpdate = (e) => {
    e.preventDefault();
    this.props.updateItems(this.state.items);
  }

  hideModal = (e) => {
    this.setState({show_modal:false});
  }

  handleAddItem = (docid, s_page, e_page) => {
    
    //e.preventDefault();  //this gets handled in modal now
        
    const items = this.state.items
    items.push([docid, s_page, e_page]);  //push array
    this.setState({items})
    //this.setState({show_modal:false})
    //console.log(this.state.items)
  }

  handleRemoveItem = (i, e) => {
    //See: https://www.youtube.com/watch?v=tJYBMSuOX3s 7:34
    //console.log("index: ", i)
    //alert("Called delete");
    e.preventDefault();
    
    const items = this.state.items
    items.splice(i, 1); //(index location, 1 # of elements) remove first element of an array
    this.setState({items})
    //console.log(this.state.items)
  }

  render() {
    const { items } = this.state;

    return (
      <div>
      <AppBar
      elevation={0}
      position="sticky" //sticky vs. fixed.  sticky will make the bar stay on top and move the window down.  fixed with not move the window it just floats.
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      style={{zIndex: 2}}
    >
      <Toolbar sx={{flex:1}}>
      <Button variant="contained" sx={{ marginRight: 5 }} onClick={this.showModal}>
          <AddIcon aria-label="Add Document"/>
        </Button>
        <Button variant="contained" sx={{ marginRight: 5 }} onClick={this.handleSubmit}>
          <SaveIcon aria-label="Save Report"/>
        </Button>
        <Button variant="contained" sx={{ marginRight: 5 }} onClick={this.sendUpdate}>
          <RefreshIcon aria-label="Update"/>
        </Button>
      </Toolbar>
    </AppBar>

  <ModalObject show={this.state.show_modal} add_item={this.handleAddItem} close={this.hideModal}/>
  <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
    {items.map((value, index) => (
      //Note: if you add () to a function it calls it.
      //Index is not passed so you have to define it twice.
      <SortableItem key={`item-${index}`} index={index} i={index} value={value} delete_func={this.handleRemoveItem} />
    ))}
  </SortableContainer>
  </div>
    );
  }
}

export default SortableItems;
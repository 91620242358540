import React, { Component } from 'react'
// import Search_Bar from './search_bar';

import InformationGroup from "./FolderView/InformationGroup";
import DocumentsSection from './FolderView/DocumentsSection';
import SearchBar from './SearchBar';
import Results_Box from './results_box';

import { makeStyles } from "@mui/styles";

import {
	AppBar,
	Toolbar,
	Typography,
	Box,
	Grid,
	Button,
	Drawer
} from "@mui/material";

//Parent to search_bar.js and results_box.js


//next step: keep search and results window seperate.
//See: https://www.pluralsight.com/guides/react-redux-reusable-components
//See also: https://towardsdatascience.com/passing-data-between-react-components-parent-children-siblings-a64f89e24ecf

//where should we direct the query.
//const API = '/search_documents';  //local testing
const foldersAPI = '/folders.api'  //get folders data

// Define Styles as a plain object
const drawerWidth = 350;
const appBarHeight = 70; // Adjust based on your AppBar height

const styles = {
  appBar: {
    margin: 0,
    zIndex: 1,
    width: "100%",
  },
  toolBar: {
    justifyContent: "space-between",
    margin: 0,
  },
  sidebar: {
    display: 'block',
    height: "100vh",
    backgroundColor: "#F5F5F5",
    width: '100%',
    whiteSpace: 'nowrap',  // Prevent text from wrapping to the next line
    '@media (min-width: 768px)': {
      width: drawerWidth,
    },
  },
  // ... other styles
};


class Search_Page extends Component {

	constructor(props) {
		super(props)
	
		this.state = {
			//These will be shared among the Child components.
			hits: [],
			length: 50, //not used;  specifies the number of results to return.  Right now we will default the system to 50.
			total: 0, // holds the hits total.
			from: 0, // specifies where the results will be pulled from.  (i.e., if you wanted the next 50, you'd pull from 51 with a length of 50)
			search_query: "", //for passing search query around
			db_type: "paragraphs", //for passing db_type around
            isLoading: true,  // will need to better determine for initial load.  data is not technically loading at start.
			isSidebarOpen: false,
			folders_data: '',
			checked_items: [],
			isSelectionChanged: 'false',
			checkedNodes: [], //checked boxes?
			previousStates: {},
		}
	}

	fetchData = async () => {
		console.log("token is: ", this.props.token)

		// Save the current state to previousStates
		this.setState(prevState => ({
			previousStates: {
				...prevState.previousStates,
				[prevState.project_id]: {
				checked_items: prevState.checked_items,
				isSelectionChanged: prevState.isSelectionChanged,
				checkedNodes: prevState.checkedNodes,  // Save checkedNodes
				},
			},
			}), () => {
			// If the project_id is in previousStates, restore the state
			if (this.state.previousStates.hasOwnProperty(this.props.project_id)) {
				this.setState({
				checked_items: this.state.previousStates[this.props.project_id].checked_items,
				isSelectionChanged: this.state.previousStates[this.props.project_id].isSelectionChanged,
				checkedNodes: this.state.previousStates[this.props.project_id].checkedNodes,
				});
			} else {
				// If the project_id is not in previousStates, clear the state
				this.setState({
				checked_items: [],
				isSelectionChanged: false,
				checkedNodes: [],
				});
			}
		});

		await fetch(foldersAPI, {
			method: 'POST',
			mode: 'cors',
			body: JSON.stringify({
				matter_id: this.props.matter_id,
				project_id: this.props.project_id
			}),  
			headers: new Headers({
				//'Accept': 'application/json; charset=utf-8',
				'Authorization': 'Bearer ' + this.props.token,
				'Content-Type': 'application/json',
				//'Access-Control-Allow-Origin': '*',
			})
		})
		.then(response=> {
			return response.json() //convert to json.  return passes it
		})  
		.then((ResponseData) => {
			console.log("Response (folders):", ResponseData)

			//check to see if a new access_token
			if(ResponseData.access_token !== undefined){
				console.log("setting new token")
				this.props.setToken(ResponseData.access_token) //if refresh token present. Set it.
			}

			if(ResponseData.folders_list !== undefined){
				console.log("setting folders_data:", ResponseData.folders_list) //for debugging
				this.setState({folders_data: ResponseData.folders_list})  //set the folder_data
			}

			// Check if previousStates has a state for this project_id
			if (this.state.previousStates.hasOwnProperty(this.props.project_id)) {
				// If it does, restore the state
				this.setState({
					checked_items: this.state.previousStates[this.props.project_id].checked_items,
					isSelectionChanged: this.state.previousStates[this.props.project_id].isSelectionChanged,
				});
			}
		});
	}
	
	//handle items when component loads.  made async to perserve order
	componentDidMount = async () => {
		//XXXXXXX
		//Handles loading the folders for the matter.
		//XXXXXXX

		this.fetchData()

	}

	componentDidUpdate(prevProps) {
		// Compare the previous project_id prop with the current one
		if (prevProps.project_id !== this.props.project_id) {
			// Save the current state to previousStates
			this.setState(prevState => ({
				previousStates: {
					...prevState.previousStates,
					[prevProps.project_id]: {
						checked_items: prevState.checked_items,
						isSelectionChanged: prevState.isSelectionChanged,
						checkedNodes: prevState.checkedNodes,  // Save checkedNodes
					},
				},
				// Clear checked_items and set isSelectionChanged to false
				checked_items: [],
				isSelectionChanged: false,
				checkedNodes: [],  // Clear checkedNodes
			}), () => {
				// After state update, fetch the new data
				this.fetchData();
			});
		}
	}

	fetchData() {
		// Fetch data based on this.props.project_id
		// Then, check if previousStates has a state for this project_id
		if (this.state.previousStates.hasOwnProperty(this.props.project_id)) {
			// If it does, restore the state
			this.setState({
				checked_items: this.state.previousStates[this.props.project_id].checked_items,
				isSelectionChanged: this.state.previousStates[this.props.project_id].isSelectionChanged,
			});
		}
		// Continue with your data fetching and state updating logic
	}

	setCheckedItems = (childData) => {
		this.setState({checked_items: childData})
	}

	setCheckedNodes = (childData) => {
		this.setState({checkedNodes: childData})
	}

	callbackFunction = (childData) => {
		
		//convert to JSON
		//console.log(childData.hits)  // for debugging  Display Contents of Hits
		//console.log("Json check for 1: ", hits.employees[1].firstName)  //for debugging

		//convert to json object here.  It fails other places.
		//console.log("Error location:", childData.hits[316])
		//var json_obj = JSON.parse(childData.hits.replace(/\u'|'/ig, '"'))  //testing
		//var json_obj = JSON.parse(childData.hits)  //if the value is not in json format.
		//var json_obj = childData.hits['hits']  //this value should already be in json format.  Pass hits since its the array
		//console.log("Testing Json Obj:", json_obj)  //show what we are passing.

		//may need to convert json to array.
		//See: https://stackoverflow.com/questions/44309300/iterating-over-json-in-react
		//example: return <ul>{arr.map(item => <MyAppChild key={item.label} label={item.label} value={item.value} />)}</ul>;
		var arr = [];

		//console.log("query_state:" + childData.query + " db_type: " + childData.index)
		
		this.setState({
			//hits: json_obj.employees,  //pass array here because .maps will have a problem otherwise.
			hits:childData.hits['hits']['hits'],
			total:childData.hits["hits"]["total"]["value"],
			from:0,
			//search_query: childData.query, // Assuming the query is returned as 'query' property
			//db_type: childData.index, // Assuming the index is returned as 'index' property
			isLoading:childData.isLoading
		})
	}

	callbackSetQueryNdb_type = (tmpQuery, tmpDB_Type) => {
		console.log(tmpQuery, tmpDB_Type)
		this.setState({
			search_query:tmpQuery,
			db_type:tmpDB_Type
		})
	}

	callbackSetNext = (childData) => {
		
		//convert to JSON
		//console.log(childData.hits)  // for debugging  Display Contents of Hits
		//console.log("Json check for 1: ", hits.employees[1].firstName)  //for debugging
		var arr = [];
		
		this.setState({
			//hits: json_obj.employees,  //pass array here because .maps will have a problem otherwise.
			hits:childData.hits['hits']['hits'],
			total:childData.hits["hits"]["total"]["value"],
			from: this.state.from + this.state.length,
			isLoading:childData.isLoading
		})
	}

	setSearchQuery = (searchQuery) => {
		this.setState({ search_query: searchQuery });
	  }
	
	setDBType = (dbType) => {
		this.setState({ db_type: dbType });
	  }

	toggleSidebar = () => {
		this.setState(prevState => ({
			isSidebarOpen: !prevState.isSidebarOpen
		}), () => {
			console.log("toggleSidebar:", this.state.isSidebarOpen);
		});
	};

	setSelectionChanged = (ChangeValue) => {
		this.setState({isSelectionChanged: ChangeValue}, () => {
			console.log("isSelectionChanged:", this.state.isSelectionChanged); //for debugging
		});
	};
	


	//Used to set loading state.
	callbackSetLoading = (loadingState) => {
		
		this.setState({
			//hits: json_obj.employees,  //pass array here because .maps will have a problem otherwise.
			isLoading:loadingState
		})
	}

	render() {

		return(
			// <div className={classes.root}>
			<div>
				{
					//Search_Bar will accept the query from the user and set the hits data.
					//Will need to receive the hits data from the Search_Bar child.
					//This is done via a callbackFunction
				}
				<SearchBar 
					token = {this.props.token} 
					setToken={this.props.setToken} 
					parentCallback = {this.callbackFunction} 
					parentCallbackSetLoading = {this.callbackSetLoading} 
					project_id={this.props.project_id} 
					matter_id={this.props.matter_id}
					callbackSetQueryNdb_type= {this.callbackSetQueryNdb_type}
					SearchQuery = {this.state.search_query}
					setSearchQuery = {this.setSearchQuery}
					DBType={this.state.db_type}
					setDBType={this.setDBType}
					onMenuClick={this.toggleSidebar}
					CheckedItems={this.state.checked_items}
					isSelectionChanged={this.state.isSelectionChanged}
				/>
				{
					//Will accept the hits data and render teh page.
				}
				<Results_Box 
					token = {this.props.token} 
					setToken={this.props.setToken} 
					hits = {this.state.hits} 
					isLoading = {this.state.isLoading} 
					total = {this.state.total} 
					from = {this.state.from} 
					length = {this.state.length} 
					project_id={this.props.project_id} 
					matter_id={this.props.matter_id}
					search_query={this.state.search_query}
					db_type={this.state.db_type}
					callbackSetNext = {this.callbackSetNext} 
					parentCallbackSetLoading = {this.callbackSetLoading}
				/>
				{
					//maybe add Paragraph View Modal here.
				}
				<Drawer
					variant="persistent"
					anchor="left"
					open={this.state.isSidebarOpen}
					sx={{
						'& .MuiDrawer-paper': {
							// Apply a box shadow for elevation effect
							boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1), 0px 4px 6px -2px rgba(0,0,0,0.05)',
						},
						width: drawerWidth,
						flexShrink: 0,
						'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
						marginTop: `${appBarHeight * 2}px`, // Twice the height of AppBar
						height: `calc(100% - ${appBarHeight * 2}px)`, // Adjust height
						},
						// ... any additional custom styles ...
					}}
					>
					<Typography variant="h6" gutterBottom sx={{ padding: 2 }}>
						{/* Your sidebar content */}
					</Typography>
					<Grid container>
						<Grid item xs={12}>
						<Box>
							<DocumentsSection 
								data={this.state.folders_data} 
								checkedItems={this.state.checked_items}
								setCheckedItems={this.setCheckedItems} 
								checkedNodes={this.state.checkedNodes}
								setCheckedNodes={this.setCheckedNodes}
								isSelectionChanged={this.isSelectionChanged} 
								setSelectionChanged={this.setSelectionChanged} />
							{/* ... other content ... */}
						</Box>
						</Grid>
					</Grid>
				</Drawer>

				<Grid 
					container 
					//className={styles.content} 
				>
					<Grid item xs={12}>
					<Toolbar />
						<Box>
						{/* <PushpinButton/><CopyButton text="THIS IS COPY TIME!"/>
						<Button onClick={() => props.handleSetOptionClick(options)}>
							Test Bar Change
						</Button> */}
						</Box>
						<Box >
						{/*Put stuff here*/}
						</Box>
					</Grid>
				</Grid>	
			</div>
		);	
	}
}

export default Search_Page
import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

const items = [
  { id: '1', content: '^', description: 'Matches the start of the string.' },
  { id: '2', content: '$', description: 'Matches the end of the string.' },
  { id: '3', content: '()', description: 'Scopes a part of the pattern and usually combined with a quantifier.' },
  // More options...
];

function RegexConfig() {
  const [list, setList] = useState(items);
  const [regex, setRegex] = useState([]);

  function handleDragEnd(result) {
    if (!result.destination) return;

    const sourceId = result.source.droppableId;
    const destinationId = result.destination.droppableId;

    if (sourceId === destinationId) {
      const itemsCopy = Array.from(sourceId === 'list' ? list : regex);
      const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
      itemsCopy.splice(result.destination.index, 0, reorderedItem);

      if (sourceId === 'list') {
        setList(itemsCopy);
      } else {
        setRegex(itemsCopy);
      }
    } else if (destinationId === "regex") {
      const itemsCopy = Array.from(list);
      const [movedItem] = itemsCopy.splice(result.source.index, 1);
      const regexCopy = Array.from(regex);
      regexCopy.splice(result.destination.index, 0, movedItem);
      setList(itemsCopy);
      setRegex(regexCopy);
    }
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <Box {...provided.droppableProps} ref={provided.innerRef} sx={{ display: 'flex', overflowX: 'hidden', maxWidth: '100%' }}>
            <Typography variant="h5">Available Regex Items</Typography>
            {list.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided) => (
                  <Tooltip title={item.description} arrow>
                    <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} sx={{ margin: '0 10px' }}>
                      <Typography variant="h6">{item.content}</Typography>
                    </Box>
                  </Tooltip>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
      <Droppable droppableId="regex">
        {(provided) => (
          <Box {...provided.droppableProps} ref={provided.innerRef} sx={{ display: 'flex', overflowX: 'hidden', maxWidth: '100%' }}>
            <Typography variant="h5">Current Regex</Typography>
            {regex.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided) => (
                  <Tooltip title={item.description} arrow>
                    <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} sx={{ margin: '0 10px' }}>
                      <Typography variant="h6">{item.content}</Typography>
                    </Box>
                  </Tooltip>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default RegexConfig;

import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import SearchButton from './SearchButton';
import SorterButton from './SorterButton';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import FileView from './00_FileView';
import SorterSpeedDial from './SorterButton';

export default function DocumentsSection({ data, checkedItems, setCheckedItems, isSelectionChanged, checkedNodes, setCheckedNodes, setSelectionChanged}) {
  //console.log("DocumentSection Received Data: ", data)

  const [persistent_data, setPersistentData] = useState(data)

  const [originalExpandedPaths, setOriginalExpandedPaths] = useState([]); //for maintaining the original expanded paths.
  //const [checkedItems, setCheckedItemsState] = useState([]);  //for handling items that are checked during the search.

  const [expandedPaths, setExpandedPaths] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [fileViewVisible, setFileViewVisible] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [isSorted, setIsSorted] = useState(false);
  const [sortOrder, setSortOrder] = useState('none');

  const handleClearSearch = () => {
    setSearchTerm('');  // Clear the search term
    setExpandedPaths(originalExpandedPaths);  // Reset expanded paths to original
    setPersistentData(data);  // Reset to original data
  };

//This was done to allow passing to parent so that it can be passed to the searchbar.
// const setCheckedItems = (CheckedItems) =>
// {
//   setCheckedItemsState(CheckedItems)
//   setCheckedItemsData(CheckedItems)
//   console.log("Setting CheckedItems: ", CheckedItems)
// }

 const clearSearchTerm = () => {
    setSearchTerm('');  // clear the search term
  };

const toggleSort = () => {
    setSortOrder(prevOrder => {
        switch (prevOrder) {
            case 'none':
                return 'asc';
            case 'asc':
                return 'desc';
            case 'desc':
                return 'none'; 
            default:
                return 'none';
        }
    });
};
  const [previousSearchTerm, setPreviousSearchTerm] = useState('');

  const handleSearchClick = () => {
    if (searchActive && searchTerm) {
      // Save the current search term as the previous search term
      setPreviousSearchTerm(searchTerm);
      handleClearSearch();  // Clear the search term and reset state
    } else if (previousSearchTerm) {
      // If there's a previous search term, apply it and start searching
      setSearchTerm(previousSearchTerm);
    }
    setSearchActive(!searchActive);  // Toggle the search active state
};


  const handleSort = (order) => {
    setSortOrder(order);
};

  const handleSearchTermChange = (newSearchTerm) => {
    // If starting a search, store the current expanded paths
    if (!searchTerm && newSearchTerm) {
      
      setOriginalExpandedPaths(expandedPaths);
    }
    setSearchTerm(newSearchTerm);
  };
  
  //
  const filterdata = filterData(persistent_data, searchTerm);
  
  
 useEffect(() => {
    if (searchTerm) {
      // If a search term is set, store the current expanded paths as the original
      setOriginalExpandedPaths(expandedPaths);
    } else {
      // If the search term is cleared, revert to the original expanded paths
      setExpandedPaths(originalExpandedPaths);
      setPersistentData(data);  // Reset to original data
    }
}, [searchTerm, data, expandedPaths, originalExpandedPaths]);

  
  //To sync up the checked objects with the main object.
  //NOT FUNCTIONAL -- 
  const updateCheckedStateInMainData = (checkedItems) => {
        const updateCheckedStateInData = (node) => {
            const newNode = { ...node };

            newNode.checked = checkedItems.includes(newNode.id);

            if (newNode.children) {
                newNode.children = newNode.children.map(child => updateCheckedStateInData(child));
            }

            return newNode;
        };

        const newData = updateCheckedStateInData(persistent_data);
        setPersistentData(newData);
        console.log("Updating persistent data");
    };

    useEffect(() => {
        updateCheckedStateInMainData(checkedItems);
    }, [checkedItems]);

const sortData = (data, order) => {
    if (!data.children) return data;
    const sortedChildren = [...data.children].sort((a, b) => {
        if (order === 'asc') {
            return a.name.localeCompare(b.name);
        } else if (order === 'desc') {
            return b.name.localeCompare(a.name);
        } else {
            return 0; // no sorting for 'none'
        }
    });
    return {
        ...data,
        children: sortedChildren.map(child => sortData(child, order))
    };
};
  //rendering loop
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <div style={{ display: 'flex', alignItems: 'center' }}>
             <SearchButton 
              searchActive={searchActive} 
              handleSearchClick={handleSearchClick} 
              handleSearchTermChange={handleSearchTermChange}
              handleClearSearch={handleClearSearch}  
              clearSearchTerm={clearSearchTerm}
          />
           <SorterButton onSort={handleSort} sortOrder={sortOrder} />

          </div>
          <div style={{ flexGrow: 1}} /> {/* This acts as a spacer */}
          <ArrowForwardIosSharpIcon 
            expanded={expanded}
            onClick={() => {
              setFileViewVisible(!fileViewVisible);
              setExpanded(!expanded);
            }}
            style={{
              color: 'white',
              transform: expanded ? 'rotate(-90deg)' : 'rotate(90deg)',
              fontSize: '14px'
            }} 
          />
        </Toolbar>
      </AppBar>
      <div style={{
        transition: 'opacity 1s, max-height 1s',
        maxHeight: fileViewVisible ? '1000px' : '0',
        overflow: 'hidden',
      }}>

        {/* Display search data, verses display regular data. */}
       <FileView 
            data={sortOrder !== 'none' ? sortData(filterdata, sortOrder) : filterdata} 
            expandedPaths={expandedPaths} 
            searchIsActive={searchActive}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            isSelectionChanged={isSelectionChanged}  //from Parent
            setSelectionChanged={setSelectionChanged} //from Parent
            checkedNodes={checkedNodes}
            setCheckedNodes={setCheckedNodes}
        />
      </div>
    </Box>
  );
}


//functions
const resetExpanded = (data) => {
  const newData = { ...data };

  if (newData.expanded) {
    newData.expanded = false;
  }
  
  if (newData.children) {
    newData.children = newData.children.map(child => resetExpanded(child));
  }
  
  return newData;
}

const filterData = (data, searchTerm) => {
  // If there's no search term, return the original data
  let not_find = true;
  if (!searchTerm) return data;

  const lowerCaseSearchTerm = searchTerm.toLowerCase();
  const searchResults = [];

  // Recursive function to search nodes and their children
  const searchNodes = (node) => {
      if (node.name.toLowerCase().includes(lowerCaseSearchTerm)) {
          // If the node's name matches the search term, add it as a top-level item
          not_find = false;
          searchResults.push({ ...node });
      }
      if (node.children) {
          for (const child of node.children) {
              searchNodes(child); // Recursively search children
          }
      }
  };

  searchNodes(data); // Start the search
  if (not_find){
    return null;
  }
  return { ...data, children: searchResults };
}


function getPathsForNodesWithChildren(data, path = []) {
    let paths = [];
    
    const newPath = [...path, data.name];

    // Add the path if the node has children
    if (data.children) {
        paths.push(newPath.join('/'));
        for (let child of data.children) {
            paths = paths.concat(getPathsForNodesWithChildren(child, newPath));
        }
    }

    return paths;
}
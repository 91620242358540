import React, { Component } from 'react';
import PDF_View_Sidebar from './02_pdf_view_sidebar';
import PDF_Viewer from './02_pdfviewer';

//import PDFJSBackend from './backends/pdfjs';  //testing; didn't work
//import IframeComponent from './IframeComponent'; //testing

//Parent to 02_pdf_view_sidebar.js and 02_pdfviewer.js

//These components shall work together to allow a method to interact with the pdf.
//These might all need to be expanded to a navigation bar, side bar and view column that allows multiple functionalities to traverse the entire program.

//where should we direct the query.
//const API = 'http://127.0.0.1:9999/search_documents';  //local testing


class PDF_View_Page extends Component {

	constructor(props) {
		super(props)
	
		this.state = {
			//These will be shared among the Child components.
			pdfURL: '',
            isLoading: true
		}
	}

	finishedLoading = () => {
		this.setState({
		  loading: false
		});
	  };

	callbackFunction = (childData) => {
		
		//convert to JSON
		
		this.setState({
			//hits: json_obj.employees,  //pass array here because .maps will have a problem otherwise.
			pdfURL:childData.pdfURL,
			pageNumber:childData.pageNumber
		})
		//alert(childData.pdfURL)
	}

	render() {
		
		return(
			<div className="PDF_View_Page">
				{
					//Search_Bar will accept the query from the user and set the hits data.
					//Will need to receive the hits data from the Search_Bar child.
					//This is done via a callbackFunction
				}
				<PDF_View_Sidebar 
					parentCallback = {this.callbackFunction}
					docid="1"
				/>
				{/*
					//Will accept the hits data and render teh page.
				*/}
				
				<PDF_Viewer 
					src = {this.state.pdfURL}
					pageNumber = {this.state.pageNumber}
				/>				
				
			</div>
		);	
	}
}

export default PDF_View_Page
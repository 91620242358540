import { Container } from '@mui/material'
import React, { Component } from 'react'
import SRNoteCard from './ResultCard/SR_NoteCard'

import {Typography} from '@mui/material';
import Button from "@mui/material/Button";
import SearchTermChips from './SearchTermChips';

// Note: 5/35/20
//having an issue with map below.
//Next step is to learn how to parse json objects.


//next step: keep search and results window seperate.
//See: https://www.pluralsight.com/guides/react-redux-reusable-components

const API = '/search.api';  //Prod  Check


class Results_Box extends Component {

	constructor(props) {
		super(props)
	
		this.state = {
			//Dev Note:  These will need to be linked so when other component updates.  They update and force render.
			 hits: []
		}
	}
	
	//Dev Note: this will need to be changed to refresh when hits value is received.

	handleNextClick = (event) => {
		event.preventDefault()
		this.props.parentCallbackSetLoading(true);
		console.log("search check (NextCall):", this.props.project_id, this.props.matter_id)
		console.log("checking token (NextCall):", this.props.token)

		fetch(API, {
			method: 'POST',
			mode: 'cors',
			body: JSON.stringify({
				search_query: this.props.search_query,
				db_type: this.props.db_type,
				project_id: this.props.project_id,
				matter_id: this.props.matter_id,
				from: this.props.from + this.props.length
			}),	
			headers: new Headers({
				//'Accept': 'application/json; charset=utf-8',
				'Authorization': 'Bearer ' + this.props.token,
				'Content-Type': 'application/json',
				//'Access-Control-Allow-Origin': '*',
			})
		})
		//.then(response => response.text())          // convert to plain text
		//.then(text => console.log("fetch data: ",text))  // then log it out
		.then(response=> {
			return response.json() //convert to json.  return passes it
		})  // Prod
		//.then(data => console.log("fetch data: ",data))  //For Debugging
		.then((ResponseData) => {
      		console.log("Response (NextCall):", ResponseData)
			

			//check to see if a new access_token
			//console.log("new token: ", data)
			if(ResponseData.access_token !== undefined){
				console.log("setting new token")
				this.props.setToken(ResponseData.access_token) //if refresh token present. Set it.
			}

			if(ResponseData.results !== undefined){
				console.log("setting results (NextCall):", ResponseData.results)
				this.props.callbackSetNext({ hits: ResponseData.results, isLoading: false });  //send to Parent Search_Page  Handle JSON conversion as parent level?  PROD
				//set isLoading to false.
			  }
			}
		);  //set data to results
	}


	render() {
		//const { hits } = this.state;
		//console.log(hits)
		//if (isLoading) {
		//	return <p>Loading ...</p>;
		//}
		if (this.props.isLoading == false) {
			if (this.props.hits !== undefined) {
				//var arr = [];
				console.log("JSON Object breakdown: ", this.props.hits)  // for debugging			

				//this.props.hits = JSON.parse(this.props.hits)

				return(
					<div>
						
						<div className="resultsbox">	 
								{
								//https://stackoverflow.com/questions/52428879/objects-are-not-valid-as-a-react-child-if-you-meant-to-render-a-collection-of-c
								//use .map() for an array
								//hits.map(data =>
								//Can use data.[key] or data.key to access
								//need to send with )} see other Forms.bak.js for reference.
								
								//Working with JSON and Map
								//See: https://stackoverflow.com/questions/48250294/rendering-json-api-in-react-using-map-method

								//Working with JSON without Map
								//See: https://www.w3schools.com/js/js_json_objects.asp
								//See: https://www.w3schools.com/js/js_json_arrays.asp
								
								//Will need to pull values from the received data and place into here.
								//Will need to copy structure
								
								//Should move style to a css that gets imported for this page.

								}
								{this.props.from + this.props.length < this.props.total ? (
									<Typography variant="body1">Displaying {this.props.from + 1}-{this.props.from + this.props.length} of {this.props.total}:</Typography>
									) : (
									<Typography variant="body1">Displaying {this.props.from + 1}-{this.props.total} of {this.props.total}:</Typography>
								)}


								{/* Displays a Next Button when there are additional results that can be pulled */}
								{this.props.from + this.props.length < this.props.total ? (
									<Button onClick={this.handleNextClick}>
										<Typography variant="body1"> Next</Typography>
									</Button>
									
									): (
										//do nothing
										<></>
									) 
								}

								
								
								<Container sx={{bgcolor: '#E5E4E2'}}>
									<SearchTermChips searchQuery={this.props.search_query}/>
									{this.props.hits.length > 0 ? (
										this.props.hits.map(hit => <SRNoteCard 
																		key={hit.objectID} 
																		hit={hit}
																		token = {this.props.token} 
																		setToken={this.props.setToken}
																		searchQuery={this.props.search_query}
																	/>)
										) : (
										<Typography variant="body1">No Results Found</Typography>
									)}
								</Container>
						
						</div>
						{/* Add Paragraph Model here? */}
					</div>
				);	
			}
		} else {
			return(
				<div>
					<p> Please enter a search... </p>
				</div>
			);
		}
	}
}

export default Results_Box
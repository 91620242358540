import React from 'react';
import { styled } from '@mui/material/styles';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import {GroupCheckBox} from './GroupCheckBox';
import {ItemCheckBox} from './ItemCheckBox';

const StyledTreeItem = styled((props) => {
  const { onCheckChange, checked, disabled, label, checkboxType, ...other } = props;
  const CheckboxComponent = checkboxType === 'group' ? GroupCheckBox : ItemCheckBox;

  // Disable the checkbox if it's in the 'DisabledProcessing' state
  const isDisabled = disabled || checked === 'DisabledProcessing';

  // Log the 'checked' prop here
  //console.log(`'checked' prop passed to GroupCheckBox in StyledTreeItem (${label}):`, checked);

  return (
    <TreeItem 
      {...other} 
      label={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CheckboxComponent title={label} checked={checked} disabled={isDisabled} onCheckChange={onCheckChange} />
          <span>{label}</span>
        </div>
      }
    >
      {props.children}
    </TreeItem>
  );
})(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    padding: '5px 0',  // Adjusts the padding between the checkbox and the title
    alignItems: 'center',
  },
}));

export { StyledTreeItem };

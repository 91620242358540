import React, { Component } from "react";
import "./06_screenfill_modal_styles.css"

/*

Notes:

How to position model in middel of screen (yet to be tested)
See: https://stackoverflow.com/questions/29664622/css-for-modal-how-to-position-it-above-page-yet-scrollable-in-window

*/


class ScreenfillModalObject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal:"",
      btn:"",
      span:"",
      ModalMessage:"",
      message: "",
      //DO NOT ASSIGN PROPS HERE.  THEY WILL ONLY ASSIGN ON FIRST PASS.
    };
  }

  initilize() {

    // Get the modal      
    const modal = this.state.modal
    const btn = this.state.btn
    const span = this.state.span

    modal = document.getElementById("myModal");
    this.setState({modal})

    btn = document.getElementById("myBtn");
    this.setState({btn})

    span = document.getElementsByClassName("close")[0];
    this.setState({span})
}

handleClose = (e) => {
  e.preventDefault();
  //place a check to see whether the processing has been completing to allow close.
  //otherwise prevent close.
  if (this.props.processing_complete == true){
    this.state.message = "";
    this.props.updateProcessing(false);
    this.props.hideFullModal();
    this.props.updateDocID("");
  }
  else {
      alert("Please wait while processing completes.");
      //alert("Props DocID: ", this.props.doc_id);  //alerts don't work to show props?
      console.log("Document ID: ", this.props.doc_id);
  }
  
  //FOR DEBUG.
  // if(this.state.processing_complete==false) {
    
  //   setTimeout(function(){
  //     this.setState({processing_complete:true});
  //   }.bind(this),5000);  // wait 5 seconds, then reset to false
  // }
}

content() {
  if (this.props.processing_complete == true){
    this.state.message = <p>The merge has been completed!  Document ID: {this.props.doc_id}</p>
  }
  else {
    this.state.message = <p>Your request is being processed... </p>
  }
}

  render() {
    //if show is false don't show anything
   if (!this.props.show){
     return null;
   }
    return (
      <div className="screenfill-modal">
        <div className="screenfill-modal-content">
          <div className="screenfill-modal-header">
            <span className="screenfill-close" onClick={this.handleClose}>&times;</span>
            <h2>Creating Document:</h2>
          </div>
          <div className="screenfill-modal-body">
            {this.content()}
            {this.state.message}         
            <p>This window will update when the merger completes.</p>
            <button className="secondary" onClick={this.handleClose}>Close</button>
            </div>
            <div className="screenfill-modal_image">
          </div>
          </div>
        </div>
    );
  }
}

export default ScreenfillModalObject;
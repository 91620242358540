import React, { useState } from 'react';
import { Grid, Typography, InputBase } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import MyBox from './MyBox';

function SomeComponent({handleClose}) {
  const theme = useTheme();
  const [boxes, setBoxes] = useState([...Array(6).keys()]); // Initialize with 6 boxes

  // Handler for removing boxes
  const handleRemoveBox = (index) => {
    const newBoxes = boxes.filter((_, boxIndex) => boxIndex !== index);
    setBoxes(newBoxes);
  };

  return (
    <div style={{ 
      width: '90vw', 
      minHeight: '90vh',
      maxHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#f2f2f2',
      overflowY: 'auto'
    }}>
      <div style={{ 
            display: 'flex',
            justifyContent: 'flex-start',
            padding: '10px',
            position: 'absolute', 
            top: 0,
            left: 0,
            zIndex: 1001
          }}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>

      {/* Primary Header */}
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        backgroundColor: theme.palette.primary.main, 
        padding: '15px', 
        color: 'white',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      }}>
        <Typography variant="h6">Sample Header Text</Typography>
      </div>

      {/* Search Box */}
      <div style={{ 
        display: 'flex', 
        alignItems: 'center',
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '5px',
        margin: '30px auto', 
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        width: '60%',
      }}>
        <SearchIcon style={{ marginRight: '8px' }} />
        <InputBase 
          placeholder="Search…" 
          style={{ flex: 1 }}
        />
      </div>

      {/* Content */}
      <Grid container style={{ flexGrow: 1, padding: '20px' }}>
        {/* Left column for "Recently Viewed" */}
        <Grid item xs={12} md={2} style={{ flexGrow: 0, flexBasis: '20%' }}>
          <div style={{ paddingTop: '20px', paddingLeft: '20px' }}>
            <Link to="/" onClick={() => { console.log('Navigating to home...'); }}>
              <Typography variant="h6">Recently Viewed</Typography>
            </Link>
          </div>
        </Grid>

        {/* Right column for boxes */}
        <Grid item xs={12} md={10} style={{ flexGrow: 1, flexBasis: '80%' }}>
          <div style={{ padding: '20px 20px 20px' }}>
            <Grid container spacing={2}>
              {/* Responsive Boxes */}
              {boxes.map((_, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <MyBox onRemove={() => handleRemoveBox(index)} /> {/* Pass the click handler */}
                </Grid>
              ))}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default SomeComponent;

// AddButton.js
import React from 'react';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

function AddButton({ onClick }) {
  return (
    <IconButton color="inherit" onClick={onClick}>
      <AddIcon />
    </IconButton>
  );
}

export default AddButton;

// Copyright © 2024 Ulomate LLC. All rights reserved.
// This source code is proprietary and may not be used, copied, distributed 
// or shared without the express written consent of Ulomate LLC.

import React from 'react';
import { Typography } from '@mui/material';

function HighlightText({ text, searchWords, colors }) {

  console.log(searchWords)

   // Function to escape special characters in regex
   const escapeRegexCharacters = str => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  // Function to extract and clean search words
  const prepareSearchWords = searchWordsString => {
    // Match quoted phrases with single or double quotes or individual words
    const matches = searchWordsString.match(/"([^"]+)"|'([^']+)'|\b(\w+)\b/g) || [];

    return matches.map(word => {
      if ((word.startsWith('"') && word.endsWith('"')) || (word.startsWith("'") && word.endsWith("'"))) {
        // Remove quotes for exact phrases and do not remove special characters
        return word.substring(1, word.length - 1);
      } else {
        // Remove special characters for individual words and convert to lowercase
        return word.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
      }
    }).filter(word => word !== 'and' && word !== 'or');
  };

  // Prepare the search words array
  const preparedSearchWords = prepareSearchWords(searchWords);

  // Function to highlight text
  function highlightText(text, searchWordsArray) {
    // Create a regex pattern to match all search words and phrases
    const regexParts = searchWordsArray.map(word => {
      return `(${escapeRegexCharacters(word)})`;
    });
    const regexPattern = regexParts.join("|");
    const regex = new RegExp(regexPattern, 'gi');

    // Split the text into parts and map over them to create Typography elements
    const parts = text.split(regex);
    return parts.filter(part => part).map((part, index) => {
      const wordIndex = searchWordsArray.findIndex(searchWord => 
        searchWord.toLowerCase() === part.toLowerCase()
      );
      const isHighlighted = wordIndex !== -1;

      if (isHighlighted) {
        const color = colors[wordIndex % colors.length];
        return (
          <Typography
            component="span"
            key={index}
            sx={{
              backgroundColor: color,
              display: 'inline'
            }}
          >
            {part}
          </Typography>
        );
      } else {
        return (
          <Typography
            component="span"
            key={index}
            sx={{
              display: 'inline'
            }}
          >
            {part}
          </Typography>
        );
      }
    });
  }

  // Call the highlightText function with the prepared search words
  const highlightedText = highlightText(text, preparedSearchWords);

  return (
    <Typography component="div" sx={{ whiteSpace: 'pre-wrap' }}>
      {highlightedText}
    </Typography>
  );
}

export default HighlightText;

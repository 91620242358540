import React, { Component } from 'react'
import {BrowserRouter, Navigate, redirect, Route, Routes} from "react-router-dom"

import './App.css'
import './drag_n_drop_list.css'
import './AnnotationLayer.css'

/* Pages */
import Search_Page from './components/Search/search_page'
import PDF_View_Page from './components/02_pdf_view_page'
import Main_Page from './components/03_DocumentLister.js'
import DND_List from './components/PDFMerger/04_drag_and_drop_list'
import DocumentMergePage from './components/PDFMerger/05_document_merge_page'
import File_Uploader from './components/07_file_uploader'
import Navigation_Bar from './components/08_navbar'
import Topics_Page from './components/09_topics_page'
import DocumentAnnotatorPage from './components/ReviewNAnnotate/05_document_annotator_page'
import Dashboard_Matters from './components/12_Dashboard_Matters'
import AnalyzerPage from './components/Analyzer/AnalyzerPage'
import LabelsAndSuggestions from './components/LabelNSuggestion/LabelNSuggestion_Manager'

/*Parts*/
import ResponsiveAppBar from './components/ResponsiveAppBar'
import PersistentDrawerLeft from './components/Matters and Project Dashboards/Sidebar/PersistentDrawerLeft'

/* MUI */
import { makeStyles } from '@mui/styles'
import Drawer from '@mui/material/Drawer'
import { Typography } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { ThemeContext } from '@emotion/react'
import zIndex from '@mui/material/styles/zIndex'
import { Box } from '@mui/system'

/*Authentication*/
import Login from './components/login/11_Login'
//import Profile from './components/login/11_Profile'
import Header from './components/login/11_Header'
import Register from './components/login/11_Register'
import jwt_decode from "jwt-decode";

/*testing remove from final*/
import Testing from './components/testing/00_labelbutton'

import Demo from "./demo";
import { Token } from '@mui/icons-material'


//import Layout from './Layout'

// Input previous App.js, which uses States, into this.

//NEED TO BUILD IN CODE THAT DOESN'T LOAD PAGE IF DATA IS NOT RECEIVED.

const drawerWidth = 200

const API = '/nav';  //Prod  Check
//for authentication.
//const { token, removeToken, setToken } = useToken();

class App extends Component {
	constructor(props) {
		super(props)
		this.state = {
			navigation_choice: "Main",
			token: undefined,
			matter_id: null,
			project_id: null,
			task_id: undefined,
			projectsList: [],
			optionChoice: null,
			docid: undefined,
		}
	}	

	MenuChoice = (a_value) => {
		//alert(a_value)
		this.setState({navigation_choice: a_value})
		//this.props.parentCallback({pdfURL:a_value});
	}

	//for analyzer testing.
	setDocID = (a_value) => {
		//alert(a_value)
		this.setState({docid: a_value})
		//this.props.parentCallback({pdfURL:a_value});
	}

	//handle items when component loads.  made async to perserve order
	componentDidMount = async () => {
		//XXXXXXX
		//THIS CAUSES ISSUES WITH THE NAVIGATION BAR.  IT DOESN'T REFRESH THE DATA BECAUSE THIS DOESN'T CALL AGAIN.  WILL NEED TO FIX.
		//XXXXXXX

		await this.getToken() //needed await to lock the order of events so token is grabbed before getNav is called.  needed async to use await
		//const userToken = localStorage.getItem('token'); //need to set user token here because otherwise it fails.  //removed to see if await fixed this.
		
		// Check immediately upon mounting
		await this.checkTokenExpiration();
		// Set up an interval to check every 30 minutes

		//Add check on status before running get nav and interval setting.  If undefined don't set the interval or run get nav.

		this.tokenCheckInterval = setInterval(this.checkTokenExpiration, 30 * 60 * 1000);

		this.getNav()

	}

	//handle component unmounting
	componentWillUnmount() {
        // Clear the interval when the component unmounts
        clearInterval(this.tokenCheckInterval);
    }

	checkTokenExpiration = async () => {
        console.log("Checking for expired token.");

        if (this.state.token && this.isTokenExpired(this.state.token)) {
            console.log("Token is expired. Removing Token.");
            localStorage.removeItem('token');  // Remove from local storage.
            this.setToken(undefined); // If expired, remove token from state
        }
    }

	//get Nav Bar files.
	getNav = async () => {
		console.log("Token before getNav fetch:", this.state.token);

		fetch(API, {
			method: 'POST',
			mode: 'cors', 
			// body: JSON.stringify({
			// 	//search_query: this.state.search_query
			// 	msg: "request nav"
			// }),	
			headers: new Headers({
				//'Accept': 'application/json; charset=utf-8',
				'Authorization': 'Bearer ' + this.state.token,
				'Content-Type': 'application/json',
				//'Access-Control-Allow-Origin': '*',
			})
		})
		//.then(response => response.text())          // convert to plain text
		//.then(text => console.log("fetch data: ",text))  // then log it out
		.then(response=> {

			if (!response.ok) {
				return response.json().then(error => {
					throw new Error(error.message);
				});
			}
			
			return response.json() //convert to json.  return passes it

		})  // Prod
		//.then(data => console.log("fetch data: ",data))  //For Debugging
		.then((data) => {
			
			console.log("App data:", data)
			//check to see if a new access_token
			//console.log("new token: ", data)
			if(data.access_token !== undefined){
				console.log("setting new token")
				this.setToken(data.access_token) //if refresh token present. Set it.
			}

			if(data.projects_list !== undefined){
				console.log("setting project list ", typeof data.projects_list)
				var proj_tmp = JSON.parse(data.projects_list)
				console.log("project json:", typeof proj_tmp)
				this.setState({projectsList: proj_tmp})
			}
		})  //set data to results
		.catch(error => {
			console.error("There was an error:", error);
		}) //end of catch
	}

	/*  REMOVED BECAUSE INTERGRATED REACT
	renderSwitch = () => {
		//alert("menu:" + this.state.navigation_choice)
		switch(this.state.navigation_choice) {
		  case 'Main':
			return <Main_Page/>;
		  case 'Search':
			return <Search_Page/>;
		  case 'Upload':
			return <File_Uploader/>;
		  case 'Merge':
			return <DocumentMergePage/>;
		  case 'Topics':
			return <Topics_Page/>;
		  case 'Annotator':
			return <DocumentAnnotatorPage/>;
		  default:
			return <Main_Page/>;

		//<DND_List colors={["Red", "Green", "Blue", "Yellow", "Black", "White", "Orange"]} />  //Not able to pull list contents. Might not be useful.
		}
	  }
	*/
	
	// ---- For User Login --- START
	getToken = () => {
		const userToken = localStorage.getItem('token');
		console.log("found: ", userToken)
		this.setState({token: userToken})
	}
	
	setToken = (token_value) => {
		console.log("set value:", token_value)
		if(token_value && token_value!==""){
			console.log("token detected")
			localStorage.setItem('token', token_value)
			this.setState({token: token_value})
		}	
	}

	isTokenExpired(token) {
		const decoded = jwt_decode(token);
		const currentTime = Date.now() / 1000;
		return decoded.exp < currentTime;
	}

	setProjectsList = (list_value) => {
		console.log("set value:", list_value)
		//allow undefined
		if(list_value && list_value!==""){
			console.log("project list detected")
			//localStorage.setItem('token', token_value) //local storage
			this.setState({projectsList: list_value})
		}	
	}

	setMatterNProject = (tmp_Matter_ID, tmp_Project_ID) => {
		console.log("set M&P values:", tmp_Matter_ID," ", tmp_Project_ID)
		//if(tmp_Project_ID && tmp_Project_ID!==""){
			console.log("Project ID:", tmp_Project_ID)
			//localStorage.setItem('token', token_value) //local storage
			//this.setState({project_id:tmp_Project_ID})
		//}

		//if(tmp_Matter_ID && tmp_Matter_ID!==""){
			console.log("Matter ID:", tmp_Matter_ID)
			//localStorage.setItem('token', token_value) //local storage
			//this.setState({matter_id: tmp_Matter_ID})
		//}

		this.setState({project_id:tmp_Project_ID, matter_id:tmp_Matter_ID})
	}

	handleSetOptionClick = (Matter_ID, Project_ID) => {
		console.log("Options Click:",typeof Matter_ID, Matter_ID, Project_ID, typeof Project_ID)
		//const Matter_ID = 1; // replace with the actual Matter_ID you want to search for
		//const Project_ID = 3; // replace with the actual Project_ID you want to search for

		if (!Matter_ID || !Project_ID) {
			this.setState({ optionChoice: null }, () => {
			  console.log("New value:", this.state.optionChoice);
			});
			return;
		}
		
		const option = this.state.projectsList.find((opt) => opt.Matter_ID === parseInt(Matter_ID) && opt.Project_ID === parseInt(Project_ID));
		console.log("projectList:", this.state.projectsList)
		console.log("option chosen:", option)
		
		//console.log(this.autocompleteRef.current)
		if (option) {
			this.setState({ optionChoice: option }, () => {
			  console.log("New value:", this.state.optionChoice);
			});
		}
	};

	
	removeToken = () => {
		localStorage.removeItem("token");
		this.setState({projectsList: []})
		this.setState({token: undefined})
	}

	// ---- For User Login --- END

	showSite = () => {
		return {



		}
	}

	Layout = () => {
		const classes = makeStyles((theme) => {
			return {
				page: {
					background: '#f9f9f9',
					width: '70%',
					position: "relative"
				},
				drawer: {
					//https://lzomedia.com/blog/position-material-ui-drawer-component-under-appbar-on-screen/
					//paddingTop: 20
				},
				drawerPaper: {
					//width: 240,
					//paddingTop: 50,
					zIndex: 1,
				},
				root: {
					//display: 'flex'
				},
				appbar: {
					zIndex: 0,
					//width: `calc(100% - ${drawerWidth}px)`,
				},
				/*  Need to add Toolbar: theme.mixins.toolbar*/
			}
		})
		
		return(
			
			<div className={classes.root}>
				{/*<p>Token: "{this.state.token}"</p>*/}
				<AppBar
					className={classes.appbar}
					elevation={0}
					position="sticky" //sticky vs. fixed.  sticky will make the bar stay on top and move the window down.  fixed with not move the window it just floats.
					sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
					style={{zIndex: 99}}
				>
					<ResponsiveAppBar MenuChoice={this.MenuChoice} handleSetOptionClick={this.handleSetOptionClick} optionChoice={this.state.optionChoice} removeToken={this.removeToken} projectsList = {this.state.projectsList} setMatterNProject={this.setMatterNProject} />
	
				</AppBar>
				{/* Set checks on which appbar to display */}

				{/* Testing persistent drawer */}
				{/* <PersistentDrawerLeft 
					getNav={this.getNav}
					handleSetOptionClick={this.handleSetOptionClick} 
					setMatterNProject={this.setMatterNProject} 
					token = {this.state.token} 
					setToken={this.setToken} 
					project_id={this.state.project_id} 
					matter_id={this.state.matter_id} 
					DocID={this.state.docid} 
					setDocID={this.setDocID}
				/>  */}
	
				{/* Set checks on which drawer to display
				https://smartdevpreneur.com/how-to-position-a-material-ui-drawer-inside-a-container/
				
				*/}
				
				
				<div className={classes.page}>
				{/*
					<Drawer
					//https://mui.com/components/drawers/
						className={classes.drawer}
						variant="persistent"
						//variant="temporary"
						anchor="left"
						sx={{
							width: drawerWidth,
							flexShrink: 0,
							[`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
						}}
						open={true}
						style={{position:'relative', zIndex: 1}}  //gets us some of the way there.  now need to move down and have it lock in the position.
						//containerStyle={{height: 'calc(100% - 64px)', top: 64}}
						classes={{paper:classes.drawerPaper}}
					>

					<div>
						<Toolbar />  {//Used to make space}
						<Typography variant="h5">
							Ninja Notes
						</Typography>
					</div>
				</Drawer>

				
			*/}	
			
				<Box sx={{ display: 'relative' }}> 
					<div 
					sx={{
						width: drawerWidth,
						flexShrink: 0,
						[`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
					  }}
					  style={{position:'relative', zIndex: 1}}
					  align="left"
					  >
					</div>
					<Routes>
						<Route path="/" element={<Dashboard_Matters getNav={this.getNav} handleSetOptionClick={this.handleSetOptionClick} setMatterNProject={this.setMatterNProject} token = {this.state.token} setToken={this.setToken} project_id={this.state.project_id} matter_id={this.state.matter_id} DocID={this.state.docid} setDocID={this.setDocID}/>}/>
						<Route path="/search" element={<Search_Page token = {this.state.token} setToken={this.setToken} project_id={this.state.project_id} matter_id={this.state.matter_id}/>}/>
						<Route path="/upload" element={<File_Uploader token = {this.state.token} setToken={this.setToken} matter_id={this.state.matter_id} project_id={this.state.project_id}/>}/>
						<Route path="/analyzer" element={<AnalyzerPage token = {this.state.token} setToken={this.setToken} matter_id={this.state.matter_id} project_id={this.state.project_id} docid={this.state.docid}/>}/>
						<Route path="/labelmanager" element={<LabelsAndSuggestions token = {this.state.token} setToken={this.setToken} matter_id={this.state.matter_id} project_id={this.state.project_id}/>}/>
						<Route path="/merge" element={<DocumentMergePage/>}/>
						<Route path="/topics" element={<Topics_Page/>}/>
						<Route path="/annotator" element={<DocumentAnnotatorPage/>}/>
						{/*Consider switching the below so that login and register got to /, and everything else goes to 404*/}
						<Route path="/*" element={<Navigate to="/"/>}/>
					</Routes>
				</Box>
			
				</div>
			
			</div>
		)
	}

	render() {
		return (
		  <>
			{
				//<Header token={this.removeToken} />
			}
			{!this.state.token && this.state.token !== "" ?
			<Routes>
				<Route path="/register" element={<Register/>}/>
				<Route path="/testing" element={
													<Testing 
														handleSetOptionClick={this.handleSetOptionClick} 
														setMatterNProject={this.setMatterNProject} 
														project_id={this.state.project_id} 
														matter_id={this.state.matter_id}
													/>
												}/>
				<Route path="*" element={<Login getNav={this.getNav} setToken={this.setToken} token={this.state.token} />}/>
			</Routes>
			  : (
				<>
				  {this.Layout()}
				</>
			  )
			}
		  </>
		);
	  }
}

export default App


/*
function App() {
	return (
		<Router>
			<Layout>
				<Routes>
					<Route exact path="/" element={<Main_Page/>}/>
					<Route exact path="/create" element={<Search_Page/>}/>
				</Routes>
			</Layout>
		</Router>
	);
}


export default App;
*/
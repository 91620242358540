import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Card, List, ListItem, Typography, Container, Tabs, Tab } from '@mui/material';
import { grey } from '@mui/material/colors';

import SeparatorBox from './SeparatorBox';

const options = ['No Value. Please Choose...'];

const API_getTargetting4Label = "/getTargets4Label.api";
const API_setTarget = "/setTarget.api";


const TargetingTab = (props) => {
    const [regexSearch, setRegexSearch] = useState("");

    //Selected Valued
    // const [selectedType, setSelectedType] = useState(options[0]);
    // const [selectedWords, setSelectedWords] = useState([]);
    // const [selectedId, setSelectedId] = useState(null);
    // const [selectedCaseSensitivity, setSelectedCaseSensitivity] = useState(false);

    //For Controlling SeperatorBox
    const [items, setItems] = React.useState(props.selectedWords||[]);
    
    //const [savedSearches, setSavedSearches] = useState([]);
    //SAMPLE DATA FOR TESTING ONLY.
    // const [savedSearches, setSavedSearches] = useState([
    //     { ID: 1, Type: 'any', Words: ["Testing", "Testing More", "Testing Even More", "etc."], Case_Sensitive: false },
    //     { ID: 2, Type: 'allInSentence', Words: ["Sorta", "Testing More", "Testing Even More", "etc."], Case_Sensitive: true },
    //     { ID: 3, Type: 'anyInSentence', Words: ["Kinda", "Testing More", "Testing Even More", "etc."], Case_Sensitive: true },
    //     { ID: 4, Type: 'all', Words: ["Mostly", "Testing More", "Testing Even More", "etc."], Case_Sensitive: true },
    //     { ID: 5, Type: 'allInSentence', Words: ["Task", "Testing More", "Testing Even More", "etc."], Case_Sensitive: true },
    //     { ID: 6, Type: 'anyInSentence', Words: ["Finding", "Testing More", "Testing Even More", "etc."], Case_Sensitive: true }
    // ]);

    const [testResults, setTestResults] = useState([]);
    const [currentTopTab, setCurrentTopTab] = useState(0);
    const [currentTab, setCurrentTab] = useState(0);

    const fetchTargets = async () => {
        // ...
        //console.log("token is: ", props.token)  //Security

        await fetch(API_getTargetting4Label, {
            method: 'POST',
            mode: 'cors',
            //IMPORTANT
            //The body should contain the paragraph_id being requested.  This should be passed via props
    
            body: JSON.stringify({
                //search_query: this.state.search_query
                //docid: props.docid,
                //matter_id: props.matter_id,
                //project_id: props.project_id
                //docid: 13
                label_id: props.label_ID
            }),	
            headers: new Headers({
            // 	//'Accept': 'application/json; charset=utf-8',
            	'Authorization': 'Bearer ' + props.token,
            	'Content-Type': 'application/json',
            // 	//'Access-Control-Allow-Origin': '*',
            })
        })
        //.then(response => response.text())          // convert to plain text
        //.then(text => console.log("fetch data: ",text))  // then log it out
        .then(response=> {
            return response.json() //convert to json.  return passes it
        })  // Prod
        //.then(data => console.log("fetch data: ",data))  //For Debugging
        .then((ResponseData) => {
            console.log("Response:", ResponseData)
            
    
            //check to see if a new access_token
            //console.log("new token: ", data)
            if(ResponseData.access_token !== undefined){
                console.log("setting new token")
                props.setToken(ResponseData.access_token) //if refresh token present. Set it.
            }
    
            if(ResponseData.targets_list !== undefined){
                console.log("targetingtab: targeting_list:", ResponseData.targets_list);
                props.setTargets(ResponseData.targets_list)
    
                //const parsedLabelsList = JSON.parse(ResponseData.paragraphs_labels_list);
                //setTags(ResponseData.paragraphs_labels_list)
        }
    
        }).catch(error => console.error('Error:', error));  //end of handling response and then error
    };


    const fetchCreateOrUpdate = async () => {
        // ...
        //console.log("token is: ", props.token)  //Security

        await fetch(API_setTarget, {
            method: 'POST',
            mode: 'cors',
            //IMPORTANT
            //The body should contain the paragraph_id being requested.  This should be passed via props
      
            body: JSON.stringify({
              //search_query: this.state.search_query
              //docid: props.docid,

              label_id: props.label_ID,
              Type: props.selectedType,
              Words: props.selectedWords,
              ID: props.selectedId,
              CaseSensitivity: props.selectedCaseSensitivity
            }),	
            headers: new Headers({
            	//'Accept': 'application/json; charset=utf-8',
            	'Authorization': 'Bearer ' + props.token,
                'Content-Type': 'application/json',
            	//'Access-Control-Allow-Origin': '*',
            })
          })
          //.then(response => response.text())          // convert to plain text
          //.then(text => console.log("fetch data: ",text))  // then log it out
          .then(response=> {
            handleNew()  //remove data from target
            fetchTargets() //reset target list
            //setCurrentTopTab(1); //Set top tab to Expressions.  //Consider
            return response.json() //convert to json.  return passes it  //CHECK IF THIS IS NEEDED...
          })  // Prod
          .catch(error => console.error('Error:', error));  //end of handling response and then error
        };



    useEffect(() => {
        fetchTargets();
    }, []);


    const handleTest = async () => {
        // Handle the clicking of the test button which should run a regex search verse various documents in the system
    };

    const handleAddNew = async () => {
        // Handles the fetch call to create a new Target for the label.
        fetchCreateOrUpdate()

    };

    const handleUpdate = async () => {
        // Handles the fetch call to update a Target for the label.
        fetchCreateOrUpdate()
    };


    const handleNew = async () => {
        // Clicked Create New Button
        props.setSelectedWords([])
        props.setSelectedId(null);
        props.setSelectedType('any')
        props.setSelectedCaseSensitivity(false)

    };

    const handleSearchSelection = (search) => {
        props.setSelectedId(search.ID);
        props.setSelectedType(search.Type)
        props.setSelectedWords(search.Words)
        props.setSelectedCaseSensitivity(search.Case_Sensitive)
        
        setCurrentTopTab(0);
    };

    function translaterSearchText(value) {
        switch(value) {
          case 'any':
            return 'Search for Any Words';
          case 'anyInSentence':
            return 'Search for Any Words in Sentence';
          case 'all':
            return 'Search for All Words';
          case 'allInSentence':
            return 'Search for All Words in Sentence';
          default:
            return 'Unknown value';
        }
      }


    return (
        <Container>
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ marginLeft: 2, flexGrow: 1 }}>
            <Tabs sx={{ marginTop: 2 }} value={currentTopTab} onChange={(event, newValue) => setCurrentTopTab(newValue)}>
                <Tab label="Current Expression" />
                <Tab label="Targeting Expressions" />
            </Tabs>
            {currentTopTab === 0 ? (
            <>
                {/* This is the current regex item selected */}

                {props.selectedId ? (
                        <Button onClick={handleNew}>Create New</Button>
                    ) : (
                        <></>
                    )}

                <Card sx={{ marginTop: 2, padding: 1 }} fullWidth>
                    <TextField
                        label="ID"
                        value={props.selectedId || "New"}
                        size="small"
                        sx={{ 
                            marginRight: 0.1,
                            '& .MuiInputBase-input': { // targeting the input element
                              paddingLeft: .5,
                              textAlign: "center",
                            },
                          }}
                        style={{ width: 50 }} // set your desired width
                        disabled
                    />
                    <TextField
                        label="Type"
                        value={translaterSearchText(props.selectedType)}
                        size="small"
                        sx={{ marginRight: .1 }}
                        disabled
                    />
                    <TextField
                        label="Case Sensitive"
                        value={props.selectedCaseSensitivity}
                        size="small"
                        style={{ width: 100 }} // set your desired width
                        sx={{ marginRight: .1 }}
                        disabled
                    />
                    <TextField
                        label="Words"
                        value={props.selectedWords}
                        size="small"
                        disabled
                    />
                </Card>
                <Box sx={{ marginTop: 2, display: 'flex', alignItems: 'center' }}>
                    <Button onClick={handleTest} disabled>Test</Button>
                    {props.selectedId ? (
                        <Button onClick={handleUpdate}>Update</Button>
                    ) : (
                        <Button onClick={handleAddNew}>Add New</Button>
                    )}
                </Box>

                <Tabs sx={{ marginTop: 2 }} value={currentTab} onChange={(event, newValue) => setCurrentTab(newValue)}>
                    <Tab label="Words" />
                    <Tab label="Test Results" disabled/>
                </Tabs>
            </>
                ) : null}
            
            {/* Targetting Expressions List */}
            {currentTopTab === 1 && props.targets.map((search) => (
                <Card                 
                    key={search.ID} 
                    sx={{ marginTop: .5, bgcolor: grey[200], padding: 1 }}
                    onClick={() => handleSearchSelection(search)}
                    fullWidth
                >
                    <TextField
                        label="ID"
                        value={search.ID}
                        size="small"
                        sx={{ marginRight: .1 }}
                        style={{ width: 50 }} // set your desired width
                        disabled
                    />
                    <TextField
                        label="Type"
                        value={translaterSearchText(search.Type)}
                        size="small"
                        sx={{ marginRight: .1 }}
                        disabled
                    />
                    <TextField
                        label="Case Sensitive"
                        value={search.Case_Sensitive}
                        size="small"
                        style={{ width: 100 }} // set your desired width
                        sx={{ marginRight: .1 }}
                        disabled
                    />
                    <TextField
                        label="Words"
                        value={search.Words}
                        size="small"
                        disabled
                    />
                </Card>
            ))}
            </Box>
        </Box>

    <Container>
        {currentTopTab === 0 && currentTab === 0 ? (<><br /><SeparatorBox 
            selectedType = {props.selectedType}
            setSelectedType ={props.setSelectedType}
            selectedId = {props.selectedId}
            setSelectedId = {props.setSelectedId}
            selectedCaseSensitivity = {props.selectedCaseSensitivity}
            setSelectedCaseSensitivity = {props.setSelectedCaseSensitivity}
            items = {props.selectedWords}
            setItems = {props.setSelectedWords}
        /></>) : null}
        {currentTopTab === 0 && currentTab === 1 && testResults.map((result, index) => (
            <Card 
                key={index} 
                sx={{ maxWidth: 345, marginTop: 2, bgcolor: grey[200] }}
            >
                <Typography>{result}</Typography>
            </Card>
        ))}
    </Container>
    </Container>
        
    );
};

export default TargetingTab;

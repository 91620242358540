import React, { Component } from 'react'

//Will hold a list of pages with errors or maybe multiple menus
//On click, either links or possibly buttons will cause the program to load a pdf with a specific page indicated.
//The purpose is so that you can hop around the pdf.

//Will want to change this so its an optional loaded component.  There will be multiple versions of the sidebar as some point.

const API = '/getpageerrors.py';  //Prod  Check

class PDF_View_Sidebar extends Component {

	constructor(props) {
		super(props)
	
		this.state = {
			 docid: '',
			 results: [],
			 isloading: true,
		}
	}

	//when the this component is mounted.
	componentDidMount(){

		fetch(API, {
			method: 'POST', 
			body: JSON.stringify({
				//search_query: this.state.search_query
				docid: this.props.docid
			}),
			headers: new Headers({
				'Accept': 'application/json; charset=utf-8',
				'Content-Type': 'application/json',
				//'Access-Control-Allow-Origin': '*',
			})
		})
		//.then(response => response.text())          // convert to plain text
		//.then(text => console.log("fetch data: ",text))  // then log it out
		.then(response=> response.json())  // Prod
		//.then(data => console.log("fetch data: ",data))  //For Debugging
		.then((data) => this.setState({results: data}));  //set data to results
	}	

	handleClick = (event, a_value, b_value) => {
		//alert(a_value);
		this.props.parentCallback({ pdfURL:a_value, pageNumber:b_value });
	}

	render() {

		const {results} = this.state;  //Prod.  Not sure why this is required, but it is.  Otherwise it doesn't receive results.

		//Need to add map to this from ES results.
		//console.log("JSON Object breakdown (this.results): ", this.results)  // for debugging
		//console.log("(const results): ", results)  // for debugging
		
		
		//first check if results is defined
		if (results) {
	
			/*if yes, show the pdf*/
			//alert(this.props.src)

			return(
				<div className="PDF_View_Sidebar">
					
					{results.map(hit =>						
						<button key={hit["File_Page_Number"]} value={`/getfile.py?docid=1#page=${hit["File_Page_Number"]}`} onMouseDown={(e) => this.handleClick(e,`/getfile.py?docid=1#page=${hit["File_Page_Number"]}`, hit["File_Page_Number"])}>{hit["File_Page_Number"]}</button>
					)}
				</div>
			);
		} else {
			//If not URL specified, return nothing.
			return(
				/*
					Problem:  apparently this is only rendering to a small part of page.
					Solution: There is a parent div that must be set to 100%
				*/
				<div className="PDF_View_Sidebar">
					<p>NO DATA LOADED</p>
				</div>
			);
		}

	}
}

export default PDF_View_Sidebar
import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import InfoPanel from './InfoPanel';
import HighlighterParagraph from './HighlighterParagraph';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';

const ParagraphPanel = ({
    paragraphs,
    selectedParagraphIndex,
    handleParagraphClick,
    selectedParagraphRef,
    showLabels,
    labels,
    showRightSection,
    setHighlightState,
    shouldHighlight,
    highlightState,
    highlightColor,
    highlightingObject,
    onHighlightTrigger,
    contentRef,
    activeParagraphIndex,
    setActiveParagraphIndex,
    highlightedRanges,
    setHighlightedRanges,
    ...props
}) => {

    let scrollTimer = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);

    const updateTooltipPosition = (container) => {
        const scrollPosition = container.scrollTop;
        const thumbHeight = container.clientHeight / container.scrollHeight * container.clientHeight;
        const thumbPosition = container.scrollTop / (container.scrollHeight - container.clientHeight) * (container.clientHeight - thumbHeight);

        setTooltipPosition({
            top: thumbPosition + thumbHeight / 2,
        });
    };

    useEffect(() => {
        if (shouldHighlight) {
            addHighlight();
            setHighlightState(prevState => ({
                ...prevState,
                shouldHighlight: false,
            }));
        }

        // const handleScroll = (e) => {
        //     const container = e.target;
        //     const scrollPosition = container.scrollTop;

        //     const pageNumbers = Object.keys(pageRefs.current);
        //     for (let i = pageNumbers.length - 1; i >= 0; i--) {
        //         const pageNumber = pageNumbers[i];
        //         const pageRef = pageRefs.current[pageNumber].current;
        //         if (pageRef && pageRef.offsetTop <= scrollPosition) {
        //             setCurrentScrollPage(pageNumber);
        //             break;
        //         }
        //     }

        //     updateTooltipPosition(container);
        //     setShowTooltip(true);

        //     if (scrollTimer.current) {
        //         clearTimeout(scrollTimer.current);
        //     }

        //     scrollTimer.current = setTimeout(() => {
        //         setShowTooltip(false);
        //     }, 1000); // Tooltip hides after 1 second of inactivity
        // };

        // const container = document.getElementById('scrollable-container');
        // container.addEventListener('scroll', handleScroll);

        // // Set initial position
        // updateTooltipPosition(container);

        // return () => {
        //     container.removeEventListener('scroll', handleScroll);
        //     if (scrollTimer.current) {
        //         clearTimeout(scrollTimer.current);
        //     }
        // };

    }, [shouldHighlight]);

    // Updated function to calculate selection offsets within a paragraph
    function getSelectionOffsetsWithinParagraph(paragraphElement) {
        const selection = window.getSelection();

        if (selection.isCollapsed || !paragraphElement.contains(selection.anchorNode)) {
            return null; // Ensure there's a valid selection within the paragraph
        }

        const range = selection.getRangeAt(0);
        let startCharCount = 0;
        let endCharCount = 0;

        const iterateNodes = (node, isStart) => {
            if (node === (isStart ? range.startContainer : range.endContainer)) {
                if (isStart) {
                    startCharCount += range.startOffset;
                } else {
                    endCharCount += range.endOffset;
                }
                return true; // Found the node, stop iteration
            } else if (node.nodeType === Node.TEXT_NODE) {
                const length = node.textContent.length;
                if (isStart) {
                    startCharCount += length;
                } else {
                    endCharCount += length;
                }
                return false; // Continue iteration
            } else {
                let found = false;
                node.childNodes.forEach(childNode => {
                    found = found || iterateNodes(childNode, isStart);
                });
                return found;
            }
        };

        iterateNodes(paragraphElement, true); // Iterate to find start position
        iterateNodes(paragraphElement, false); // Iterate to find end position

        return { start: startCharCount, end: endCharCount };
    }

    // Updated addHighlight function using new offset calculation method
    const addHighlight = () => {
        if (activeParagraphIndex !== null) {
            const paragraphElement = document.querySelector(`[data-paragraph-id="${paragraphs[activeParagraphIndex].ID}"]`);
            if (paragraphElement) {
                const offsets = getSelectionOffsetsWithinParagraph(paragraphElement);
                if (offsets) {
                    const { start, end } = offsets;
                    console.log(`Highlight added: Start: ${start}, End: ${end}, Color: ${highlightColor}, Text: '${window.getSelection().toString()}'`);
                    setHighlightedRanges(prev => [...prev, {
                        paragraphIndex: activeParagraphIndex,
                        start,
                        end,
                        color: highlightColor,
                        timestamp: Date.now()
                    }]);
                    window.getSelection().removeAllRanges(); // Clear the selection after highlighting
                }
            }
        }
    };

    //move variables to top of page when completed.
    const [currentScrollPage, setCurrentScrollPage] = useState(null);
    const tooltipRef = useRef(null);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

    const pageRefs = useRef([]); //for jumping to page divides
    let showPageBreaks = true; //change to prop that is set from parent component
    let current_page_number = 1; //for page divides.
    

    return (
        <Box id="scrollable-container" sx={{ flexBasis: showRightSection ? '60%' : '100%', overflow: 'auto', p: 1 }}>
           {/* This might be causing the below re-render.  I need to fix this. */}
           {/* {showTooltip && currentScrollPage && (
                <Tooltip title={`Page ${currentScrollPage}`} open={!!currentScrollPage} arrow placement="right">
                    <Box sx={{ position: 'absolute', top: tooltipPosition.top, left: '100%', transform: 'translate(100%, -50%)', pointerEvents: 'none' }} />
                </Tooltip>
            )} */}
            
            {paragraphs.map((paragraph, index) => {
                const isParagraphSelected = index === selectedParagraphIndex;
                const isNewPage = paragraph.File_Page_Number !== current_page_number;

                let endOfPageMessage = null;
                if (isNewPage && showPageBreaks) {
                    pageRefs.current[current_page_number] = React.createRef();
                    endOfPageMessage = (
                        <Divider key={`end-of-page-${current_page_number}`} sx={{ my: 2, color: 'lightgrey', '&::before, &::after': { borderColor: 'lightgrey' } }} ref={pageRefs.current[current_page_number]}>
                            <Typography variant="body2" sx={{ color: 'lightgrey' }}>
                                End of Page {current_page_number} (File)
                            </Typography>
                        </Divider>
                    );
                    current_page_number = paragraph.File_Page_Number;
                }

                return (
                    <React.Fragment key={index}>
                        {endOfPageMessage}

                        <HighlighterParagraph
                            key={index}
                            index={index}
                            labels={labels}
                            isParagraphSelected={isParagraphSelected}
                            selectedParagraphRef={isParagraphSelected ? selectedParagraphRef : null}
                            paragraph={paragraph}
                            showLabels={showLabels}
                            handleParagraphClick={handleParagraphClick}
                            bgcolor="#f2f2f2"
                            highlightedRanges={highlightedRanges.filter(h => h.paragraphIndex === index)}
                            highlightColor={highlightColor}
                            setActiveParagraphIndex={setActiveParagraphIndex}
                        />
                    </React.Fragment>
                );
            })}
        </Box>
    );
};

export default ParagraphPanel;

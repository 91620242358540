import React, { useState, useEffect } from 'react';
import { Button, Box, Typography, Modal, TextField, Chip, IconButton, Autocomplete } from '@mui/material';
import { Label as LabelIcon } from '@mui/icons-material';

const API = "/getlabels.api"
const SetLabelsAPI = "/setparagraphlabels.api"

const LabelButton = (props) => {
  const [open, setOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const [options, setOptions] = useState([]);

  const [labels, setLabels] = useState([]);  //holds the complete list of labels
  const [titlesWithColorArray, settitlesWithColorArray] = useState([]);

  //will need to modify this to work off label id
  const transformTags = (tags, labels, paragraph_id) => {

    // Assuming labels is an object with values that you want to search through
    const labelsArray = Object.values(labels); // Convert object values to an array
    console.log("transformTags. paragraph_id: ", paragraph_id)
    console.log("transformTags. labelsArray: ", labelsArray)

    return tags.map(tag => {
      //const found = labels.find(item => item.title === tag);
      const found = labelsArray.find(item => item.Title === tag);

      if (found) {
        console.log("found: ", found)
        return {
          Color: found.Color,
          Doc_Type: null,
          Label_ID: found.ID,
          Label_Title: found.Title,
          Paragraph_ID: paragraph_id
        };
      } else {
        console.log("not found: ", tag)
        return {
          Color: '#000000',
          Doc_Type: null,
          Label_ID: null,
          Label_Title: tag,
          Paragraph_ID: paragraph_id
        };
      }
    });
  };
  

//COMPONENT DID MOUNT - RUN WHEN COMPONENT FIRST MOUNTS
// UseEffect for component mount and updates on paragraph_id change
useEffect(() => {
  fetchData();
}, [props.paragraph_id]); // Add props.paragraph_id to the dependency array



  const fetchData = async () => {
		//console.log("token is: ", props.token)  //Security

		await fetch(API, {
			method: 'POST',
			mode: 'cors',
      //IMPORTANT
      //The body should contain the paragraph_id being requested.  This should be passed via props

			body: JSON.stringify({
				paragraph_id:props.paragraph_id
			}),
			headers: new Headers({
				//'Accept': 'application/json; charset=utf-8',
				'Authorization': 'Bearer ' + props.token,
				'Content-Type': 'application/json',
				//'Access-Control-Allow-Origin': '*',
			})
		})
		//.then(response => response.text())          // convert to plain text
		//.then(text => console.log("fetch data: ",text))  // then log it out
		.then(response=> {
			return response.json() //convert to json.  return passes it
		})  // Prod
		//.then(data => console.log("fetch data: ",data))  //For Debugging
		.then((ResponseData) => {
      console.log("Response:", ResponseData)
			

			//check to see if a new access_token
			//console.log("new token: ", data)
			if(ResponseData.access_token !== undefined){
				console.log("setting new token")
				props.setToken(ResponseData.access_token) //if refresh token present. Set it.
			}

			if(ResponseData.labels_list !== undefined && ResponseData.labels_list !== null){
        //console.log("setting labels_list:", ResponseData.labels_list);  //for debugging
        const labelsArray = JSON.parse(ResponseData.labels_list);  //create json object
  
        const parsedLabelsList = JSON.parse(ResponseData.labels_list);
        const titlesArray = parsedLabelsList.map((label) => label.Title).sort();
        console.log("titlesArray: ", titlesArray);

        const titlesWithColorArray = parsedLabelsList.map((label) => ({ title: label.Title, color: label.Color })).sort();
        console.log("titlesWithColorArray: ", titlesWithColorArray);
        
        setLabels(labelsArray);
        settitlesWithColorArray(titlesWithColorArray);
        setOptions(titlesArray);
      
        // Set isLoading to false and handle other logic if needed.
      }

      //will return null if no labels are present
      if(ResponseData.paragraphs_labels_list !== undefined){
        console.log("paragraph_labels_list:", ResponseData.paragraphs_labels_list);
  
        //const parsedLabelsList = JSON.parse(ResponseData.paragraphs_labels_list);
        setTags(ResponseData.paragraphs_labels_list)
      }

			}
		);  //set data to results
	}


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  const handleTagClick = (tag) => {
    const updatedTags = tags.filter((t) => t !== tag);
    setTags(updatedTags);
  };


  
  //Send labels to server to update
  const handleUpdate = async () => {

    //send data to server to add labels to paragraph.
    try {
    
      console.log("options:", options)
      const response = await fetch(SetLabelsAPI, {
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify({
            labels: tags,
            //IMPORTANT -- This should be determined via props.
            paragraph_id: props.paragraph_id,  //this will need to be set to props.para_id so that the caller can set the paragraph.
            requester: 'user'
          }),
          //  PUT IN FOR FINAL
          headers: new Headers({
          //'Accept': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + props.token,
          'Content-Type': 'application/json',
          //'Access-Control-Allow-Origin': '*',
        })
      });
      if (response.ok) {
        //If we need stuff put it here.
        if (props.updateLabelList) {
          console.log('Detected parent label_list modification function.  Calling function.');
          console.log('paragraph_id:', props.paragraph_id)
          console.log('tags:', tags);

          // convert tags to the format expected by the parent component
          const newTags = transformTags(tags, labels, props.paragraph_id);
          console.log('newTags:', newTags);

          props.updateLabelList(newTags, props.paragraph_id);
        }

      } else {
        console.log('Error adding labels to paragraph');
      }
      
    } catch (error) {
      console.log(error);
    }

    handleClose();
  };

  const handleTagSelect = (event, value) => {
    if (tags === null) {  //check if tags is currently empty.  If so, assign a new value.
      const trimmedValue = value.trim();
      const matchedOption = options.find((option) => option.toLowerCase() === trimmedValue.toLowerCase());  //Match color etc
      setTags(matchedOption ? [matchedOption] : []);
    } else if (value && value.trim() !== '') { 
      const trimmedValue = value.trim();
      const matchedOption = options.find((option) => option.toLowerCase() === trimmedValue.toLowerCase()); //Match color etc
      if (matchedOption && !tags.includes(matchedOption)) {
        setTags([...tags, matchedOption]);
        setInputValue('');
      }
    }
  };
  
  
  //when enter is pressed assign the tag.
  const handleTagKeyDown = (event) => {
    if (event.key === 'Enter') {
      const tag = event.target.value.trim();
      if (tag !== '' && !tags.includes(tag)) {
        setTags([...tags, tag]);
        setInputValue('');
      }
    }
  };

  // const options = [
  //   'indemnification',
  //   'limitation of liability',
  //   'test cases',
  // ].sort();

  return (
    <>
    <IconButton 
      aria-label="expand_label_model"
      sx={{
        backgroundColor: props.iconButtonStyle && props.iconButtonStyle.backgroundColor ? props.iconButtonStyle.backgroundColor : 'info.main',
        color: props.iconButtonStyle && props.iconButtonStyle.color ? props.iconButtonStyle.color : 'white',
        '&:hover': {
          backgroundColor: 'info.dark',
        },
        ...props.iconButtonStyle, // incorporate the passed variable
      }}
      onClick={handleOpen}
      >
        <LabelIcon />

    </IconButton>

      {/* <Button variant="contained" startIcon={<LabelIcon />} onClick={handleOpen}/>  REMOVE AFTER CONFIRMING FUNCTIONAL */}

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'white',
            boxShadow: 24,
            p: 4,
            outline: 'none',
          }}
        >
          <Typography variant="h6" component="h2" align="center" gutterBottom>
            Labels
          </Typography>

          <Box display="flex" alignItems="center" flexWrap="wrap" mb={2}> 
          {
            tags !== null && tags !== undefined && tags.map((tag) => {
              const matchedTitle = titlesWithColorArray.find((item) => item.title === tag);
              const color = matchedTitle ? matchedTitle.color : null;
              return (
                <Chip
                  key={tag}
                  label={tag}
                  onDelete={() => handleTagClick(tag)}
                  color="primary"
                  sx={{ mr: 1, mt: 1, backgroundColor: color }}
                  // onDelete={undefined}  //remove delete icon
                  // sx={{
                  //   //m: 0,
                  //   //mt: 1,
                  //   backgroundColor: color,
                  //   fontSize: '8pt',
                  //   border: '1px solid #ccc',
                  //   paddingTop: '0px',
                  //   paddingBottom: '0px',
                  //   lineHeight: '1',
                  //   height: '1',
                  //   '& .MuiChip-deleteIcon': {
                  //     width: '12px',
                  //     height: '12px',
                  //   },
                  // }}  // make the chips as small as possible.
                />
              );
            })
          }
          </Box>
          <Autocomplete
            options={options}
            value={inputValue}
            onChange={handleTagSelect}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Type here"
                onKeyDown={handleTagKeyDown}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
              />
            )}
          />
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={handleUpdate} variant="contained" sx={{ mr: 1 }}>
              Update
            </Button>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default LabelButton;
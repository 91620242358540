import React, { useState, useEffect } from 'react';
import TreeView from '@mui/lab/TreeView';
import Button from '@mui/material/Button';
import { StyledTreeItem } from './StyledTreeItem';
//import { testData } from './testData';  //For Debugging

export default function TargetPicker(props) {
  //const [data, setData] = useState(testData);  // For Debugging
  const [data, setData] = useState([]);  // For Debugging

  //const API_getLabelGroupsNLabels = "http://192.168.1.3:9998/getLabelGroupsNLabels.api";
  const API_getLabelGroupsNLabels = "/getLabelGroupsNLabels.api";
  const API_setProcessing4Document = "/setProcessing4Document.api";

    // This useEffect will run once when the component is loaded.
    useEffect(() => {

      //Get original data
      fetchGroupLabelsNLabels("1")

      // It will update the state of the group checkboxes based on the state of their item checkboxes.
      const updateGroupCheckboxes = data.map((groupData) => {
        // Check if a 'Processed' or 'Disabled' item exists in the group
        const processedItemExists = groupData.items.some(item => item.checked === 'Processed' || item.checked === 'Disabled');
        // Check if an 'Empty' item exists in the group
        const emptyItemExists = groupData.items.some(item => item.checked === 'Empty');
  
        let groupCheckedState;
        // Set the state of the group checkbox based on the item states within the group
        if (processedItemExists && emptyItemExists) {
          groupCheckedState = 'Process';
        } else if (!processedItemExists && emptyItemExists) {
          groupCheckedState = 'Empty';
        } else if (processedItemExists && !emptyItemExists) {
          groupCheckedState = 'Processed';
        } else {
          groupCheckedState = 'Disabled';
        }
  
        return { ...groupData, checked: groupCheckedState };
      });
  
      setData(updateGroupCheckboxes);
    }, []);


// This function checks if a 'Disabled' item exists in the given items array
// and returns the boolean result and the index of the disabled item if it exists.
const checkDisabledItemExists = (items) => {
  for (let i = 0; i < items.length; i++) {
    if (items[i].checked === 'Disabled') {
      return { exists: true, index: i };
    }
  }
  return { exists: false, index: -1 };
};

const updateItemsWithID = (newData, ID, state) => {
  // set same state for all items with 
  // same id
  newData.forEach(grp => {
    grp.items.forEach(itm => {
      if(itm.ID === ID){
        if(itm.checked != 'Disabled' && itm.checked != 'DisabledProcessing'){
          itm.checked = state;
        }
      }
    })
  });
}

const fixGroupState = (newData) => {
  // set same state for all items with 
  // same id
  newData.forEach(grp => {
    let hasEmpty = grp.items.some(itm => itm.checked === 'Empty');
    let hasChecked = grp.items.some(itm => itm.checked !== 'Empty');
    if (grp.checked === "DisabledProcessing"){
      return;
    }
    if(hasEmpty && hasChecked){
      grp.checked = 'Process'
    }else if(hasEmpty && !hasChecked){
      grp.checked = 'Empty';
    }else{
      grp.checked = 'Processed';
    }
  });
}

const handleCheckChange = (title, type, state) => {
  let newData = JSON.parse(JSON.stringify(data)); // deep copy
  let groupIdx, itemIdx;

  if (type === 'group') {
    groupIdx = newData.findIndex(group => group.Group === title);
    if (groupIdx !== -1) {
      const currentGroupState = newData[groupIdx].checked;
      const disabledItemExists = newData[groupIdx].items.some(item => item.checked === 'Disabled');

      if (currentGroupState === 'Process' && state === 'Processed') {
        newData[groupIdx].items.forEach(item => {
          if (item.checked !== 'Disabled' && item.checked !== 'DisabledProcessing') {
            item.checked = 'Processed';

            // set same state for all items with 
            // same id
            newData.forEach(grp => {
              grp.items.forEach(itm => {
                if(itm.ID === item.ID){
                  if(itm.checked != 'Disabled' && item.checked !== 'DisabledProcessing'){
                    itm.checked = 'Processed';
                  }
                }
              })
            });
          }
        });
        newData[groupIdx].checked = state;
      } else if (currentGroupState === 'Processed' && state === 'Empty') {
        if (disabledItemExists) {
          newData[groupIdx].checked = 'Process';
          newData[groupIdx].items.forEach(item => {
            if (item.checked !== 'Disabled' && item.checked !== 'DisabledProcessing') {
              item.checked = 'Empty';
              updateItemsWithID(newData, item.ID, 'Empty');
            }
          });
        } else {
          newData[groupIdx].checked = state;
          newData[groupIdx].items.forEach(item => {
            if (item.checked !== 'Disabled' && item.checked !== "DisabledProcessing") {
              item.checked = 'Empty';

              updateItemsWithID(newData, item.ID, 'Empty');
            }
          });
        }
      } else if (currentGroupState === 'Process' && state === 'Empty') {
        if (disabledItemExists) {
          newData[groupIdx].items.forEach(item => {
            if (item.checked !== 'Disabled' && item.checked !== "DisabledProcessing") {
              item.checked = 'Processed';

              updateItemsWithID(newData, item.ID, 'Processed');
            }
          });
          newData[groupIdx].checked = 'Processed';
        } else {
          newData[groupIdx].checked = 'Processed';  // changed this line
          newData[groupIdx].items.forEach(item => {
            if (item.checked !== 'Disabled' && item.checked !== "DisabledProcessing") {
              item.checked = 'Processed';  // changed this line

              updateItemsWithID(newData, item.ID, 'Processed');
            }
          });
        }
      } else if (currentGroupState === 'Empty' && state === 'Processed') {
        if (!disabledItemExists) {
          newData[groupIdx].checked = 'Processed';
          newData[groupIdx].items.forEach(item => {
            if (item.checked !== 'Disabled' && item.checked !== "DisabledProcessing") {
              item.checked = 'Processed';

              updateItemsWithID(newData, item.ID, 'Processed');
            }
          });
        }
      }
    }
  } else if (type === 'item') {
    for (let i = 0; i < newData.length; i++) {
      itemIdx = newData[i].items.findIndex(item => item.Title === title);
      if (itemIdx !== -1) {
        groupIdx = i;
        break;
      }
    }
    if (itemIdx !== -1 && groupIdx !== undefined) {
      newData[groupIdx].items[itemIdx].checked = state;
      
      updateItemsWithID(newData,
        newData[groupIdx].items[itemIdx].ID, state);

      const processedItemExists = newData[groupIdx].items.some(item => item.checked === 'Processed' || item.checked === 'Disabled');
      const emptyItemExists = newData[groupIdx].items.some(item => item.checked === 'Empty');
      if (processedItemExists && emptyItemExists) {
        newData[groupIdx].checked = 'Process';
      } else if (!processedItemExists && emptyItemExists) {
        newData[groupIdx].checked = 'Empty';
      } else if (processedItemExists && !emptyItemExists) {
        newData[groupIdx].checked = 'Processed';
      }
    }
  }
  fixGroupState(newData);
  setData(newData);
};

    

  const handleSubmit = () => {
  // Deep copy of the data
  let newData = JSON.parse(JSON.stringify(data));

  // Iterate over all groups and items
  newData.forEach(group => {
    group.items.forEach(item => {
      // If the item is 'Processed', change its state to 'DisabledProcessing'
      if (item.checked === 'Processed') {
        item.checked = 'DisabledProcessing';
      }
    });

    // If the group is 'Processed', change its state to 'DisabledProcessing'
    if (group.checked === 'Processed') {
      group.checked = 'DisabledProcessing';
    }
  });

  // Update the state
  setData(newData);
  console.log("TargetPicker Submission:", newData)
  setGroupLabelsNLabels();

  // Continue with your existing submit logic
  //setGroupLabelsNLabels();
}


  //fetch GroupLabels and Labels
  const fetchGroupLabelsNLabels = async (Doc_ID) => {
    //console.log("token is: ", props.token)  //Security
  
    await fetch(API_getLabelGroupsNLabels, {
      method: 'POST',
      mode: 'cors',
      //IMPORTANT
      //The body should contain the paragraph_id being requested.  This should be passed via props

      body: JSON.stringify({
        //search_query: this.state.search_query
        doc_id: props.docid,
        //matter_id: props.matter_id,
        //project_id: props.project_id
        //doc_id: "1"  //doc_id: 1 for debugging.
      }),
      headers: new Headers({
      // 	//'Accept': 'application/json; charset=utf-8',
      'Authorization': 'Bearer ' + props.token,
      'Content-Type': 'application/json',
      // 	//'Access-Control-Allow-Origin': '*',
      })
    })
    //.then(response => response.text())          // convert to plain text
    //.then(text => console.log("fetch data: ",text))  // then log it out
    .then(response=> {
      return response.json() //convert to json.  return passes it
    })  // Prod
    //.then(data => console.log("fetch data: ",data))  //For Debugging
    .then((ResponseData) => {
      console.log("Response:", ResponseData) //For Debugging
      
      //check to see if a new access_token
      //console.log("new token: ", data)
      if(ResponseData.access_token !== undefined){
        console.log("setting new token")
        //props.setToken(ResponseData.access_token) //if refresh token present. Set it.
      }

      if(ResponseData.labels_list !== undefined){
        console.log("labels_list:", ResponseData.labels_list);
        setData(ResponseData.labels_list)
      }

    }).catch(error => console.error('Error:', error));  //end of handling response and then error
  }

  //fetch GroupLabels and Labels
  const setGroupLabelsNLabels = async () => {
    //console.log("token is: ", props.token)  //Security
  
    const response = await fetch(API_setProcessing4Document, {
      method: 'POST',
      mode: 'cors',
      //IMPORTANT
      //The body should contain the paragraph_id being requested.  This should be passed via props

      body: JSON.stringify({
        //search_query: this.state.search_query
        //docid: props.docid,
        //docid: 13
        labels_list: data,
        doc_id: props.docid,
        //doc_id: "1"  //doc_id: 1 for debugging.
      }),	
      headers: new Headers({
      // 	//'Accept': 'application/json; charset=utf-8',
      'Authorization': 'Bearer ' + props.token,
      'Content-Type': 'application/json',
      // 	//'Access-Control-Allow-Origin': '*',
      })
    }).catch(error => console.error('Error:', error));  //end of handling response and then error

    //check if the response is ok.
    if (response.ok) {
      alert("Processing Set");
    }

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  }


  return (
    <React.Fragment>
      <TreeView>
        {data.map((group, groupIndex) => 
            <StyledTreeItem 
                key={`group-${groupIndex}`} 
                nodeId={group.Group} 
                label={group.Group} 
                checkboxType="group" 
                checked={group.checked} 
                onCheckChange={handleCheckChange}
            >
            {group.items.map((item, itemIndex) => 
                <StyledTreeItem 
                    key={`item-${groupIndex}-${itemIndex}`} 
                    nodeId={item.Title} 
                    label={item.Title} 
                    checkboxType="item" 
                    checked={item.checked} 
                    disabled={item.disabled}
                    onCheckChange={handleCheckChange} 
                />
            )}
            </StyledTreeItem>
        )}
      </TreeView>
      <Button variant="contained" onClick={handleSubmit}>Submit</Button>
    </React.Fragment>
  );
}

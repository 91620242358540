import React, { Component } from "react";
import SortableItems from "./05_drag_and_drop_listv2";
import MultiPDF_Viewer from './05_multipdfviewer';
import ScreenfillModalObject from './06_screenfill_modal'


class DocumentMergePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [["837", "1", "20"]],
      show_full_modal: false,
      doc_id: "test",
      processing_complete: false
    };
  }

  updateItems = (items) => {
    this.setState({items})
  }

  updateDocID = (tmp_doc_id) => {
    //alert(tmp_doc_id);
    var obj = JSON.parse(tmp_doc_id);
    //alert(obj.data);    
    this.setState({doc_id: obj.data});
    console.log("Obj data: ", obj.data);
    //debugging how to check state change
    // this.setState({doc_id:obj.data }, () => {
    //   console.log('doc_id:', this.state.doc_id);
    // }); 
    //alert("Page Merge DocID: ", this.state.new_doc_id)
  }

  updateProcessing = (tmp_data) => {
    //alert(tmp_data);
    this.setState({processing_complete: tmp_data});
    
    //debugging how to check state change
    // this.setState({doc_id:obj.data }, () => {
    //   console.log('doc_id:', this.state.doc_id);
    // }); 
    //alert("Page Merge DocID: ", this.state.new_doc_id)
  }

  showFullModal = () => {
    this.setState({show_full_modal:true});
  }

  hideFullModal = () => {
    this.setState({show_full_modal:false});
  }

  render() {
    //console.log(this.state.items);
    return(
      <div>
        <ScreenfillModalObject 
          show={this.state.show_full_modal} 
          doc_id={this.state.doc_id}
          processing_complete={this.state.processing_complete} 
          updateDocID={this.updateDocID} 
          updateProcessing={this.updateProcessing} 
          hideFullModal={this.hideFullModal}
        />


        <div className="PDF_View_Sidebar">

          <SortableItems 
              pdf_list={this.state.items} 
              updateItems={this.updateItems} 
              updateDocID={this.updateDocID}
              updateProcessing={this.updateProcessing} 
              showFullModal={this.showFullModal}
          />


        </div>
        <div className="PDF_View_Page">
        {this.state.items.map((value, index) => (
            //Note: if you add () to a function it calls it.
            //Index is not passed so you have to define it twice.
            <MultiPDF_Viewer 
              src = {`/getfile.py?docid=${value[0]}`}
              startPage = {value[1]}
              endPage = {value[2]}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default DocumentMergePage;
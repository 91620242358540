import { Block } from '@mui/icons-material';
import { Card, Grid } from '@mui/material';
import React, { Component, useRef } from 'react';
import { Document, Page, StyleSheet } from 'react-pdf';

//https://react-pdf.org/styling

//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

//https://react-pdf.org/styling

/* code is glitched, you get an error that states:
ERROR:  Critical dependency: require function is used in a way in which dependencies cannot be statically extracted
FIX:    The way to fix this is to copy "node_modules/pdfjs-dist/build/pdf.worker.min.js" to "public/pdf.worker.min.js"
        
AUTOMATED SOLUTION: 
    One person notes to do it automatically using a package.json script:
    small automation: add "postinstall": "cp node_modules/pdfjs-dist/build/pdf.worker.min.js public/pdf.worker.min.js" to the scripts section in the package.json
    See:https://github.com/wojtekmaj/react-pdf/issues/280

    Notes of PDF.jS
    https://code.tutsplus.com/tutorials/how-to-create-a-pdf-viewer-in-javascript--cms-32505

    Consider adding navigation bar that allows jumping page by page using scroll, zooming in and out, jumping to pages x of numPages.
*/

/* ADDING ANNOTATION LAYER

  https://usefulangle.com/post/94/javascript-pdfjs-enable-annotation-layer
  https://stackoverflow.com/questions/63278540/how-to-use-annotation-layer-in-pdf-js

  https://www.findbestopensource.com/product/agentcooper-react-pdf-highlighter
  

*/
 
class MULTI_RJS_PDF_Viewer extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
    ParentHeight: 1,
    ParentWidth: 1,
  }

  parentRef = React.createRef();
 
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }



  goToPDFPage(){

      //May want to research the following.
      //See: https://blog.logrocket.com/a-complete-guide-to-default-props-in-react-984ea8e6972d/

      //check if pageNumber was passed.  If so scroll to that page, else don't.
      if (this.props.pageNumber)
      {
        var string_search = '[data-page-number="' + this.props.pageNumber + '"]';
      
        //alert(string_search);

        //set to to time out after 30000
        var checkExist = setInterval(function() {
          if (document.querySelector(string_search)) {
            //console.log("Exists!"); //for Debug
            clearInterval(checkExist);

            //Scrolling
            //See: https://www.w3schools.com/jsref/met_element_scrollintoview.asp
            //See also: https://www.w3schools.com/jsref/met_document_getelementsbyclassname.asp
            document.querySelector(string_search).scrollIntoView();
          }
      }, 500); // check every 500ms (1/2 second)
    }
      //get top position of element
      //var myElement = document.getElementById('element_within_div');
      //document.querySelector('[data-page-number="22"]').scrollIntoView();
  }

  /*
  removeTextLayerOffset() {
    const textLayers = document.querySelectorAll(".react-pdf__Page__textContent");
      textLayers.forEach(layer => {
        const { style } = layer;
        style.top = "49%";
        style.left = "0";
        style.transform = "";
        style.cssFloat = "right";
    });
  */

    //Adjust text by 1% to fix offset.  Note that the Annotation layer must be imported to allow this to happen correctly.
    removeTextLayerOffset() {
      //THIS DOES NOT WORK WITH MULTIPLE PDFS AS PROVIDED BELOW.
      //REASON IS THAT EACH DOCUMENT RESTARTS WITH PAGE NUMBER 1.

      const textLayers = document.querySelectorAll(".react-pdf__Page__textContent");
        textLayers.forEach(layer => {
          const { style } = layer;
          //alert(style.position);
          style.top = "49%";
      });
  }

    //note used, and not tested. Designed to copy attributes of two element.
    copyAttrs = (target, source) => {
      [...source.attributes].forEach(attr => {
          target.setAttribute(attr.nodeName, attr.nodeValue)
      })
    }

    updateParentHnW = (var_height, var_width) => {
      //setState to update Annotator Canvas
      this.setState({ParentHeight: var_height, ParentWidth:var_width});
    }

    //make annotation_layer match pdf_canvas
    clonePDFCanvasStyles = (targetPDFLayer, targetAnnotationLayer) => {

      //THIS DOES NOT WORK WITH MULTIPLE PDFS AS PROVIDED BELOW.
      //REASON IS THAT EACH DOCUMENT RESTARTS WITH PAGE NUMBER 1.

      targetPDFLayer = String(targetPDFLayer)  //sometimes issue scome from code

      //alert(targetPDFLayer)

        //set to to time out after 30000
        var checkExist = setInterval(function() {

            //Note:  Sometimes "s surrounding selector will cause invalid selector error.
            if (document.querySelector(targetPDFLayer)) {
            //console.log("Exists!"); //for Debug
            
            clearInterval(checkExist);  //if found stop searching

            //Scrolling
            //See: https://www.w3schools.com/jsref/met_element_scrollintoview.asp
            //See also: https://www.w3schools.com/jsref/met_document_getelementsbyclassname.asp
            //alert("Found PAGE")
            var pdfcanvas = document.querySelector(targetPDFLayer);  //target canvas
            //alert(targetAnnotationLayer);
            var annotation_layer = document.querySelector(targetAnnotationLayer);  //target annotation layer
            
            //creator selectors for Konvajs
           // var konvajs_div_selector = targetAnnotationLayer + " > div.konvajs-content"
           // var konvajs_can_selector = targetAnnotationLayer + " > div.konvajs-content > canvas"

            //var konvajs_div = document.querySelector(konvajs_div_selector)
            //var konvajs_can = document.querySelector(konvajs_can_selector)


            //annotation_layer.height = pdfcanvas.height  //for canvas
            //annotation_layer.width = pdfcanvas.width  //for canvas
            
            //used for PDF
            //annotation_layer.setAttribute('style', `position: absolute; top: 49%; left: 50%; color: transparent; transform: translate(-50%, -50%) rotate(0deg); padding-left: 0px; padding-right: 0px; height:${pdfcanvas.height}px; width:${pdfcanvas.width}px`)  //change div height;  but erases all other styles
          
            //used for Canvas

            //copy style
            const styles = window.getComputedStyle(pdfcanvas);
            let cssText = styles.cssText;
            /*
            if (!cssText) {
              cssText = Array.from(styles).reduce((str, property) => {
                console.log(`${str}${property}:${styles.getPropertyValue(property)};`)
                return `${str}${property}:${styles.getPropertyValue(property)};`;
              }, '');
            }
            */
            //annotation_layer.style.cssText = cssText;
            
            //console.log("width: ", styles.getPropertyValue("width"))

            //target style
            annotation_layer.setAttribute('style', `position: absolute; top: 49%; left: 50%; color: transparent; transform: translate(-50%, -50%) rotate(0deg); padding-left: 0px; padding-right: 0px; height:${styles.getPropertyValue("height")}; width:${styles.getPropertyValue("width")}`)  //change div height;  but erases all other styles
            annotation_layer.setAttribute('height', pdfcanvas.height)
            annotation_layer.setAttribute('width', pdfcanvas.width)
            
            //for this to size accordingly the div must appear under the pdf.js div.
            //the following moves the div.            
            
            //create string to target parent div
            var parentPDFdivString = targetPDFLayer.replace(' canvas:first-child','');
            if (document.querySelector(parentPDFdivString)) {
              //alert(parentPDFdivString)

              //target parent div and make canvas a child
              document.querySelector(parentPDFdivString).appendChild(annotation_layer);
            } else {
              alert(parentPDFdivString + "not found!");
            }

            //annotation_layer.styles = pdfcanvas.styles  //did not work
            
            }
        }, 2000); // check every 500ms (1/2 second)


  }
  
  
  render() {
    const { pageNumber, numPages } = this.state;
    console.log(this.props);
    const {endPage, startPage} = this.props;
    console.log("endPage: ", endPage)
    console.log("startPage: ", startPage)

    const range = (endPage - startPage) + 1
    console.log("range: ", range)
    var document_number = this.props.src.replace('/getfile.py?docid=','');
 
    return (


      //**Need to figure out how to only render if src changes
      //See: https://www.freecodecamp.org/news/how-to-identify-and-resolve-wasted-renders-in-react-cc4b1e910d10/
      //See also: https://reactjs.org/docs/conditional-rendering.html
      //See also: https://blog.logrocket.com/conditional-rendering-in-react-c6b0e5af381e/
      
      //May want to use a function calls <Document> if src changes or else doesn't.  However, stil need to consider that render will be called and it needs a value
      //unless we can prevent the render, and just call the jump to page. 
      
      //className="PDF_reader"
      <div className="PDF_reader">
        <Card>
          <Document
            file={this.props.src}
            onLoadSuccess={this.onDocumentLoadSuccess}
          >
          {Array.apply(1, Array(range)).map((x, i) => {
              //alert(i);

              //Need to increase size of each page of the pdf so it fits the screen.
              //Consider using height.
              //Will need to add a calculation for width.  500 worked for a test.
              //i+1 because pdf.js starts at 1 not 0\
              //onLoadSuccess={this.removeTextLayerOffset} // used to strip out css coding from text layer.


              //Fixing text alignment
              //https://github.com/wojtekmaj/react-pdf/issues/556
            
              //If annotator is to be used on multiple pages, then in addition to document and page number, we will need a merger_index_no.  This is because you can insert the same document multiple times, and
              //the text and annotator pages will need to be aligned with the correct instance of the copy.  so if doc1page1 is in twice, the annotator page will need to be inserted on over the second copy not
              //just the first.
              
              //this.clonePDFCanvasStyles(`div[data-page-number='${i + parseInt(this.props.startPage)}'] canvas:first-child`,`#annotation_layer_doc_${document_number}_page_${i + parseInt(this.props.startPage)}`)
              //

              return [<Page 
                        onLoadSuccess={() => {
                          this.removeTextLayerOffset();
                         }} 
                        pageNumber={i + parseInt(this.props.startPage)} 
                        width="550"/>,
                      ];
              //return [<Page onLoadSuccess={this.removeTextLayerOffset} pageNumber={i + parseInt(this.props.startPage)} width="550"/>, <AnnotationCanvas draw={this.draw} targetcanvas={`"div[data-page-number='${i + parseInt(this.props.startPage)}'] canvas:first-child"`}/>];  //use [,]  to return multiple
              }
          )
          }
          </Document>
          </Card>

          

      </div>
    );
    
  }
}

export default MULTI_RJS_PDF_Viewer
import React, { Component } from "react";
import "./06_modal-styles.css"

/*

Notes:

How to position model in middel of screen (yet to be tested)
See: https://stackoverflow.com/questions/29664622/css-for-modal-how-to-position-it-above-page-yet-scrollable-in-window

*/


class ModalObject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal:"",
      btn:"",
      span:"",
      documentID:"",
      start_page:"",
      end_page:"",
      show: this.props.show
    };
  }



  initilize() {

    // Get the modal      
    const modal = this.state.modal
    const btn = this.state.btn
    const span = this.state.span

    modal = document.getElementById("myModal");
    this.setState({modal})

    btn = document.getElementById("myBtn");
    this.setState({btn})

    span = document.getElementsByClassName("close")[0];
    this.setState({span})
}


handleAddItem = (e) => {
  e.preventDefault();

  this.props.add_item(this.state.documentID, this.state.start_page, this.state.end_page);
}


handleClose = (e) => {
  e.preventDefault();
  this.props.close();
}

handleDocumentIDChange = (event) => {
  this.setState({
    documentID: event.target.value
  })	
}

handleStartPageChange = (event) => {
  this.setState({
    start_page: event.target.value
  })	
}

handleEndPageChange = (event) => {
  this.setState({
    end_page: event.target.value
  })	
}

  render() {
    //if show is false don't show anything
   if (!this.props.show){
     return null;
   }
    return (
      <div className="modal-content">
        <div className="modal-header">
          <span className="close" onClick={this.handleClose}>&times;</span>
          <h2>Add Document</h2>
        </div>
        <div className="modal-body">
          <p>Please select the document you'd like to add:</p>
          <div className="modal_form">
          <label> Document ID:  </label><input type='text' value={this.state.documentID} onChange={this.handleDocumentIDChange}/><br></br>
          <label> Starting Page:  </label><input type='text' value={this.state.start_page} onChange={this.handleStartPageChange}/><br></br>
          <label> Ending Page:  </label><input type='text' value={this.state.end_page} onChange={this.handleEndPageChange}/><br></br>
          <button className="secondary" onClick={this.handleClose}>Close</button>
          <button className="primary" onClick={this.handleAddItem}>Add Document</button>
          </div>
          <div className="modal_image">

          </div>
        </div>

        <div class="modal-footer">
          <h3></h3>
        </div>
      </div>
    );
  }
}

export default ModalObject;
import React from 'react';
import { Link, Box, Typography } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';

function DocumentInfo({hit, ...props}) {
  return (
    <Box>
        <CardHeader nogutter
          title={
            <Link target="_blank" href={`/getfile.py?docid=${hit["_source"]["Doc_ID"]}#page=${hit["_source"]["File_Page_Number"]}`}>Document Name: {hit["_source"]["Document_Name"]}</Link> 
          }
          subheader={
            <div>
              <div>{`Document ID: ${hit["_source"]["Doc_ID"]}`}</div>
              <div>{`Page No.: ${hit["_source"]["File_Page_Number"]}`}</div>
            </div>
            // `Document ID: ${hit["_source"]["Doc_ID"]}\nPage No.: ${hit["_source"]["File_Page_Number"]}`
          }
        />

      {/* <Typography variant="h6" gutterBottom sx={{ color:'#1976d2' }}>Document Name:</Typography>
      <Typography variant="body2" color="text.secondary">Document ID: 140</Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>Page No.: 24</Typography> */}

    </Box>
  );
}
export default DocumentInfo;
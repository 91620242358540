import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import { redirect } from 'react-router-dom';


import {Link} from "react-router-dom"

//import RenderGroup from './ResponsiveAppBar_Selector';
import RenderProjectSelector from './ResponsiveAppBar_Selector';
import LogoAnimated from './logo';


//https://stackoverflow.com/questions/63786452/react-navigate-router-v6-invalid-hook-call
//const navigate = useNavigate();

const pages = [
    {
        key: "1",
        text: "Home",
        path: '/'
    },
    {
        key: "2",
        text: "Search",
        path: '/search'
    },,
    {
        key: "3",
        text: "Upload",
        path: '/upload'
    },
    // {
    //     key: "4",
    //     text: "Merge",
    //     path: '/merge'
    // },
    // {
    //     key: "5",
    //     text: "Topics",
    //     path: '/topics'
    // },
    // {
    //     key: "6",
    //     text: "Annotator",
    //     path: '/annotator'
    // },
    {
        key: "7",
        text: "Analyzer",
        path: '/analyzer'
    },
    {
      key: "8",
      text: "Label Manager",
      path: '/labelmanager'
    }
]
const settings = ['Profile', 'Account', 'Dashboard', 'Label Manager', 'Logout'];

const ResponsiveAppBar = (props) => {

  //console.log(props) //for debugging

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  //set change back state.  See previous navigation bar and app setup
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <LogoAnimated/>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.key} onClick={() => {
                    handleCloseNavMenu();
                    props.MenuChoice(page.text);
                  }}>
                  <Link to={page.path} style={{ textDecoration: 'none' }}>
                    <Typography textAlign="center">{page.text}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>           
            <Link to= '/' style={{ textDecoration: 'none' }}> 
              <Button
                key={"1"}
                onClick={() => {handleCloseNavMenu(); props.MenuChoice("Home");}}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                Home
              </Button>
            </Link>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>           
            <Link to= '/search' style={{ textDecoration: 'none' }}> 
              <Button
                key={"2"}
                onClick={() => {handleCloseNavMenu(); props.MenuChoice("Search");}}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                Search
              </Button>
            </Link>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>           
            <Link to= '/upload' style={{ textDecoration: 'none' }}> 
              <Button
                key={"3"}
                onClick={() => {handleCloseNavMenu(); props.MenuChoice("Upload");}}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                Upload
              </Button>
            </Link>
          </Box>

          {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>           
            <Link to= '/merge' style={{ textDecoration: 'none' }}> 
              <Button
                key={"4"}
                onClick={() => {handleCloseNavMenu(); props.MenuChoice("Merge");}}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                Merge
              </Button>
            </Link>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>           
            <Link to= '/topics' style={{ textDecoration: 'none' }}> 
              <Button
                key={"5"}
                onClick={() => {handleCloseNavMenu(); props.MenuChoice("Topics");}}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                Topics
              </Button>
            </Link>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>           
            <Link to= '/annotator' style={{ textDecoration: 'none' }}> 
              <Button
                key={"5"}
                onClick={() => {handleCloseNavMenu(); props.MenuChoice("Annotator");}}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                Annotator
              </Button>
            </Link>
          </Box> */}

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>           
            <Link to= '/analyzer' style={{ textDecoration: 'none' }}> 
              <Button
                key={"5"}
                onClick={() => {handleCloseNavMenu(); props.MenuChoice("Analyzer");}}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                Analyzer
              </Button>
            </Link>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>           
            <Link to= '/labelmanager' style={{ textDecoration: 'none' }}>
              <Button
                key={"6"}
                onClick={() => {handleCloseNavMenu(); props.MenuChoice("LabelManager");}}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                Label Manager
              </Button>
            </Link>
          </Box>
            {/* {console.log("props:",  props)} */}
          
          {/* Render selector */}
          <RenderProjectSelector 
            autocompleteRef={props.autocompleteRef} 
            handleSetOptionClick={props.handleSetOptionClick}
            optionChoice={props.optionChoice}
            projectsList={props.projectsList} 
            setMatterNProject={props.setMatterNProject}
          />

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >

            <MenuItem key="Logout" onClick={() => {handleCloseNavMenu(); props.removeToken()}}>
              <Typography textAlign="center">Logout</Typography>
            </MenuItem>

            <MenuItem key="SetLabelsNSuggestions" onClick={() => {handleCloseNavMenu(); props.removeToken()}}>
              <Typography textAlign="center">Label Manager</Typography>
            </MenuItem>
              
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
import React from "react";
import { useState } from 'react';
import Button from '@mui/material/Button';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

function CopyButton({ text, iconButtonStyle }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <Button
      variant="contained"
      color="info"
      sx={{
        ...iconButtonStyle, //incorporate the passed variable
      }}
      onClick={handleCopyClick}
      startIcon={isCopied ? <CheckCircleOutlineIcon /> : <FileCopyOutlinedIcon />}
    >
      {isCopied ? 'Copied!' : 'Copy'}
    </Button>
  );
}

export default CopyButton;
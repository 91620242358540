import React, { useState, useEffect } from 'react';
import { Button, Paper, Box, Typography, Modal, TextField, Chip, IconButton, Autocomplete } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import Drawer from '@mui/material/Drawer';
// import IconButton from '@mui/material/IconButton';
// import ChecklistIcon from '@mui/icons-material/Checklist';
// import CloseIcon from '@mui/icons-material/Close';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import Box from '@mui/material/Box';
// import SidebarItem from './SidebarItem';

// import Toolbar from '@mui/material/Toolbar';
// import SearchIcon from '@mui/icons-material/Search';
// import SaveIcon from '@mui/icons-material/Save';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';

// import SummarizeIcon from '@mui/icons-material/Summarize';

// import Collapse from '@mui/material/Collapse';
// import Button from '@mui/material/Button';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';


//we will need to pass the checks list as checks and the check_responses list as responses
// ADD IN , comments, setComments for final version.
const LinksModal = ({ showModal, selectedCheck, setShowModal, ...props}) => {

    const handleClose = () => {
        setShowModal(false);
    };
    
    console.log("LinksModal. selectedCheck:", selectedCheck, showModal)
    return(  
        <Modal open={showModal} onClose={handleClose}>
            <Box
                sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'white',
                boxShadow: 24,
                p: 4,
                outline: 'none',
                width: '75%', // Set width to 75%
                height: '75%', // Set height to 75%
                overflow: 'auto', // Add scrolling if content overflows
                }}
            >
            <IconButton onClick={handleClose} style={{ float: 'right' }}>
                <CloseIcon />
            </IconButton>

            <h2>References:</h2>
            {console.log("LinksModal. selectedCheck:", selectedCheck)}
            {selectedCheck && selectedCheck.Links && selectedCheck.Links.map((link, index) => {
                return(
                <Paper
                    key={index}
                    data-link-id={link.ID} // for text detection
                    variant='elevation'
                    sx={{
                    mb: 1,
                    p: 1,
                    bgcolor: 'background.paper',
                    //cursor: 'pointer',
                    borderLeft: 'none',
                    }}
                >    
                    <Box sx={{ mb: 1, display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>

                        {/* I need to add code that opens documents in a new tab or window. */}
                        <IconButton 
                            aria-label="expand_label_model"
                            sx={{
                                backgroundColor: props.iconButtonStyle && props.iconButtonStyle.backgroundColor ? props.iconButtonStyle.backgroundColor : 'info.main',
                                color: props.iconButtonStyle && props.iconButtonStyle.color ? props.iconButtonStyle.color : 'white',
                                '&:hover': {
                                backgroundColor: 'info.dark',
                                },
                                ...props.iconButtonStyle, // incorporate the passed variable
                            }}
                            onClick={() => {}}//no action yet
                            >
                                <VisibilityIcon/>
                        </IconButton>
                    </Box>    
                    {   
                    //For highlighting if we need it later                             
                    //paragraph && paragraph.Text && (highlightedRanges && highlightedRanges.length > 0 ? renderTextWithHighlights(paragraph, highlightedRanges) :
                    <Typography component="span">{link.Paragraph_Text}</Typography>
                    //)
                    }
                </Paper>
                )
            })}
            </Box>
        </Modal>
      )

}
export default LinksModal;
import React, { useState, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChecklistIcon from '@mui/icons-material/Checklist';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import SidebarItem from './SidebarItem';

import Menu from '@mui/icons-material/Menu';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

import Toolbar from '@mui/material/Toolbar';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import SummarizeIcon from '@mui/icons-material/Summarize';

import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import CheckReportWindow from './ChecksReportModal';

import ChecklistMenu from './addCheckButton/checklistSettingMenu';

const API = "/checklists.api"

//we will need to pass the checks list as checks and the check_responses list as responses
// ADD IN , comments, setComments for final version.

const Highlighter_Sidebar = ({ handleHighlightTrigger, labels, selectedParagraphID, contentRef, checks, setChecks, linksModalIsOpen, setLinksModalIsOpen, selectedCheck, setSelectedCheck, responses, setResponses, paragraphs, ...props}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false); //for the extra checks in ¶ view
    const [isTextSelectMode, setIsTextSelectMode] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [activeParagraphView, setActiveParagraphView] = useState('¶');
    const [changesMade, setChangesMade] = useState(false);

    const [checkReportModalIsOpen, setCheckReportModalIsOpen] = useState(false);
    const handleCheckReportModalOpen = () => setCheckReportModalIsOpen(true);
    const handleCheckReportModalClose = () => setCheckReportModalIsOpen(false);

    //XXX COMMENTS TESTING.  REMOVE FROM FINAL.
    const [comments, setComments] = useState([
        { ID: null, User: '', Text: 'This is a test comment', Paragraph_ID:'30591', Check_ID: '1', time: '2022-01-01T12:00:00Z'},
        { ID: null, User: '', Text: 'This is a test response', Paragraph_ID:'30605', Check_ID: '1', time: '2022-01-01T12:05:00Z'},
    ]);  //THIS IS ONLY FOR DEBUGGING REMOVE FROM FINAL AND PASS FROM PARENT.

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' && isTextSelectMode) {
                setIsTextSelectMode(false);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isTextSelectMode]);


    const isMobileDevice = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setIsOpen(open);
        if (contentRef.current) {
            contentRef.current.style.pointerEvents = open ? 'none' : 'auto';
        }
        if (open) {
            setIsTextSelectMode(true);
            const contentArea = document.getElementById('contentArea');
            if(contentArea) {
              contentArea.style.pointerEvents = 'auto';
            }
        } else {
            setIsTextSelectMode(false);
            const contentArea = document.getElementById('contentArea');
            if(contentArea) {
              contentArea.style.pointerEvents = 'none';
            }
        }
    };

    const handleArrowClick = (item, paragraph_id) => {

        if(paragraph_id){
            setIsTextSelectMode(true);

            handleHighlightTrigger(item, paragraph_id);  //should just pass the item.

            if (isMobileDevice()) {
                toggleDrawer(false);
            }
        } else {
            console.log('No paragraph_id passed to handleArrowClick');
        }
        
    };

    //Will likely need to be passed to the parent component, but will check.
    function updateResponse(checkID, paragraphID, newResponse) {
        const responseIndex = responses.findIndex(response =>
          Number(response.Check_ID) === Number(checkID) && Number(response.Paragraph_ID) === Number(paragraphID)
        );
      
        let updatedResponses;
      
        if (responseIndex !== -1) {
          // Update existing response
          updatedResponses = [...responses];
          updatedResponses[responseIndex] = { ...responses[responseIndex], ...newResponse };
        } else {
          // Add new response if no existing match is found
          updatedResponses = [...responses, { ...newResponse, Check_ID: String(checkID), Paragraph_ID: paragraphID }];
        }
      
        setResponses(updatedResponses);  //need this to update the state
      
        console.log('responses updated:', updatedResponses);
      }

    // Checklist menu items
    const [checkedCheckListItems, setCheckedCheckListItems] = useState(
        checks.reduce((acc, item) => ({ ...acc, [item.Checklist_ID]: true }), {})
    );
    

    //HANDLING INITIAL PROCESSING FOR RENDERING.
    // Step 1: Get labels associated with the current paragraph
    const currentParagraphLabels = labels.filter(label => Number(label.Paragraph_ID) === Number(selectedParagraphID));

    // Create a set of unique Check_IDs from responses that match the selectedParagraphID
    const responseCheckIDs = new Set(responses
        .filter(response => response.Paragraph_ID === selectedParagraphID)
        .map(response => Number(response.Check_ID)));

    // Add to this set any Check_IDs from checks that match currentParagraphLabels
    // This is used in the render section to determine if an item should be rendered
    checks.forEach(item => {
        if (item.Labels.some(itemLabelID => 
            currentParagraphLabels.some(label => Number(label.Label_ID) === Number(itemLabelID)))) {
        responseCheckIDs.add(item.ID);
        }
    });



    // Create a set of unique Check_IDs from responses that match the selectedParagraphID
    const currentParagraphComments = new Set(comments
        .filter(comment => comment.Paragraph_ID === selectedParagraphID)
        .map(comment => Number(comment.Check_ID)));
        

    
    const uploadResponses = async () => {
        //console.log("token is: ", props.token)  //Security

        await fetch(API, {
            method: 'POST',
            mode: 'cors',
            //IMPORTANT
            //The body should contain the paragraph_id being requested.  This should be passed via props

            body: JSON.stringify({
                call_type: 'sync_responses',
                document_id: props.document_id,
                project_id: props.project_id,

                check_responses: responses,
            }),
            headers: new Headers({
                //'Accept': 'application/json; charset=utf-8',
                'Authorization': 'Bearer ' + props.token,
                'Content-Type': 'application/json',
                //'Access-Control-Allow-Origin': '*',
            })
        })
        //.then(response => response.text())          // convert to plain text
        //.then(text => console.log("fetch data: ",text))  // then log it out
        .then(response=> {
            return response.json() //convert to json.  return passes it
        })  // Prod
        //.then(data => console.log("fetch data: ",data))  //For Debugging
        .then((ResponseData) => {
            console.log("Response:", ResponseData)
            

            //check to see if a new access_token
            //console.log("new token: ", data)
            if(ResponseData.access_token !== undefined){
                console.log("setting new token")
                props.setToken(ResponseData.access_token) //if refresh token present. Set it.
            }

            // ADD CODE TO HANDLE RESPONSE WITH CHECK_ID TO ADD IT TO CHECKS.
            // AND CLEAR THE CHECK_TEXT.

            if(ResponseData.responses_list !== undefined && ResponseData.responses_list !== null){
                setResponses(ResponseData.responses_list);
                setChangesMade(false);
                alert("Responses have been updated.")
            }

        })  //set data to results
        .catch(error => {
            console.log('Fetch error: ', error);
            alert('There was an error saving your responses.');
        });//end error catch
    }
   


    //HANDLE RENDERING
    return (
        <Box display="flex" justifyContent="flex-end" sx={{ cursor: isTextSelectMode ? 'text' : 'default' }}>
            <IconButton onClick={isOpen ? toggleDrawer(false) : toggleDrawer(true)} color={isOpen ? "inherent" : "primary"}>
                <ChecklistIcon />
            </IconButton>

            {/* Check Report Modal */}
            <CheckReportWindow
                paragraphs={paragraphs}
                responses={responses}
                checks={checks}
                isOpen={checkReportModalIsOpen}
                setIsOpen={setCheckReportModalIsOpen}
                {...props}
            />

            {/* sidebar drawer */}
            <Drawer
                anchor="right"
                open={isOpen}
                onClose={() => {
                    if (!isTextSelectMode) {
                        setIsOpen(false);
                    }
                }}
                PaperProps={{
                    sx: {
                        width: isMobile ? '90%' : '25%',
                        bgcolor: 'white',
                        position: 'fixed', 
                        right: 0,
                        height: '100%',
                        pointerEvents: 'all',
                    }
                }}
                ModalProps={{
                    keepMounted: true,
                    hideBackdrop: true,
                    disableEnforceFocus: isMobile ? false : true,
                    disableAutoFocus: isMobile ? false : true,
                    BackdropProps: { 
                        invisible: true, 
                        style: { position: 'absolute' },
                    },
                    sx: (!isMobile && isOpen) ? { pointerEvents: 'none' } : {},
                }}
            >

                <Toolbar>
                    <IconButton color="inherit" aria-label="search">
                        <SearchIcon />
                    </IconButton>
                    <IconButton 
                        color={changesMade ? 'primary' : 'disabled'} 
                        aria-label="save"
                        disabled={!changesMade}
                        onClick={uploadResponses} // Add this line
                    >
                        <SaveIcon />
                    </IconButton>
                    <IconButton 
                        color="inherit" 
                        aria-label="report"
                        onClick={handleCheckReportModalOpen}
                    >
                        <SummarizeIcon />
                    </IconButton>

                    {/* Checklist Menu Icon */}
                    <ChecklistMenu 
                        checks={checks} 
                        checkedCheckListItems={checkedCheckListItems}
                        setCheckedCheckListItems={setCheckedCheckListItems}
                    />

                    <Select
                        value={activeParagraphView}
                        onChange={(event) => {
                            const newValue = event.target.value;
                            setActiveParagraphView(newValue);
                        }}
                        style={{ marginLeft: 'auto', marginRight: '50px' }}
                    >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem value="¶">¶</MenuItem>
                    </Select>
                </Toolbar>

                <IconButton
                    onClick={toggleDrawer(false)}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                    <CloseIcon />
                </IconButton>
                <Box
                    role="presentation"
                    sx={{ pt: 1 }}  // Add padding top to separate the checks from the toolbar
                >
                   {
                
                        activeParagraphView === "¶" ? (
                            <>
                              {(() => {
                                
                                // Split checks into matching and non-matching lists
                                const matchingItems = [];
                                const nonMatchingItems = [];
                              
                                
                                {/* Modify rendering logic to filter checks based on checkedCheckListItems */}
                                checks.filter(check => checkedCheckListItems[check.Checklist_ID]).map((item, index) => {
                                // checks.forEach((item, index) => { //old code
                                    // Check based on item labels
                                    const isLabelMatch = item.Labels.some(itemLabelID =>
                                        currentParagraphLabels.some(label => Number(label.Label_ID) === Number(itemLabelID))
                                    );

                                    // Check based on response Check_IDs
                                    const isResponseMatch = responseCheckIDs.has(item.ID);

                                    // Find a single related response based on matching item.ID with response.Check_ID
                                    // and also matching response.Paragraph_ID with selectedParagraphID
                                    const relatedResponse = responses.find(response => 
                                        Number(response.Check_ID) === Number(item.ID) && Number(response.Paragraph_ID) === Number(selectedParagraphID)
                                    );

                                    //For debugging.  If related response exists, log it.
                                    if (relatedResponse) {
                                        console.log('relatedResponse:', relatedResponse);
                                    }


                                    if (isLabelMatch || isResponseMatch) {

                                        matchingItems.push(
                                            <SidebarItem
                                            key={index}
                                            item={item}
                                            selectedParagraphID={selectedParagraphID}  //needed for setting the response
                                            relatedResponse={relatedResponse} // Pass related responses here
                                            onArrowClick={handleArrowClick}
                                            handleHighlightTrigger={handleHighlightTrigger}
                                            toggleDrawer={toggleDrawer}
                                            setChangesMade={setChangesMade}
                                            updateResponse={updateResponse}
                                            customSet={false}

                                            setLinksModalIsOpen={setLinksModalIsOpen}
                                            setSelectedCheck={setSelectedCheck}

                                            props={props} //pass the remaining props to the sidebar item

                                            />
                                        );

                                    } else {
                                        nonMatchingItems.push(
                                        <SidebarItem
                                            key={index}
                                            //item={item}
                                            item={{ ...item, Color: 'lightgrey' }} //make all checks lightgrey
                                            selectedParagraphID={selectedParagraphID}  //needed for setting the response
                                            relatedResponse={relatedResponse} // Pass related responses here
                                            onArrowClick={handleArrowClick}
                                            handleHighlightTrigger={handleHighlightTrigger}
                                            toggleDrawer={toggleDrawer}
                                            setChangesMade={setChangesMade}
                                            updateResponse={updateResponse}
                                            customSet={false}

                                            setLinksModalIsOpen={setLinksModalIsOpen}
                                            setSelectedCheck={setSelectedCheck}

                                            props={props} //pass the remaining props to the sidebar item
                                        />
                                        );
                                    }

                                });
                          
                                return (
                                  <>
                                    <div>{matchingItems}</div>
                                    <Button 
                                      onClick={() => setIsExpanded(!isExpanded)} 
                                      endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    >
                                      {isExpanded ? 'Show Less' : 'Show More'}
                                    </Button>
                                    <Collapse in={isExpanded}>
                                      <div>{nonMatchingItems}</div>
                                    </Collapse>
                                  </>
                                );
                              })()}
                            </>
                          ) : (
                            // Render this when value is not "¶"
                            <>
                                {/* filtering out checks that are not checked on checklist menu */}
                                {checks.filter(check => checkedCheckListItems[check.Checklist_ID]).map((item, index) => {
                                //  checks.map((item, index) => {  //old code
                                    const relatedResponse = responses.find(response => 
                                    Number(response.Check_ID) === Number(item.ID) && Number(response.Paragraph_ID) === Number(selectedParagraphID)
                                    );

                                    // Logging directly. This will execute each iteration of map.
                                    relatedResponse && console.log('relatedResponse:', relatedResponse);

                                    return (
                                        <SidebarItem
                                            key={index}
                                            item={item}
                                            selectedParagraphID={selectedParagraphID}  //needed for setting the response
                                            relatedResponse={relatedResponse} // Pass related responses here
                                            onArrowClick={handleArrowClick}
                                            handleHighlightTrigger={handleHighlightTrigger}
                                            toggleDrawer={toggleDrawer}
                                            setChangesMade={setChangesMade}
                                            updateResponse={updateResponse}
                                            customSet={false}

                                            setLinksModalIsOpen={setLinksModalIsOpen}
                                            setSelectedCheck={setSelectedCheck}

                                            props={props} //pass the remaining props to the sidebar item
                                        />
                                    );
                                })}
                            </>
                        )
                    }

                    
                </Box>
            </Drawer>
        </Box>
    );
};

export default Highlighter_Sidebar;

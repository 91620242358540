// Copyright © 2024 Ulomate LLC. All rights reserved.
// This source code is proprietary and may not be copied, distributed 
// or shared without the express written consent of Ulomate LLC.

import React, { useState , useEffect } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
//import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import LinkIcon from '@mui/icons-material/Link';
import CommentIcon from '@mui/icons-material/Comment';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import SearchIcon from '@mui/icons-material/Search'; 

//For showing the number of comments and links
import Badge from '@mui/material/Badge';

const SidebarItem = ({ item, onArrowClick, toggleDrawer, selectedParagraphID, relatedResponse, updateResponse, setChangesMade, setLinksModalIsOpen, setSelectedCheck, ...props}) => {
    const [commentCount, setCommentCount] = useState(0); //For showing the number of comments
    const [linkCount, setLinkCount] = useState(0); //For showing the number of links

    const [selectedButton, setSelectedButton] = useState(null); //For the check and cross buttons - convert to checkarray

    const [isHighlighted, setIsHighlighted] = useState(false);

    useEffect(() => {
      if (item.Links) {
        setLinkCount(item.Links.length);
      }
    }, [item]);

    const toggleHighlight = () => {
        setIsHighlighted(!isHighlighted); //may not be needed.

        let newResponse;
        // Check if a related response already exists
        if (!relatedResponse) {
          // If no related response exists, create a new response object.
          // No values will be set except for the item ID and the paragraph ID.
          newResponse = {
            ID: null,
            Project_ID: props.project_id,
            Title: null,
            Text: null, 
            Type: null,
            Color: null,
            Paragraph_ID: selectedParagraphID,
            Check_ID: String(item.ID),
            Response: '', // Setting the response type to nothing
            Comments: [],
            Links: []
          };
        }
      
        // Call the updateResponse function with the new or updated response
        updateResponse(item.ID, selectedParagraphID, newResponse);      

        //Handle highlighting
        onArrowClick(item, selectedParagraphID); //This will trigger the highlight.

        setChangesMade(true);  //make sure its indicated that there are changes to be saved.

        //likely not needed
        // if (onHighlightTrigger) {
        //     onHighlightTrigger(lightenColor(item.Color, 40));
        // }
    };

    const lightenColor = (color, amount) => {
        let r = parseInt(color.slice(1, 3), 16);
        let g = parseInt(color.slice(3, 5), 16);
        let b = parseInt(color.slice(5, 7), 16);

        r = Math.min(255, r + amount);
        g = Math.min(255, g + amount);
        b = Math.min(255, b + amount);

        return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
    };

    const darkenColor = (color, amount) => {
        let r = parseInt(color.slice(1, 3), 16);
        let g = parseInt(color.slice(3, 5), 16);
        let b = parseInt(color.slice(5, 7), 16);

        r = Math.max(0, r - amount);
        g = Math.max(0, g - amount);
        b = Math.max(0, b - amount);

        return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
    };

    const onCommentIconClick = () => {
        console.log('Comment icon clicked');
    };

    const onLinkIconClick = () => {
        console.log('Link icon clicked, setting selected check to:', item);

        setSelectedCheck(item); //set the Model's check data to this check.
        setLinksModalIsOpen(true);  //show the Model
        
    };

    const handleCheckClick = () => {
        let newResponse;
      
        // Check if a related response already exists
        if (relatedResponse) {
          // If it exists, prepare to update the existing response's Text to 'check'
          newResponse = { ...relatedResponse, Response: 'check' }; // Assuming you want to update the response type
        } else {
          // If no related response exists, create a new response object
          newResponse = {
            ID: null,
            Project_ID: props.project_id,
            Title: '',
            Text: '', 
            Type: '',
            Color: null,
            Paragraph_ID: selectedParagraphID,
            Check_ID: String(item.ID),
            Response: 'check', // Setting the response type to 'check'
            Comments: [],
            Links: []
          };
        }
      
        // Call the updateResponse function with the new or updated response
        updateResponse(item.ID, selectedParagraphID, newResponse);
      
        setChangesMade(true);
        // Add any additional logic for when the check button is clicked
      };
      
      
      const handleCrossClick = () => {
        let newResponse;
      
        // Check if a related response already exists
        if (relatedResponse) {
          // If it exists, prepare to update the existing response's Text to 'check'
          newResponse = { ...relatedResponse, Response: 'cross' }; // Assuming you want to update the response type
        } else {
          // If no related response exists, create a new response object
          newResponse = {
            ID: null,
            Project_ID: props.project_id,
            Title: null,
            Text: null, 
            Type: null,
            Color: null,
            Paragraph_ID: selectedParagraphID,
            Check_ID: String(item.ID),
            Response: 'cross', // Setting the response type to 'check'
            Comments: [],
            Links: []
          };
        }
      
        // Call the updateResponse function with the new or updated response
        updateResponse(item.ID, selectedParagraphID, newResponse);
      
        setChangesMade(true);
        // Add any additional logic for when the check button is clicked
      };


      const handleQuestionClick = () => {
        let newResponse;
      
        // Check if a related response already exists
        if (relatedResponse) {
          // If it exists, prepare to update the existing response's Text to 'check'
          newResponse = { ...relatedResponse, Response: 'question' }; // Assuming you want to update the response type
        } else {
          // If no related response exists, create a new response object
          newResponse = {
            ID: null,
            Project_ID: props.project_id,
            Title: null,
            Text: null, 
            Type: null,
            Color: null,
            Paragraph_ID: selectedParagraphID,
            Check_ID: String(item.ID),
            Response: 'question', // Setting the response type to 'check'
            Comments: [],
            Links: []
          };
        }
      
        // Call the updateResponse function with the new or updated response
        updateResponse(item.ID, selectedParagraphID, newResponse);
      
        setChangesMade(true);
        // Add any additional logic for when the check button is clicked
      };

      const onSearchIconClick = () => { 
        console.log('Search icon clicked');
      }

    return (
         <Box
            sx={{
                position: 'relative',
                bgcolor: isHighlighted ? lightenColor(item.Color, 40) : item.Color,
                p: 1,
                margin: '10px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                borderBottom: '1px solid gray',
                borderRadius: '4px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >

            {/* Check, Cross and Question buttons */}
            <Box sx={{ position: 'absolute', bottom: '-10px', left: '-5px' }}>
                <IconButton 
                    onClick={handleCheckClick} 
                    color={relatedResponse?.Response === 'check' ? 'success' : 'disabled'}
                    sx={{backgroundColor: relatedResponse?.Response === 'check' ? 'darkgreen' : 'darkgrey' }}
                >
                    <CheckIcon sx={{ fontSize: 10 }} /> {/* Reduce the size of the icon */}
                </IconButton>
                <IconButton 
                    onClick={handleCrossClick} 
                    color={relatedResponse?.Response === 'cross' ? 'error' : 'disabled'}
                    sx={{ backgroundColor: relatedResponse?.Response === 'cross' ? 'darkred' : 'darkgrey' }}
                >
                    <CloseIcon sx={{ fontSize: 10 }} /> {/* Reduce the size of the icon */}
                </IconButton>
                <IconButton 
                    onClick={handleQuestionClick} 
                    color={relatedResponse?.Response === 'question' ? 'warning' : 'disabled'}
                    sx={{ backgroundColor: relatedResponse?.Response === 'question' ? 'yellow' : 'darkgrey' }}
                >
                    <HelpOutlineIcon sx={{ fontSize: 10 }} />
                </IconButton>
            </Box>

            {/* Highlighter Button */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                    onClick={toggleHighlight}
                    sx={{
                        color: 'white',
                        backgroundColor: darkenColor(item.Color, 100),
                        '&:hover': { backgroundColor: darkenColor(item.Color, 40) },
                    }}
                >
                    <DriveFileRenameOutlineIcon />
                </IconButton>
                <Box sx={{ ml: 3, flexGrow: 1, fontSize: '0.8em' }}>
                    {item.Text}
                </Box>
            </Box>

            {/* Search, Comments and Links Buttons */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                {/* Search */}
                <IconButton onClick={onSearchIconClick}>
                    <SearchIcon />
                </IconButton>
                {/* Comments */}
                <IconButton onClick={onCommentIconClick}>
                    <Badge badgeContent={commentCount} color="primary">
                    <CommentIcon />
                    </Badge>
                </IconButton>
                {/* Links */}
                <IconButton 
                  onClick={onLinkIconClick} 
                  disabled={linkCount === 0}
                >
                    <Badge badgeContent={linkCount} color="primary">
                      <LinkIcon />
                    </Badge>
                </IconButton>
            </Box>
        </Box>
    );
};

export default SidebarItem;

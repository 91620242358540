import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';

//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

//https://react-pdf.org/styling

/* code is glitched, you get an error that states:
ERROR:  Critical dependency: require function is used in a way in which dependencies cannot be statically extracted
FIX:    The way to fix this is to copy "node_modules/pdfjs-dist/build/pdf.worker.min.js" to "public/pdf.worker.min.js"
        
AUTOMATED SOLUTION: 
    One person notes to do it automatically using a package.json script:
    small automation: add "postinstall": "cp node_modules/pdfjs-dist/build/pdf.worker.min.js public/pdf.worker.min.js" to the scripts section in the package.json
    See:https://github.com/wojtekmaj/react-pdf/issues/280

    Notes of PDF.jS
    https://code.tutsplus.com/tutorials/how-to-create-a-pdf-viewer-in-javascript--cms-32505

    Consider adding navigation bar that allows jumping page by page using scroll, zooming in and out, jumping to pages x of numPages.

*/
 
class RJS_PDF_Viewer extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
  }
 
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }


  goToPDFPage(){

      //May want to research the following.
      //See: https://blog.logrocket.com/a-complete-guide-to-default-props-in-react-984ea8e6972d/

      //check if pageNumber was passed.  If so scroll to that page, else don't.
      if (this.props.pageNumber)
      {
        var string_search = '[data-page-number="' + this.props.pageNumber + '"]';
      
        //alert(string_search);

        //set to to time out after 30000
        var checkExist = setInterval(function() {
          if (document.querySelector(string_search)) {
            //console.log("Exists!"); //for Debug
            clearInterval(checkExist);

            //Scrolling
            //See: https://www.w3schools.com/jsref/met_element_scrollintoview.asp
            //See also: https://www.w3schools.com/jsref/met_document_getelementsbyclassname.asp
            document.querySelector(string_search).scrollIntoView();
          }
      }, 500); // check every 500ms (1/2 second)
    }
      //get top position of element
      //var myElement = document.getElementById('element_within_div');
      //document.querySelector('[data-page-number="22"]').scrollIntoView();
  }


  render() {
    const { pageNumber, numPages } = this.state;
 
    return (
      //**Need to figure out how to only render if src changes
      //See: https://www.freecodecamp.org/news/how-to-identify-and-resolve-wasted-renders-in-react-cc4b1e910d10/
      //See also: https://reactjs.org/docs/conditional-rendering.html
      //See also: https://blog.logrocket.com/conditional-rendering-in-react-c6b0e5af381e/
      
      //May want to use a function calls <Document> if src changes or else doesn't.  However, stil need to consider that render will be called and it needs a value
      //unless we can prevent the render, and just call the jump to page. 

      <div className="PDF_reader">
        <Document
          file={this.props.src}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
        {/*
            Intergrate into error check.

            Need to ID each page for manipulation.

            Note to move toward annotations:
            https://jsfiddle.net/seikichi/RuDvz/2/
            
        */}
        {Array.apply(1, Array(numPages)).map(function(x, i) {
            //alert(i);

            //Need to increase size of each page of the pdf so it fits the screen.
            //Consider using height.
            //Will need to add a calculation for width.  500 worked for a test.
            //i+1 because pdf.js starts at 1 not 0
            return <Page pageNumber={i + 1} width="550" />;
            }
        )}
        
        </Document>
          {
            //use prop for page number inside function.
            this.goToPDFPage()
          }
      </div>
    );
    
  }
}

export default RJS_PDF_Viewer
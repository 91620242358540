import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import './08_navbar.css'
//See: https://www.w3schools.com/css/css_navbar_horizontal.asp
//See: https://stackoverflow.com/questions/8965063/target-a-css-class-inside-another-css-class/8965078


class Navigation_Bar extends Component {

	constructor(props) {
		super(props)
	
		this.state = {
			 isloading: true,
			 activeClasses: [true, false, false, false, false]
		}
		this.addActiveClass= this.addActiveClass.bind(this);
	}	

	addActiveClass = (index) => {
		//alert(index)
		//Good for toggle on and off
		//const activeClasses = [...this.state.activeClasses.slice(0, index), !this.state.activeClasses[index], this.state.activeClasses.slice(index + 1)].flat();
		//this.setState({activeClasses});

		//set all to false...
		const activeClasses = [false,false,false,false, false];
		//and select one and set to true.
		activeClasses[index] = true;
		this.setState({activeClasses});
	}

	handleClick = (event, a_value, b_value) => {
		//alert("NavBar: " + a_value);
		this.props.MenuChoice(a_value);
		this.addActiveClass(b_value);
		//this.props.parentCallback({pdfURL:a_value});
	}

	render() {
		
		/*if yes, show the pdf*/
		//alert(this.props.src)

		return(
			<div className="NavBar">
				<ul>
					<li><Link to="/" className={this.state.activeClasses[0] ? "active" : "inactive"} onClick={(e) => this.handleClick(e, "Main", 0)}>Home</Link></li>
					<li><Link to="/search" className={this.state.activeClasses[1] ? "active" : "inactive"} onClick={(e) => this.handleClick(e, "Search", 1)}>Search</Link></li>
					<li><Link to="/upload" className={this.state.activeClasses[2] ? "active" : "inactive"} onClick={(e) => this.handleClick(e, "Upload", 2)}>Upload</Link></li>
					<li><Link to="/merge" className={this.state.activeClasses[3] ? "active" : "inactive"} onClick={(e) => this.handleClick(e, "Merge", 3)}>Merge</Link></li>
					<li><Link to="/topics" className={this.state.activeClasses[4] ? "active" : "inactive"} onClick={(e) => this.handleClick(e, "Topics", 4)}>Topics</Link></li>
					<li><Link to="/annotator" className={this.state.activeClasses[5] ? "active" : "inactive"} onClick={(e) => this.handleClick(e, "Topics", 5)}>Annotator</Link></li>
					<li><Link to="/analyzer" className={this.state.activeClasses[6] ? "active" : "inactive"} onClick={(e) => this.handleClick(e, "Analyzer", 5)}>Analyzer</Link></li>
				</ul>
			</div>
		);
		 
	}
}

export default Navigation_Bar
import React from "react";
import { makeStyles } from "@mui/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Grid,
} from "@mui/material";

import { styled } from '@mui/system';
import backgroundImage from "../logo192.png"; // replace with your image file

const Logo = styled('div')({
  position: 'relative',
  display: 'inline-block',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '-1',
    borderRadius: '50%',
    boxShadow: '0 0 20px 25px white',
    filter: 'blur(4px)',
    opacity: 0.6,
    transition: 'opacity 0.2s ease-out',
  },
  '&:hover::before': {
    opacity: 1,
  },
  '& img': {
    display: 'block',
    height: '60px',
    width: '60px',
    borderRadius: '50%',
    transition: 'opacity 0.2s ease-out',
  },
});

const LogoAnimated = () => {
  return (
    <div>
        <Box sx={{ display: 'flex', alignItems: 'center', position:"absolute", leftMargin: '16px'}}>
          <Logo>
            <img src={backgroundImage} alt="Logo"/>
          </Logo>
        </Box>
    </div>
  );
};

export default LogoAnimated;
